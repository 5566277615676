



































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class MobileBlocker extends Vue {
  email: string | null = null;
  submitted: boolean = false;

  async submitExternalRegister() {
    let postData = {
      'email': this.email
    };
    

    const res = await fetch(`${process.env.VUE_APP_API}/external/register`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    });

    if(res.status === 200){
      this.submitted = true;
    }
  }
}
