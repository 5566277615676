




























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PublicBoardTemplate} from "@/typescript/types";
import {CreateBoardFromTemplateMutation} from "@/graphql/board";
import {WorkspaceQuery} from "@/graphql/workspace";

@Component({
  components: {},
})
export default class Template extends Vue {
  selectedPermission: string | null = 'workspace';
  loading: boolean = false;



  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get templateId() {
    return this.template.id;
  }

  @Prop({default: undefined, required: true})
  template!: PublicBoardTemplate;

  unauthenticatedCreateFromTemplate(template: PublicBoardTemplate) {
    this.$store.commit('set_template', template);
    this.$router.push({ name: 'register' });
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  createRoomFromTemplate() {
    this.loading = true;
    setTimeout(() => this.loading = false, 1500);
    this.$apollo
      .mutate({
        mutation: CreateBoardFromTemplateMutation,
        variables: {
          name: this.template.name,
          workspace: this.workspaceId,
          template_id: this.templateId,
          permissions: this.selectedPermission
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data}) => {
        //@ts-ignore
        this.$parent.close();
        this.$emit('submitted', data);
      })
      .finally(() => {
        this.$emit('close')

      });
  }

  mounted() {
   
  }
}
