
























import { Component, Vue } from 'vue-property-decorator';

@Component({
  props: {
    incrementValue: {},
    currentValue: {},
    startValue: {},
    targetValue: {},
    id: {},
  },
})
export default class PieCheckbox extends Vue {
  currentValueNumber = 0;
  progressPercentage = 0;
  progressBalancer = 100;
  translatedIncrementValue = 0;
  delayedChecked = false;
  hasReachedHundred = false;

  /**
   * single/double click on checkbox
   * */

  clickCount = 0;

  /**
   * This triggers increment on single click, and decrement on double click.
   * */
  handleProgressClick() {
    this.clickCount++;
    setTimeout(() => {
      if (this.clickCount === 1) {
        this.increment();
      } else if (this.clickCount === 2) {
        this.decrement();
      }
      this.clickCount = 0;
    }, 250);
  }

  increment() {
    this.currentValueNumber = this.currentValueNumber + this.translatedIncrementValue;
    this.progressPercentage = this.currentValueNumber;
    this.progressBalancer = 100 - this.currentValueNumber;

    this.$emit('value', this.$props.incrementValue);

    if (this.currentValueNumber >= 100) {
      setTimeout(() => {
        this.hasReachedHundred = true;
        this.markAsChecked();
      }, 250);
    }
  }

  decrement() {
    if (this.currentValueNumber >= this.translatedIncrementValue) {
      this.currentValueNumber = this.currentValueNumber - this.translatedIncrementValue;

      if (this.currentValueNumber < 100) {
        this.delayedChecked = false;
        this.hasReachedHundred = false;
      }

      this.$emit('value', -this.translatedIncrementValue);

      this.progressPercentage = this.currentValueNumber;
      this.progressBalancer = 100 - this.currentValueNumber;
    }
  }

  markAsChecked() {
    setTimeout(() => {
      this.delayedChecked = true;
    }, 50);
  }

  //Todo: this shouldn't be on mounted but rather on updated...

  mounted() {
    this.currentValueNumber = this.$props.currentValue;
    this.progressPercentage = this.currentValueNumber;
    this.translatedIncrementValue = (this.$props.incrementValue / this.$props.targetValue) * 100;
    if (this.$props.currentValue >= this.$props.targetValue) {
      this.delayedChecked = true;
      this.hasReachedHundred = true;
    }
  }
}
