




























































































import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import UserAvatar from "@/components/user/UserAvatar.vue";
import {ObjectiveDetailedFragment, WorkspaceObjectivesQuery} from "@/graphql/objective";
import {User} from "@/typescript/types";
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {Template, UserAvatar},
  props: {
    objectiveId: {
      required: true
    },
    owner: {
      required: true,
    },
    assignees: {
      default: null,
      type: Array
    }
  },
})
export default class ObjectiveMemberSelector extends Vue {
  isActive = false;
  currentOwner: string | null = null;
  selectedAssignees: Array<User> = [];

  get board() {
    return this.$store.state.board;
  }

  get members() {

    let members = this.$store.state.board.members.map((user: any) => user.user);
    return members.filter((user: any) => user.id != this.currentOwner && user.deleted_at === null);
  }

  get membersInRoom() {
    return this.members.length;
  }

  get assignedMembers() {
    let memberCount = this.selectedAssignees.length;
    if(memberCount > 0){
      let text = '';
      let memberText = this.selectedAssignees.map((user:any, i: any)=>{

        if(memberCount === i + 1 && memberCount != 1){
          return text+' & '+user.first_name;
        }else if(memberCount-1 === i + 1){
          return text+user.first_name;
        }else if(memberCount === 1){
          return text+user.first_name;
        }else{
          return text+user.first_name+', ';
        }

      }).join('');
      return memberCount+((memberCount > 1)?' members: ':' member: ')+ memberText;
    }

    return 'Members';

  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.$store.state.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });


    if (member[0].access_workspace == 0) {
      return false;
    } else {
      return true;
    }
  }

  changed(member: any) {
    let index = this.selectedAssignees.findIndex((user: any) => {
      return user.id === member.id;
    });

    if (index != -1) {
      this.selectedAssignees.splice(index, 1);
    } else {
      this.selectedAssignees.push(member);
    }

    this.$apollo.mutate({
      mutation: gql`
                mutation assignUsers($objective_id: ID!, $user_ids: [ID!]) {
                    assignUsers(input: { objective_id: $objective_id, user_ids: $user_ids }) {
                        avatar
                        email
                        first_name
                        id
                        initials
                        last_name
                        name

                    }
                }
            `,
      variables: {
        objective_id: this.$props.objectiveId,
        user_ids: this.selectedAssignees.map(id => id.id),
      },
      refetchQueries: [{query: WorkspaceObjectivesQuery, variables: { workspace_id: this.$store.state.workspace.id }}]
      
    });
    this.$buefy.toast.open({
      message: 'Members updated!',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }


  setNewOwner(userId: string) {
    this.currentOwner = userId;
    this.updateObjective(userId);
  }

  updateObjective(userId: string) {
    this.isActive = false;
    this.$apollo.mutate({
      mutation: gql`
                mutation updateObjective($id: ID!, $owner: ID!) {
                    updateObjective(id: $id, input: { owner: { connect: $owner } }) {
                        ...ObjectiveDetailed
                    }
                }
                ${ObjectiveDetailedFragment}
            `,
      variables: {
        id: this.$props.objectiveId,
        owner: userId,
      },
    });
  }

  checkIfPresent(member: any) {
    return this.selectedAssignees.some((person: any) => person.id === member.id);
  }

  mounted() {
    this.currentOwner = this.$props.owner.id;
    this.selectedAssignees = this.$props.assignees;

    this.$events.listen('update-assignees', eventData => {
      this.selectedAssignees = eventData;
    })
  }
}
