






















































import {Component, Vue, Prop} from 'vue-property-decorator';
import {CreateObjectiveCommentMutation, ObjectiveCommentsQuery} from '@/graphql/objective';

import UserAvatar from "@/components/user/UserAvatar.vue";

import At from 'vue-at';
import {Objective, ObjectiveComment} from "@/typescript/types";

@Component({
  components: {At, UserAvatar},
})
export default class CreateObjectiveComment extends Vue {
  wait: boolean = false;
  inputIsFocused = false;
  message: string | null = null;
  html: string | null = null;
  emojis: Array<{ icon: string, name: string }> = [
    {icon: '👍', name: '+1'},
    {icon: '👎', name: '-1'},
    {icon: '👋', name: 'wave'},
    {icon: '👏', name: 'clap'},
    {icon: '💪', name: 'strong'},
    {icon: '🙏', name: 'pray'},
    {icon: '😄', name: 'smile'},
    {icon: '😃', name: 'smiley'},
    {icon: '😉', name: 'wink'},
    {icon: '🤩', name: 'star_struck'},
    {icon: '😬', name: 'grimacing'},
    {icon: '😇', name: 'angel'},
    {icon: '😢', name: 'sad'},
    {icon: '😅', name: 'sweat_smile'},
    {icon: '🦄', name: 'unicorn'},
    {icon: '🎉', name: 'party_popper'},
    {icon: '🐶', name: 'dog'},
    {icon: '👽', name: 'alien'},
    {icon: '🎃', name: 'pumpkin'},
    {icon: '👻', name: 'ghost'},
  ];

  @Prop({required: true})
  objectiveId!: string;

  @Prop({required: true})
  objective!: Objective;

  get me() {
    return this.$store.state.me;
  }

  close() {
    this.$emit('close', true);
  }

  getFakeComment(): ObjectiveComment {
    let randId = Math.random().toString(36).substring(7)
    let message = this.message ? this.message : "";

    return {
      id: randId,
      author: this.me,
      comment: message,
      commentAsHtml: message,
      created_at: new Date(),
      deleted_at: null,
      objective: this.objective,
      updated_at: new Date()
    }
  }

  createObjectiveComment() {
    this.close();
    //@ts-ignore ToDo: fix type issue
    this.message = this.$refs.edit.innerHTML;

    this.$emit('objective-comment-created', this.getFakeComment())

    this.$apollo
      .mutate({
        mutation: CreateObjectiveCommentMutation,
        variables: {
          id: this.objectiveId,
          comment: this.message,
        },
        refetchQueries: [{query: ObjectiveCommentsQuery, variables: {objective_id: this.objectiveId}}],
      })
      .finally(() => {
        this.message = null;
        this.html = null;
        // @ts-ignore
        this.$refs.edit.innerHTML = '';
        // @ts-ignore
        this.$refs.edit.focus();
      });
  }

  mounted() {
    // @ts-ignore
    document.querySelector('#mainInput').addEventListener("paste", function (e) {
      e.preventDefault();
      // @ts-ignore
      let text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }
}
