



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Board } from '@/typescript/types';

@Component({})
export default class BoardsSelector extends Vue {
  boardSelected: Array<Board> = [];

  @Prop({ required: false })
  selectable!: Array<Board>;

  @Prop({ required: false, default: () => new Array<Board>() })
  selected!: Array<Board>;

  get workspace() {
    return this.$store.state.workspace;
  }

  get allBoards(){
    return this.workspace.boards.filter((board: Board) => !this.boardSelected.find(({ id }) => board.id === id))
  }

  changed() {
    this.$emit('input', this.boardSelected);
  }

  mounted(){
    this.boardSelected = this.selected;
  }
}
