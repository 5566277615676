



















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class CreateObjectiveTrigger extends Vue {

  @Prop({required: false, default: '#c8c8c8' })
  color!: string | null;
}
