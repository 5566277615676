























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Board, Objective as ObjectiveType} from "@/typescript/types";
import gql from "graphql-tag";
import {ObjectiveDetailedFragment} from "@/graphql/objective";
import Encryption from "@/encryption";

@Component({
  components: {},
})

export default class DuplicateObjective extends Vue {
  selectedBoards: Array<Board> = [];
  filterBoards: Array<Board> = [];
  @Prop({default: undefined, required: true})
  objective!: ObjectiveType;

  get me() {
    return this.$store.state.me;
  }

  get isDisabled() {
    if(this.selectedBoards.length > 0){
      return false;
    }
    return true;
  }

  get objectiveName() {
    return Encryption.decrypt(this.objective.name);
  }

  get boards() {
    return this.$store.state.workspace.boards;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get linkedBoards() {
    let boardCount = this.selectedBoards.length;
    if(boardCount > 0){
      let text = '';
      let boardText = this.selectedBoards.map((board: Board, i: any)=>{

        if(boardCount === i + 1 && boardCount != 1){
          return text+' & '+board.name;
        }else if(boardCount-1 === i + 1){
          return text+board.name;
        }else if(boardCount === 1){
          return text+board.name;
        }else{
          return text+board.name+', ';
        }

      }).join('');
      return boardCount+((boardCount > 1)?' rooms: ':' room: ')+ boardText;
    }

    return 'Rooms';

  }

  getFilteredTags(text: string) {
    this.filterBoards = this.boards.filter((board: any) => {
      return board.name
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0
    })
  }

  duplicateObjectivesInBoards() {
    this.selectedBoards.forEach((value: Board) =>{
      this.$apollo
        .mutate({
          mutation: gql`
                    mutation duplicateObjective(
                        $objective_id: ID!,
                        $board_id: ID!
                    ) {
                        duplicateObjective(
                           objective_id: $objective_id,
                           board_id: $board_id
                        ) {
                            ...ObjectiveDetailed
                        }
                    }
                    ${ObjectiveDetailedFragment}
                `,
          variables: {
            objective_id: this.objective.id,
            board_id: value.id,
          }
        })
        .then(() => {
          this.$buefy.toast.open({
            message: this.objectiveName+' has been duplicated to '+ value.name,
            position: 'is-bottom-right',
            type: 'is-black',
            duration: 3000
          });
          //@ts-ignore
          this.$parent.close()
          this.$gtag.event('pembio_create_objective');
        });
    })
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  mounted() {
    this.filterBoards = this.boards;
  }


}
