






















































import gql from 'graphql-tag';
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import CreateObjectiveComment from '@/components/board/objective/CreateObjectiveComment.vue';
import {
  DeleteObjectiveCommentMutation,
  ObjectiveCommentDetailedFragment,
  ObjectiveCommentsQuery
} from '@/graphql/objective';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {ObjectiveCommentPaginator, ObjectiveComment} from '@/typescript/types';
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";

@Component({
  components: {UserAvatar, CreateObjectiveComment},

  apollo: {
    objectiveComments: {
      query: ObjectiveCommentsQuery,
      subscribeToMore: {
        document: gql`
                    subscription ObjectiveCommentUpdated($objective_id: ID!) {
                        objectiveCommentUpdated(objective_id: $objective_id) {
                            ...ObjectiveCommentDetailed
                        }
                    }
                    ${ObjectiveCommentDetailedFragment}
                `,
        variables() {
          return {
            objective_id: this.objectiveId,
          };
        },
        updateQuery(previous, {subscriptionData}) {
          
          if(this.checkMember(subscriptionData.data.objectiveCommentUpdated.author.id) && subscriptionData.data.objectiveCommentUpdated.objective.id == this.objectiveId) {
            this.newCount++;
            this.alertNewMessage = true;
            this.$emit('toggle-message-notification');

            let previousMessages = previous.objectiveComments.data;
            let newMessage = subscriptionData.data.objectiveCommentUpdated;


            let duplicate = previousMessages.findIndex((objectiveComment: ObjectiveComment) => {
              return objectiveComment.id === newMessage.id
            })

            if (duplicate != -1) {
              previousMessages = previousMessages.filter((objectiveComment: ObjectiveComment) => {
                return objectiveComment.id !== duplicate.id
              })
            } else {
              previousMessages.unshift(newMessage);
              this.$emit('comment-count', newMessage);
            }


            return {
              objectiveComments: {
                data: previousMessages,
                paginatorInfo: previous.objectiveComments.paginatorInfo,
                __typename: previous.objectiveComments.__typename,
              },
            };
          }

        },
      },
      variables() {
        return {
          objective_id: this.objectiveId,
        };
      },
    },
  },
})
export default class ObjectiveComments extends Vue {
  objectiveComments: ObjectiveCommentPaginator | null = null;
  newCount = 0;
  page = 1;
  alertNewMessage = false;

  @Prop({required: true})
  objectiveId!: string;

  @Prop({required: false})
  postComment!: ObjectiveComment;

  get workspace() {
    return this.$store.state.workspace;
  }

  get me(){
    return this.$store.state.me;
  }

  @Watch('alertNewMessage')
  onalertNewMessageChange() {
    if (this.alertNewMessage) {
      const chatWindow = document.querySelector('#message-container') as HTMLInputElement;
      if (chatWindow != null) {
        if (Math.abs(chatWindow.scrollHeight - chatWindow.scrollTop - chatWindow.clientHeight) <= 40.0) {
          this.$nextTick(() => {
            this.scrollToEnd();
          });
          this.alertNewMessage = false;
        }
      }
    }
  }

  get comments() {
    if (this.objectiveComments) {

      return this.objectiveComments.data
    }
    return [];
  }

  get hasMorePages() {
    if (this.objectiveComments) {
      return this.objectiveComments.paginatorInfo.hasMorePages;
    }
    return false;
  }

  checkMember(userId: string) {
    let member = this.workspace.members.filter((member: any) => {
      return member.user.id === userId;
    })
    if(member.length > 0) {
      return true;
    }
    return false;
  }

  changePage() {
    this.page++;
    this.objectiveComments!.data.splice(this.objectiveComments!.data.length - this.newCount, this.newCount);
    this.newCount = 0;
    this.$apollo.queries.objectiveComments.fetchMore({
      variables: {
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
        return {
          objectiveComments: {
            __typename: previousResult.objectiveComments.__typename,
            data: [...previousResult.objectiveComments.data, ...fetchMoreResult.objectiveComments.data],
            paginatorInfo: fetchMoreResult.objectiveComments.paginatorInfo,
          },
        };
      },
    });
  }

  alertNewMessageRead() {
    this.alertNewMessage = false;
    this.scrollToEnd();
  }

  scrollToEnd() {
    const chatWindow = document.querySelector('#message-container') as HTMLInputElement;
    if (chatWindow != null) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }

  newMessage(message: ObjectiveComment) {
    this.objectiveComments!.data.unshift(message);
    this.newCount++;
    this.$nextTick(() => {
      this.scrollToEnd();
    });
  }

  openDeleteModal(id: string){
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete this comment?',
        closeParentFirst: true
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.deleteComment(id);
          }
        }
      },
      width: '500px',
    });
  }

  deleteComment(id: string){
    this.$apollo.mutate({
      mutation: DeleteObjectiveCommentMutation,
      variables:{
        id: id
      }
    }).then((res: any) =>{
      let index = this.objectiveComments!.data.findIndex((comment: any) => comment.id === res.data.deleteObjectiveComment.id);
      this.objectiveComments!.data.splice(index, 1);
    });
  }

  mounted() {
    /*
    this.$nextTick(() => {
      this.scrollToEnd();
    });
    */
  }

  @Watch('postComment')
  postCommentChanged() {
    if (this.postComment) {
      this.objectiveComments?.data.unshift(this.postComment)
    }
  }
}
