































import { Component, Vue } from 'vue-property-decorator';
import { IncrementKeyResultMutation } from '@/graphql/objective';


@Component({
  components: {},
  props: {
    key_result: {},
  },
})
export default class KeyResult extends Vue {
  incrementKeyResult(value: number) {
    this.$apollo.mutate({
      mutation: IncrementKeyResultMutation,
      variables: {
        id: this.$props.key_result.id,
        value: value,
      },
    });
  }
}
