import gql from "graphql-tag";

export const AttachmentDetailedFragment = gql `
  fragment AttachmentDetailed on Attachment {
      id
      file_name      
      file_type     
      created_at
      owner {
          id
          name
          deleted_at
      }
      board {
          id
          name
      }
      objectives {
          id
          name
      }
  }
`;

export const DeleteAttachments = gql `
    mutation deleteAttachments($attachmentId: ID!){
        deleteAttachments(attachmentId: $attachmentId){
            id
        }
    }
`;

export const WorkspaceAttachments = gql `
    query WorkspaceAttachments($id: ID!, $board_id: ID, $owner_id: ID, $file_type: String, $boardIds: [userBoards], $first: Int, $page: Int){
        workspaceAttachments(id: $id, board_id: $board_id, owner_id: $owner_id, file_type: $file_type, boardIds: $boardIds, first: $first, page: $page){
            data{
                ...AttachmentDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
            
        }
    }
    ${AttachmentDetailedFragment}
`;

export const BoardAttachments = gql `
    query BoardAttachments($board_id: ID!, $first: Int, $page: Int){
        boardAttachments(board_id: $board_id, first: $first, page: $page){
            data{
                ...AttachmentDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }

        }
    }
    ${AttachmentDetailedFragment}
`;

export const CopyAttachments = gql `
    mutation CopyAttachments($attachment_id: ID!, $board_id: ID!, $workspace_id: ID!){
        copyAttachment(attachment_id: $attachment_id, board_id: $board_id, workspace_id: $workspace_id){
            ...AttachmentDetailed
        }
    }
    ${AttachmentDetailedFragment}
`;