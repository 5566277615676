




















































































































































import {Component, Mixins} from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {MeQuery, UpdateEmail, UpdateUserMutation, VerifyPinAndUpdateEmail} from '@/graphql/auth';
import Template from "@/components/workspace/Template.vue";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import * as ct from "countries-and-timezones";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import gql from "graphql-tag";

@Component({
  components: {Template, UserAvatar },
  apollo: {},
})
export default class Account extends Mixins(ValidationErrorParserMixin) {
  editSettingsActive: boolean | null = false;
  accountInputChange: boolean | null = false;
  editTimezoneActive: boolean | null = false;
  first_name: string | null = null;
  last_name: string | null = null;
  email: string | null = null;
  updatedEmail: string | null = null;
  clicked = false;
  isEditable: boolean = false;
  isVerificationOn: boolean = false;
  verificationCode: string | null = null;
  country: string | null = null;
  country_code: string | null = null;
  city: string | null = null;
  timezone: string | null = null;
  countryRelatedTimezones: Array<any> = [];

  openDangerZone: boolean = false;


  get me() {
    return this.$store.state.me;
  }

  get allCountries() {
    return ct.getAllCountries();
  }

  selectedCountryTimeZone(country_code: string) {
    //@ts-ignore
    this.countryRelatedTimezones = ct.getTimezonesForCountry(country_code);
  }


  updateUser() {
    this.clicked = true;
    if (this.isEditable) {
      if (this.isVerificationOn) {
        this.$apollo
          .mutate({
            mutation: VerifyPinAndUpdateEmail,
            variables: {
              pin: this.verificationCode,
              email: this.updatedEmail,
            },
            refetchQueries: [{query: MeQuery}]
          }).then(() => {

            this.$buefy.toast.open({
              message: 'Email Updated successfully.',
              position: 'is-bottom-right',
              type: 'is-black',
            });
            this.isVerificationOn = !this.isVerificationOn;
            this.isEditable = !this.isEditable;
            this.email = this.updatedEmail;

          }).catch((error) => {
            this.parseValidationError(error);

          })
      } else {
        this.$apollo
          .mutate({
            mutation: UpdateEmail,
            variables: {
              email: this.updatedEmail,
            }
          }).then((res) => {
            if (res.data.updateEmail.id) {
              this.$buefy.toast.open({
                message: 'Verification pin sent to your new email id.',
                position: 'is-bottom-right',
                type: 'is-black',
              });
              this.isVerificationOn = !this.isVerificationOn;
            }

          }).catch((error) => {
            this.parseValidationError(error);

          })
      }

    } else {
      this.$apollo
        .mutate({
          mutation: UpdateUserMutation,
          variables: {
            id: this.me.id,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            country: this.country,
            country_code: this.country_code,
            city: this.city,
            timezone: this.timezone
          },
        })
        .then(({data: user}) => {
          this.$store.commit('set_me', user.updateUser);
          this.$buefy.toast.open({
            message: 'Updated successfully',
            position: 'is-bottom-right',
            type: 'is-black',
          });
          this.accountInputChange = false;
        })
        .catch(() => {
        })
        .finally(() => {
          this.clicked = false;
        });
    }
  }

  logout() {
    this.$router.push({ name: 'logout' });
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  openDeleteConfirmation() {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: "Are you sure you want to delete your account ? "
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if(value === true) {
            this.selfDelete();
          }
        }
      },
      width: '500px',
    });
  }

  selfDelete() {
    this.$apollo
      .mutate({
        mutation: gql`
            mutation selfDelete{
                selfDelete
            }`
      }).then(res => {
        this.$gtag.event('pmb_user_account_deletion');
        if(res.data.selfDelete) {
          this.logout();
        }
      })
  }

  created() {
    this.first_name = this.me.first_name;
    this.last_name = this.me.last_name;
    this.email = this.me.email;
    this.country = this.me.country;
    this.country_code = this.me.country_code;
    this.city = this.me.city;
    this.timezone = this.me.timezone;
    this.selectedCountryTimeZone(this.country_code!);

  }

  openEditEmail() {
    this.isEditable = !this.isEditable;
  }

  changeCountry(i: any) {

    this.country_code = i;
    this.country = ct.getCountry(i).name;
    this.city = null;
    this.selectedCountryTimeZone(i);
    this.timezone = this.countryRelatedTimezones[0].name;
    this.accountInputChange = true;
  }

  changeTimezone(i: any) {
    this.timezone = i;
  }
}
