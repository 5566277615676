import gql from 'graphql-tag'
import {PlansDetailedFragment} from "@/graphql/plan";

export const WorkspaceSubscriptionFragment = gql`
    fragment WorkspaceSubscription on WorkspaceSubscription {
        id
        workspace {
            id
            name
        }
        plan {
            ...PlansDetailed
            
        }
        start_at
        end_at
        subscription_id
        subscribed_quantity
        hasAccess
        type
    }
    ${PlansDetailedFragment}
`


export const CreatePaymentIntent = gql`
    mutation CreatePaymentIntent($amount: Int!) {
        createPaymentIntent(amount: $amount) {
            id
            client_secret
        }
    }
`

export const CreateSetupIntent = gql`
    mutation CreateSetupIntent {
        createSetupIntent {
            id
            client_secret
        }
    }
`

export const CreateSubscription = gql`
    mutation CreateSubscription($workspace_id: ID!, $paymentMethod: String!, $stripe_plan_id: String!, $itemQuantity: Int!, $coupon: String) {
        makeSubscription(
            workspace_id: $workspace_id,
            paymentMethod: $paymentMethod,
            stripe_plan_id: $stripe_plan_id,
            itemQuantity: $itemQuantity
            coupon: $coupon
        ) {
            ...WorkspaceSubscription
        }
    }
    ${WorkspaceSubscriptionFragment}
`

export const UpdateSubscription = gql`
    mutation UpdateSubscription($subscription_id: String!, $stripe_plan_id: String, $itemQuantity: Int, $billingCycleAnchor: String, $prorationBehavior: String, $payment_method: String) {
        updateSubscription(input: {
            subscription_id: $subscription_id,
            stripe_plan_id: $stripe_plan_id,
            itemQuantity: $itemQuantity,
            billingCycleAnchor: $billingCycleAnchor,
            prorationBehavior: $prorationBehavior,
            payment_method: $payment_method
        }){
            ...WorkspaceSubscription
        }
    }
    ${WorkspaceSubscriptionFragment}
`

export const AllWorkspaceInvoices = gql`
    query AllWorkspaceInvoices($workspace_id: ID!, $page: Int!) {
        invoices(workspace_id: $workspace_id, page: $page) {            
            has_more
            data {
                id
                amount
                status
                hosted_invoice_url
                invoice_pdf
                period_end
                period_start
                created
            }
        }
    }
`

export const CheckPromotionCode = gql `
    mutation CheckPromotionCode($code: String!) {
        checkPromotionCode(code: $code) {
            id
            active
            code           
            created
            expires_at
            livemode
            max_redemptions
            first_time_transaction
            minimum_amount
            times_redeemed
            coupon {
                id 
                object 
                amount_off 
                duration 
                duration_in_months 
                livemode 
                max_redemptions 
                name 
                percent_off 
                redeem_by 
                times_redeemed 
                valid 
            }
        }
    }
`;

export const CancelSubscription = gql `
    mutation CancelSubscription($subscription_id: String!, $workspace_id: ID!) {
        cancelSubscription(subscription_id: $subscription_id, workspace_id: $workspace_id) {
            ...WorkspaceSubscription
        }
    }
    ${WorkspaceSubscriptionFragment}
`