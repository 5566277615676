import gql from "graphql-tag";

export const CustomTagDetailedFragment = gql `
  fragment CustomTagDetailed on CustomTag {
      id
      name
      color
  }
`;

export const CreateCustomTag = gql `
  mutation createCustomTag($name: String!, $color: String, $board_id: ID!, $objective_id: ID){
      createCustomTag(
          input:{
              name: $name
              color: $color
              board_id: $board_id
              objective_id: $objective_id
          }
      ){
          ...CustomTagDetailed
      }
  }
  ${CustomTagDetailedFragment}
`;

export const UpdateCustomTag = gql `
  mutation updateCustomTag($id: ID!, $name: String!, $color: String){
      updateCustomTag(
          id: $id
          input:{
              name: $name
              color: $color
          }
      ){
          ...CustomTagDetailed
      }      
  }
  ${CustomTagDetailedFragment}
`;

export const DeleteCustomTag = gql `
  mutation deleteCustomTag($id: ID!){
      deleteCustomTag(id:$id){
          id
      }
  }
`;