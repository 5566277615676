
























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class WorkspaceAvatar extends Vue {

  @Prop({ required: true })
  workspaceName!: string;

  @Prop({ required: false, default: 'is-36x36' })
  imgSize!: string;

  @Prop({ required: false, default: '4px' })
  radius!: string;

  @Prop({ required: false, default: false })
  defaultColor!: boolean;

  @Prop({ required: false, default: false })
  avatarLess!: boolean;

  @Prop({ required: false })
  avatar!: string;

  @Prop({ required: false })
  customClass!: string;

  @Prop({ required: false, default: false })
  hasNotification!: string;
}
