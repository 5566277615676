






































































import {Component, Prop, Vue} from 'vue-property-decorator';
import AddBookmark from "@/components/bookmarks/AddBookmark.vue";
import {DeleteBookmarkMutation} from "@/graphql/bookmark";

@Component({
  components: {},
})
export default class ObjectiveBookmarks extends Vue{
  sliceAmount: number = 3;

  @Prop({default: undefined, required: true})
  objective_id!: string;

  @Prop({default: undefined, required: true})
  bookmarks!: Array<any>;

  get bookmarksLength() {
    return this.bookmarks.length
  }

  openBookmarks() {
    this.$buefy.modal.open({
      component: AddBookmark,
      props: {
        origin: 2,
        origin_id: this.objective_id
      },
      width: '520px',
      parent: this,
      events: {
        'bookmark': (value: any) => {
          this.bookmarks.push(value)
        }
      }
    });
  }

  deleteBookmark(bookmark_id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteBookmarkMutation,
        variables: {
          id: bookmark_id,
        },
      })
      .then((result: any) => {
        let index = this.bookmarks.findIndex((bookmark: any) => bookmark.id === result.data.deleteBookmark.id);
        this.bookmarks.splice(index, 1);
      })
    ;
  }
}
