


















































































































































































































































































































































































































































import {Component, Prop, Mixins} from 'vue-property-decorator';
import gql from 'graphql-tag';
import {BoardQuery} from "@/graphql/board";
import UserAvatar from '@/components/user/UserAvatar.vue';
import DateTimeInput from '@/components/board/DateTimeInput.vue';
import formatDistance from "date-fns/formatDistance";
import {
  ObjectiveDetailedFragment,
  DeleteObjectiveMutation,
  ObjectiveQuery,
  CreateObjectiveAttachments, DeleteObjectiveAttachment, ObjectivesQuery
} from '@/graphql/objective';
import KeyResults from '@/components/board/KeyResults.vue';
import {Container, Draggable} from "vue-smooth-dnd"
import ObjectivePriority from '@/components/board/objective/ObjectivePriority.vue';
import ObjectiveTypeSelector from '@/components/board/objective/ObjectiveTypeSelector.vue';
import ObjectiveComments from '@/components/board/objective/ObjectiveComments.vue';
import ObjectiveOwnerSelector from '@/components/board/objective/ObjectiveOwnerSelector.vue';
import ObjectiveInformation from "@/components/board/objective/ObjectiveInformation.vue";
import CreateOrUpdateKeyResult from '@/components/board/CreateOrUpdateKeyResult.vue';
import Initiative from "@/components/board/Initiative.vue";
import CreateInitiative from "@/components/board/CreateInitiative.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import KeyResult from "@/components/board/KeyResult.vue";
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import ColorPicker from "@/components/general/ColorPicker.vue";
import LayoutFrame from "@/components/layout/LayoutFrame.vue";
import CreateObjectiveComment from "@/components/board/objective/CreateObjectiveComment.vue";
import KeyResultItem from "@/components/board/KeyResultItem.vue";
import {KeyResult as KeyResultObject, ObjectiveComment, User} from "@/typescript/types";
import {Objective as ObjectiveObject} from "@/typescript/types";
import sortBy from 'lodash/sortBy';
import PusherService from "@/PusherService";
import ObjectiveNorthStarLinker from "@/components/board/objective/ObjectiveNorthStarLinker.vue";
import ObjectiveReminder from "@/components/board/objective/ObjectiveReminder.vue";
import ObjectiveCalender from "@/components/board/objective/ObjectiveCalender.vue";
import ObjectiveBookmarks from "@/components/board/objective/ObjectiveBookmarks.vue";
import ObjectiveMemberSelector from "@/components/board/objective/ObjectiveMemberSelector.vue"
import ObjectiveTags from "@/components/board/objective/ObjectiveTags.vue";
import ObjectiveRecurrence from "@/components/board/objective/ObjectiveRecurrence.vue";
import {ValidationErrorParserMixin} from "@/mixins/ValidationErrorParserMixin";
import {CompletedObjectiveQuery, WorkspaceQuery} from "@/graphql/workspace";
import store from "@/store";
import Template from "@/components/templates/Template.vue";
import DuplicateObjective from "@/components/board/objective/DuplicateObjective.vue";
import ObjectiveDetailedMoreActions from "@/components/board/objective/ObjectiveDetailedMoreActions.vue";
import {endOfToday} from "date-fns";
import ObjectiveStatus from "@/components/board/objective/ObjectiveStatus.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import { utcToZonedTime } from 'date-fns-tz';
import Paywall from "@/components/subscription/Paywall.vue";

@Component({
  components: {
    ObjectiveStatus,
    ObjectiveDetailedMoreActions,
    Template,
    ObjectiveRecurrence,
    ObjectiveBookmarks,
    ObjectiveReminder,
    ObjectiveNorthStarLinker,
    KeyResultItem,
    CreateObjectiveComment,
    LayoutFrame,
    ColorPicker,
    SmartDateTime,
    KeyResult,
    ConfirmDelete,
    CreateInitiative,
    Initiative,
    Container,
    Draggable,
    CreateOrUpdateKeyResult,
    ObjectiveInformation,
    ObjectiveOwnerSelector,
    ObjectiveTypeSelector,
    ObjectiveComments,
    ObjectivePriority,
    KeyResults,
    DateTimeInput,
    UserAvatar,
    ObjectiveCalender,
    ObjectiveMemberSelector,
    ObjectiveTags,
    ConfettiCanon
  },
  apollo: {
    objective: {
      query: ObjectiveQuery,
      fetchPolicy: 'network-only',
      result(result) {
        if (result.data.objective) {
          /**
           * runs even if error is present, so check for objective
           * */
          this.dueDate = result.data.objective.due_date;
          this.startDate = result.data.objective.start_date;
          this.assignedPriority = result.data.objective.priority;
          this.bgColor = result.data.objective.background_color;
          this.oName = result.data.objective.name;
          this.oId = result.data.objective.id;
          this.oStatus = result.data.objective.status;
          this.is_recurring = result.data.objective.is_recurring;
          this.activeUsers = result.data.objective.active_users;

          this.$events.fire('calender-assignees', result.data.objective);
        
          if(this.$route.name == 'overview') {
            this.$store.commit('set_active_board_pid', result.data.objective.board.pid);
            //@ts-ignore
            this.$store.commit('set_last_active_board_pid', result.board.pid);

          }

        }
      },
      variables() {
        return {
          pid: this.objectivePid
        };
      },
      error(error) {
       
        if(error.graphQLErrors[0].message) {
          this.$buefy.toast.open({
            message: error.graphQLErrors[0].message,
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$router.go(-1);
        }
      }
    },
    board: {
      query: BoardQuery,
      variables() {
        return {
          pid: this.$store.state.active_board_pid,
        };
      },
      update(result) {
        if (result.board) {
          this.$store.commit('set_board', result.board);
          this.$store.commit('set_active_board_pid', result.board.pid);
          this.$store.commit('set_last_active_board_pid', result.board.pid);

        }
      },
      error(error) {
       
        if(error.graphQLErrors[0].message) {
          this.$buefy.toast.open({
            message: error.graphQLErrors[0].message,
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$router.go(-1);
        }
      }
    },
  }
})
export default class ObjectiveDetailed extends Mixins(ValidationErrorParserMixin) {
  oName: string | null = null;
  oId: string | null = null;
  oStatus: string | null = null;
  input: string | null = null;
  dueDate: Date | null = null;
  startDate: Date | null = null;
  bgColor: string | null = null;
  assignedPriority: string | null = null;
  name = '';
  is_recurring = false;
  isScrolledFromTop: boolean = false;
  setRecurringActive: boolean = false;
  addTasksActive: boolean = false;
  addRecurrenceActive: boolean = false;
  moreOptions: boolean = false;

  openStatusOverlay: boolean = false;
  openRemindersOverlay: boolean = false;
  openTypesOverlay: boolean = false;

  markedCompleted: boolean = false;
  successFadeOut: boolean = false;
  successFadeIn: boolean = false;

  filesUploadLoading: boolean = false;

  autofocusObjectiveDescription: boolean = false;

  settingsActive = true;

  objective: ObjectiveObject | undefined | null = null;
  postComment: ObjectiveComment | undefined | null = null;
  attachments: Array<any> = [];
  showCompletedKeyResult: boolean = false;
  activeUsers: Array<any> = [];
  shareUrl: string | null = null;
  completed_at: Date | null = null;
  isStatusChangedToCompleted: boolean = false;
 

  @Prop({required: false})
  objectivePid!: string;

  @Prop({required: false})
  justCreated!: boolean;

  @Prop({required: false, default: false})
  openObjectiveShareUrl!: boolean

  @Prop({required: false, default: null})
  nsStartDate!: Date

  @Prop({required: false, default: null})
  nsDueDate!: Date

  get meId() {
    return this.$store.state.me.id;
  }

  get board() {
    return this.$store.state.board;
  }

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get objectiveChanged() {
    return true;
  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access 
  }

  get canAddSubtasks() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_subtasks_access) || this.hasFreemiumAccess
  }

  get canAddFiles() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.file_manager_access) || this.hasFreemiumAccess
  }

  get checkIfAlreadyActiveMember(){

    if(this.activeUsers?.length > 0){
      let isMe = this.activeUsers?.filter((user: any) => {
        if(user.id === this.me.id) {
          return user;
        }
      })

      if(isMe.length > 0){
        return true;
      }
    }
    return false;
  }

  get hasAvailableCollaborators() {
    if(this.board.members.length >= 1) {
      return true;
    } else {
      return false;
    }
  }

  get openedExternally() {
    if (this.$route.params.board_pid) {
      return false
    } else {
      return true;
    }
  }

  get completedKeyResultsCount(){
    return this.objective?.key_results?.data.filter((keyResult: any) => {
      return keyResult.completed === true;
    }).length;
  }

  /**
   * Filtered assignee list where assignees are not deleted
   */

  get nonDeletedObjectiveAssignees() {
    return this.objective?.assignees?.filter((assignee: User)=>assignee.deleted_at === null);
  }

  /**
   * Keep order the same when objectives are being updated
   */
  get keyResults() {
    return sortBy(this.objective?.key_results?.data, ['order']);
  }

  get taskCompletionRate() {
    let num = this.objective!.metrics.keyResultsCompletedCount / this.objective!.metrics.keyResultsCount * 100;
    // @ts-ignore
    if (num > 0) {
      if(num >= 100){
        //this.oStatus = 'completed';
        //this.updateObjective();
      }
      // if(this.objective!.metrics.keyResultsCompletedCount == 1 && this.objective?.status == 'not_started'){
      //   this.oStatus = 'in_progress';
      //   this.updateObjective();
      // }
      return num.toFixed(0);
    } else {
      return null;
    }
  }

  get todayTime() {
    let now = new Date();
    let d = new Date();
    d.setHours(17,0,0);

    if(now.getTime() < d.getTime()){
      return d;
    }else{
      return endOfToday();
    }
  }

  

  get isActivityDueDateInvalid() {
    if (!this.dueDate || !this.startDate) {
      return false; // No error if one of the dates is not set
    }
    return new Date(this.dueDate) < new Date(this.startDate);
  }

  get isDueDateAfterGoalDueDate() {
    if (!this.dueDate || !this.startDate) {
      return false; // No error if one of the dates is not set
    }
    return new Date(this.dueDate) < new Date(this.startDate);
  }

  // Checks if nsStartDate is after objective.start_date
  get isNsStartDateAfterStartDate(): boolean {
    if (!this.nsStartDate || !this.objective?.start_date) {
      return false;
    }

    const localNsStartDate = this.convertToLocalDateTime(this.nsStartDate);
    const objectiveStartDate = new Date(this.objective.start_date);

    return localNsStartDate > objectiveStartDate;
  }

  // Checks if objective.start_date is after nsDueDate
  get isObjectiveStartDateAfterNsDueDate(): boolean {
    if (!this.objective?.start_date || !this.nsDueDate) {
      return false;
    }

    const objectiveStartDate = new Date(this.objective.start_date);
    const localNsDueDate = this.convertToLocalDateTime(this.nsDueDate);

    return objectiveStartDate > localNsDueDate;
  }

  // Checks if objective.due_date is before nsStartDate
  get isObjectiveDueDateBeforeNsStartDate(): boolean {
    if (!this.objective?.due_date || !this.nsStartDate) {
      return false;
    }

    const objectiveDueDate = new Date(this.objective.due_date);
    const localNsStartDate = this.convertToLocalDateTime(this.nsStartDate);

    return objectiveDueDate < localNsStartDate;
  }

  // Checks if objective.due_date is after nsDueDate
  get isObjectiveDueDateAfterNsDueDate(): boolean {
    if (!this.objective?.due_date || !this.nsDueDate) {
      return false;
    }

    const objectiveDueDate = new Date(this.objective.due_date);
    const localNsDueDate = this.convertToLocalDateTime(this.nsDueDate);

    return objectiveDueDate > localNsDueDate;
  }

  get dateErrorMessage(): string | null {

    if (this.isActivityDueDateInvalid) {
      return "Warning: The due date is set before the start date.";
    }
    if (this.isNsStartDateAfterStartDate) {
      return "Warning: The activity start date is set before the goal start date.";
    }

    if (this.isObjectiveStartDateAfterNsDueDate) {
      return "Warning: The activity start date is set after the goal due date.";
    }

    if (this.isObjectiveDueDateBeforeNsStartDate) {
      return "Warning: The activity due date is set before the goal start date.";
    }

    if (this.isObjectiveDueDateAfterNsDueDate) {
      return "Warning: The activity due date is set after the goal due date.";
    }

    return null; // No errors
  }


  newKeyResult(result: KeyResultObject): void {
    this.objective?.key_results?.data.push(result);
  }

  commentCreated(comment: ObjectiveComment) {

    this.objective?.comments?.push(comment);
    this.postComment = comment;
  }

  convertToLocalDateTime(value: any): Date {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return utcToZonedTime(value, localTimeZone);
  }

  commentCount(comments: ObjectiveComment){
    //@ts-ignore
    this.objective?.comments?.push(comments);
  }

  isViewingObjective(id: string): boolean {
    return PusherService.isViewingObjective(id, this.objectivePid)
  }

  setPriority(value: string) {
    this.assignedPriority = value;
    this.updateObjective();
  }

  openBookmarks() {
    // @ts-ignore
    this.$refs.bookmarks.openBookmarks();
  }

  openTags() {
    // @ts-ignore
    this.$refs.tags.openCreateTags();
  }

  setStatus(status: string) {
    this.oStatus = status;
    if(status === 'completed') {
      this.completed_at=new Date();
      this.isStatusChangedToCompleted=true;
    }else{
      this.completed_at=null;
      this.isStatusChangedToCompleted=false;
    }
    this.updateObjective();

    this.openStatusOverlay = false;
    if(status === 'completed') {
      this.$gtag.event('pmb_activity_marked_completed');
      this.markedCompleted = true;
      this.successFadeIn = true;
      setTimeout(() => {
        this.successFadeIn = false;
        this.successFadeOut = true
      }, 2000);
      setTimeout(() => {
        this.markedCompleted = false;
        this.successFadeOut = false;
        this.successFadeIn = false;
      }, 3500);
    }
  }

  setColor(value: any) {
    if (value === '#ffffff') {
      this.bgColor = null;
    } else {
      this.bgColor = value;
    }

    this.updateObjective();
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  closeModal() {
   
    if(this.$route.name == 'overview') {
      this.$store.commit('set_active_board_pid', null);
      this.$store.commit('set_board', null);

    }
    // @ts-ignore
    this.$parent.close();
  }

  openDatePicker(type: string, date: any) {
    if(date == null) {
      date = this.todayTime;
    }
    //@ts-ignore
    if(this.objective?.recurrences?.length <= 0){
      this.$buefy.modal.open({
        component: SmartDateTime,
        width: '280px',
        // @ts-ignore
        customClass: 'is-paddingless',
        parent: this,
        // 'min-date': (this.startDate) ? new Date(this.startDate) : null
        props: { 'remove-due-date': true, 'set-date': date},
        events: {
          //@ts-ignore ToDo: fix type issue
          'date': value => {
            if (value) {
              if(type == 'due-date') {
                this.dueDate = value;
                this.updateObjective();
                this.$buefy.toast.open({
                  message: 'Due date updated!',
                  position: 'is-bottom-right',
                  type: 'is-black',
                });
              }else if(type == 'start-date') {
                this.startDate = value;
                this.updateObjective();
                this.$buefy.toast.open({
                  message: 'Start date updated!',
                  position: 'is-bottom-right',
                  type: 'is-black',
                });
              }

            
            }else{
              if(type == 'due-date') {
                this.dueDate = null;
                this.updateObjective();
                this.$buefy.toast.open({
                  message: 'Due date removed',
                  position: 'is-bottom-right',
                  type: 'is-black',
                });
              }else if(type == 'start-date') {
                this.startDate = null;
                this.updateObjective();
                this.$buefy.toast.open({
                  message: 'Start date removed',
                  position: 'is-bottom-right',
                  type: 'is-black',
                });
              }
              
            }
          }
        },
      });
    }else{
      this.$buefy.toast.open({
        message: 'You cannot set date as it is in recurrence!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    }

  }

  updateObjective() {
    return this.$apollo.mutate({
      mutation: gql`
                mutation updateObjective($id: ID!, $name: String, $due_date: DateTime, $start_date: DateTime,  $background_color: String, $priority: ObjectivePriority, $status: ObjectiveStatus, $is_recurring: Boolean, $active_users: [ID!], $completed_at: DateTime) {
                    updateObjective(id: $id, input: { name: $name, due_date: $due_date, start_date: $start_date, background_color: $background_color, priority: $priority, status: $status, is_recurring: $is_recurring, active_users:{ connect: $active_users}, completed_at: $completed_at }) {
                        ...ObjectiveDetailed
                    }
                }
                ${ObjectiveDetailedFragment}
            `,
      variables: {
        id: this.oId,
        due_date: this.dueDate,
        start_date: this.startDate,
        background_color: this.bgColor,
        priority: this.assignedPriority,
        status: this.oStatus,
        is_recurring: this.is_recurring,
        active_users: this.activeUsers.map(id => id.id),
        completed_at: this.completed_at
      },
      refetchQueries:[
        {query: ObjectiveQuery, variables:{pid: this.objective!.pid}},
        {query: CompletedObjectiveQuery, variables:{workspace_id: this.$store.state.workspace.id, board_id: (this.board) ? this.board.id : null}}
      ]
    }).then((response) => {
      if (response.data) {
        this.objectiveUpdated(response.data.updateObjective)
        //this.$events.fire('objective-updated',response.data.updateObjective);


      }

      return response
    });
  }

  objectiveUpdated(objective: ObjectiveObject): void {
    this.$emit('objective-updated', objective)
    this.$events.fire('objective-updated', objective)
    this.$events.fire('objective-updated-from-northstar',objective);
    this.$events.fire('update-assignees', objective.assignees)
  }

  deleteObjective() {
    this.$apollo.mutate({
      mutation: DeleteObjectiveMutation,
      variables: {
        id: this.oId,
      },
      refetchQueries:[{ query: ObjectivesQuery, variables: { board_id: this.board.id}}, { query: BoardQuery, variables: { id: this.board.id}}]
    }).catch((_error) => {
     
    }).then((res: any) => {
      this.$events.fire('objective-deleted', res.data.deleteObjective);
      this.$buefy.toast.open({
        message: 'Deleted!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    }).finally(() => {
      //@ts-ignore
      this.$parent.close();
    });
  }

  getGhostParent() {
    return document.body;
  }

  onDrop(dropResult: any) {
    if (this.objective && this.objective.key_results) {
      this.objective.key_results.data = this.applyDrag(this.objective.key_results.data, dropResult)
    }
  }

  applyDrag(arr: KeyResultObject[], dragResult: any): KeyResultObject[] {
    const {removedIndex, addedIndex} = dragResult
    if (removedIndex === null && addedIndex === null) return arr

    const result = [...arr]

    let keyResult = null;

    if (removedIndex !== null) {
      keyResult = result.splice(removedIndex, 1)[0]
    }

    if (addedIndex !== null && keyResult) {
      result.splice(addedIndex, 0, keyResult)

      this.reindexOrder(result)
    }

    return result;
  }

  /**
   * Re-index order so always starting from 0
   */
  reindexOrder(result: KeyResultObject[]) {
    result.forEach((keyResult: KeyResultObject, index: number) => {
      keyResult.order = index;

      this.updateKeyResultOrder(keyResult);
    });
  }

  updateKeyResultOrder(item: KeyResultObject) {
    this.$apollo.mutate({
      mutation: gql`
                mutation updateKeyResult($id: ID!, $order: Int) {
                    updateKeyResult(id: $id, input: { order: $order }) {
                        id,
                        order
                    }
                }
            `,
      variables: {
        id: item.id,
        order: item.order
      },
    }).catch((_error) => {

    });
  }

  setAssignee(assignees: any) {
    this.objective!!.assignees = assignees;
  }

  uploadFiles(files: any) {
    if (this.canAddFiles) {
      this.filesUploadLoading = true;
      this.$apollo.mutate({
        mutation: CreateObjectiveAttachments,
        variables: {
          objective_id: this.objective!.id,
          files: files
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: store.state.active_workspace_pid}}],
      }).then((res: any) => {
        res.data.createObjectiveAttachments.forEach((value: any) => {
          this.objective!!.attachments!!.push(value);
        });
        this.filesUploadLoading = false;
        this.$buefy.toast.open({
          message: 'Added!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        this.attachments = [];
      }).catch((errors) => {
        this.filesUploadLoading = false;
        this.parseValidationError(errors);
        this.attachments = [];

        for (const [key, value] of Object.entries(this.arrayValidationMessages()!!)) {
          let index = key.split('.');
          this.$buefy.toast.open({
            message: files[index[1]].name + ' -- ' + value,
            position: "is-bottom-right",
            type: "is-warning",
          });
        }
      })
    } else {
      this.openPaywall();
    }
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  getAttachment(fileName: string, folder: string) {
    //@ts-ignore
    return this.$attachmentsLinks.file_path[folder] + fileName;
  }

  deleteAttachment(attachment_id: string, objective_id: string) {
    this.$apollo.mutate({
      mutation: DeleteObjectiveAttachment,
      variables: {
        objective_id: objective_id,
        attachment_id: attachment_id
      }
    }).then((res: any) => {
      let index = this.objective!!.attachments!!.findIndex((attachment: any) => attachment.id === res.data.deleteObjectiveAttachment.id);
      if (index != -1) {
        this.objective!!.attachments!!.splice(index, 1);
        this.$buefy.toast.open({
          message: 'Deleted!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      }
    })
  }

  dragover(event: any) {
    event.preventDefault();
    /**
     * Add some visual fluff to show the user can drop its files
     */
    if (!event.currentTarget.classList.contains('objective-modal-inner')) {
      event.currentTarget.classList.add('currently-dropping');
    }
  }

  dragleave(event: any) {
    /**
     * Clean up
     */
    event.currentTarget.classList.remove('currently-dropping');
  }

  drop(event: any) {
    event.preventDefault();
    this.uploadFiles(event.dataTransfer.files);
    event.currentTarget.classList.remove('currently-dropping');
  }

  handleScroll() {
    const modalWindow = document.querySelector('#mainWrapElement') as HTMLElement;
    if (modalWindow.scrollTop > 0) {
      this.isScrolledFromTop = true;
    }
    if(modalWindow.scrollTop === 0) {
      this.isScrolledFromTop = false;
    }
  }

  mounted() {
    let element = document.querySelector("#mainWrapElement");
    if(element) {
      // @ts-ignore
      document.querySelector("#mainWrapElement")!.addEventListener('scroll', this.handleScroll);
    }
    this.$events.listen('set-assignee-from-keyresult', eventData => {
      this.setAssignee(eventData);
    })

    this.$events.listen('key-result-assignee-changed', eventData => {
      let index = this.objective?.key_results?.data.findIndex((keyResult: any) => {
        return keyResult.id === eventData.keyResultId;
      });
      if(index != -1){
        //@ts-ignore
        this.objective?.key_results?.data[index].assignee = eventData.assignee;
      }
    })

    if(this.$props.openObjectiveShareUrl) {
      if(this.$route.params.board_pid && this.board) {
        this.shareUrl = window.location.origin+'/'+this.$store.state.workspace.pid+'/'+this.objectivePid+'/'+this.board.pid+'/'+this.objectivePid+'/overview/settings';
        this.$router.push({name: 'board-layout', params:{ workspace_pid: this.$store.state.workspace.pid, board_pid: this.board.pid, objective_pid: this.objectivePid }});
      }else{
        this.shareUrl = window.location.origin+'/'+this.$store.state.workspace.pid+'/'+this.objectivePid+'/all';
      }
    }

  }

  duplicateObjective() {
    this.$buefy.modal.open({
      component: DuplicateObjective,
      props: {
        objective: this.objective
      },
      width: '420px',
      parent: this,
    })
  }

  activeOnObjective(option: any) {
    if (option == 0) {
      let index = this.activeUsers.findIndex((user: any) => {
        return user.id === this.me.id;
      });

      if (index != -1) {
        this.activeUsers.splice(index, 1);
      }
    } else {
      this.activeUsers.push({id: this.me.id, name: this.me.name, avatar: this.me.avatar, initials: this.me.initials})
    }

    this.updateObjective();
  }

  copyShareUrl() {
    //@ts-ignore
    const textToCopy = this.$refs.shareurl.$el.querySelector('input');
    textToCopy.select()
    document.execCommand("copy");
    this.$buefy.toast.open({
      message: 'Objective url copied',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }

  destroyed() {
   
    if(this.$route.name == 'overview') {
      this.$store.commit('set_active_board_pid', null);
      this.$store.commit('set_board', null);

    }
    if(this.openObjectiveShareUrl) {
      this.$router.go(-1);
    }
  }


}
