



















































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class InlineDropdown extends Vue {
  close() {
    this.$emit('close', true);
  }
}
