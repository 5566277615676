

































import {Component, Vue} from "vue-property-decorator";
import Template from "@/components/workspace/Template.vue";
import {AddNewReferralCode, MeQuery} from "@/graphql/auth";

@Component({
  components: {Template}
})
export default class Referral extends Vue {
  referralCode: string | null = null;

  get me() {
    return this.$store.state.me;
  }

  get haveReferral(){
    if(this.$store.state.me.referral_code){
      return true;
    }
    return false;
  }

  createReferralLink() {
    this.$apollo
      .mutate({
        mutation: AddNewReferralCode,
        refetchQueries: [{query: MeQuery}]
      })
      .then(res =>{
        this.referralCode = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/register?referral=' + res.data.addNewReferralCode.referral_code;
      })
  }

  copyReferralLink() {
    //@ts-ignore
    let textToCopy = this.$refs.myReferralLink.$el.querySelector('input')
    textToCopy.select()
    document.execCommand("copy");
    this.$buefy.toast.open({
      message: 'Copied the referral link',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }

  mounted() {
    if(this.haveReferral) {
      this.referralCode = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/register?referral=' + this.$store.state.me.referral_code;
    }
  }
}
