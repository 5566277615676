




















































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { DeleteInitiativeMutation, UpdateInitiativeMutation, ObjectiveQuery } from '@/graphql/objective';
import UserAvatar from "@/components/user/UserAvatar.vue";
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import ObjectiveDetailed from "@/components/board/objective/ObjectiveDetailed.vue";

@Component({
  components: {ObjectiveDetailed, SmartDateTime, UserAvatar},
  props: {
    initiative: {}
  }
})
export default class InitiativeDetailed extends Vue {
  iDescription: string | null = null;
  iPriority = 'normal';
  iStatus: string | null = null;
  iStartDate: Date | null = null;
  iDueDate: Date | null = null;
  iOwnerId: string | null = null;

  expandInfo = false;
  requestDelete = false;

  openDatePicker() {
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      props: {
        date: this.iDueDate
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if(value) {
            this.iDueDate = value;
            this.updateInitiative();
          }
        }
      },
    });
  }

  openObjectiveDetailed() {
    this.$buefy.modal.open({
      component: ObjectiveDetailed,
      props: {
        objectivePid: this.$props.initiative.objective.pid
      },
      // @ts-ignore
      customClass: 'objective-modal',
      width: '780px',
      parent: this,
    });
  }

  setPriority(priority: string) {
    this.iPriority = priority;
    this.updateInitiative();
  }

  toggleInitiativeStatus() {
    if(this.iStatus === 'completed') {
      this.iStatus = null;
    } else {
      this.iStatus = 'completed';
    }
    this.updateInitiative();
  }

  setNewOwner(userId: string) {
    this.iOwnerId = userId;
    this.updateInitiative();
  }


  updateInitiative() {
    this.$apollo
      .mutate({
        mutation: UpdateInitiativeMutation,
        variables: {
          id: this.$props.initiative.id,
          description: this.iDescription,
          assignee_id: this.iOwnerId,
          status: this.iStatus,
          start_date: this.iStartDate,
          due_date: this.iDueDate,
          // Todo: if we send in null in priority, the whole workspace crashes... FIX
          priority: this.iPriority,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.initiative.objective.id } }],
      })
      .then(_ => {
        //
      });
  }

  deleteInitiative(id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteInitiativeMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.initiative.objective.id } }],
      })
      .then(_ => {
        //@ts-ignore ToDo: fix type issue
        this.$parent.close();
      });
  }

  mounted() {
    if(this.$props.initiative.priority) {
      this.iPriority = this.$props.initiative.priority;
    }
    this.iPriority = this.$props.initiative.priority;
    this.iDescription = this.$props.initiative.description;
    this.iDueDate = this.$props.initiative.due_date;
    this.iStatus = this.$props.initiative.status;
    this.iStartDate = this.$props.initiative.start_date
    this.iOwnerId = this.$props.initiative.assignee.id;
  }


}
