import gql from 'graphql-tag';

export const PlansDetailedFragment = gql`
  fragment PlansDetailed on Plan {
      id
      title
      description
      price
      monthly_base_price
      interval
      planAccess {
          id
          max_user
          max_board
          file_manager_access
          create_chat_message_access
          pinned_message_access
          room_privacy_settings_access
          max_room_templates
          max_goal_templates
          archive_room_access
          chat_history_access
          all_timeline_period_access
          convert_to_template_access
          bookmark_access
          app_integration_access
          file_manager_access
          milestone_access
          ns_visibility_access
          objective_recurrence_access
          objective_duplicaion_access
          objective_reminders_access
          objective_priority_access
          objective_tags_access
          objective_subtasks_access
      }
      stripe_plan_id
      stripeProduct {
          id
          stripe_product_id
          name
      }
  }
`

export const getAllPlans = gql`
    query GetAllPlans {
        plans {
            ...PlansDetailed
        }
    }
    ${PlansDetailedFragment}
`