














































































































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {BoardMember, KeyResult, User} from '@/typescript/types';
import UserAvatar from "@/components/user/UserAvatar.vue";
import {
  DeleteKeyResultMutation,
  ObjectiveQuery,
  UpdateKeyResultMutation
} from "@/graphql/objective";
import Encryption from '@/encryption';
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import gql from "graphql-tag";
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {Template, UserAvatar},
})
export default class KeyResultDetailed extends Vue {
  requestDelete = false;
  expandInfo = false;
  metricsActive = false;

  description: string | null = null;
  priority: string | null = null;
  completed = false;
  assigneeId: string | null = null;

  currentValue: number | null = null;
  targetValue: number | null = null;

  currentlyEditing = false;
  dueDate: Date | null = null;
  newAssignee: User | null = null;


  @Prop({required: true})
  keyResult!: KeyResult;

  @Prop({required: true, default: null})
  assignedMembers!: Array<any>

  get board() {
    return this.$store.state.board;
  }

  get boardMembers() {
    return this.board.members.filter((member: any)=>member.user.deleted_at===null);
  }

  mounted() {
    if (this.keyResult) {
      this.description = Encryption.decrypt(this.keyResult.description);
      this.priority = this.keyResult.priority;
      this.completed = this.keyResult.completed;
      this.assigneeId = (this.keyResult.assignee)?this.keyResult.assignee.id:null;
      this.currentValue = this.keyResult.current_value;
      this.targetValue = this.keyResult.target_value;
      this.dueDate = this.keyResult.due_at;
    }
    
  }

  undoChanges() {
    this.description = this.keyResult.description;
    this.currentlyEditing = false;
  }

  setPriority(priority: string) {
    this.priority = priority;
    this.updateKeyResult();
  }

  setNewOwner(userId: string) {
    this.assigneeId = userId;
    this.updateKeyResult();
  }

  /**
     * Changes the selected user before running mutation for quicker feel
     */
  setUpdatedUserInDropDown(id: string | null) {
    if (this.keyResult && this.keyResult.assignee && id) {
      this.keyResult.assignee.id = id

      let updatedTo = this.boardMembers.find((member: BoardMember) => {
        return member.user.id === id;
      });

      this.newAssignee = updatedTo.user;

      this.keyResult.assignee = this.newAssignee;
      this.$forceUpdate();
    }
  }

  updateKeyResult() {
    this.currentlyEditing = false;
    this.metricsActive = false;

    this.setUpdatedUserInDropDown(this.assigneeId)

    this.$apollo
      .mutate({
        mutation: UpdateKeyResultMutation,
        variables: {
          id: this.keyResult.id,
          description: this.description,
          assignee_id: this.assigneeId,
          priority: this.priority,
          current_value: this.currentValue,
          target_value: this.targetValue,
          due_at: this.dueDate
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.keyResult.objective.id } }]
      })
      .catch((_error) => {
        
      })
      .finally(() => {
        this.$events.fire('key-result-assignee-changed', {keyResultId: this.keyResult.id, assignee: this.newAssignee});
      })
    ;
    this.addAssigneeAsMember();
  }

  addAssigneeAsMember(){
    let index = this.assignedMembers?.findIndex((user: any) => {
      return user.id === this.assigneeId;
    });

    if(index == -1 || index == undefined){
      let selectedAssignees = [];
      let allAssignedmembers = [...this.assignedMembers];
      if(index == undefined){
        selectedAssignees.push(this.assigneeId);
      }else{
        selectedAssignees = allAssignedmembers?.map(id => id.id);
        selectedAssignees.push(this.assigneeId);
      }

      this.$apollo.mutate({
        mutation: gql`
                mutation assignUsers($objective_id: ID!, $user_ids: [ID!]) {
                    assignUsers(input: { objective_id: $objective_id, user_ids: $user_ids }) {
                        avatar
                        email
                        first_name
                        id
                        initials
                        last_name
                        name

                    }
                }
            `,
        variables: {
          objective_id: this.$props.keyResult.objective.id,
          user_ids: selectedAssignees,
        },
        refetchQueries: [{query: ObjectiveQuery, variables: { id: this.$props.keyResult.objective.id }}]
      })
        .finally(() => {
          this.assignedMembers.push(this.newAssignee)
          this.$events.fire('set-assignee-from-keyresult', this.assignedMembers);
          this.$buefy.toast.open({
            message: 'Member Added!',
            position: 'is-bottom-right',
            type: 'is-black',
          });
        });
    }

  }

  deleteKeyResult(id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteKeyResultMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{query: ObjectiveQuery, variables: {id: this.keyResult.objective.id}}],
      })
      .then(_ => {
        //@ts-ignore ToDo: fix type issue
        this.$parent.close();
        this.$buefy.toast.open({
          message: 'Deleted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
  }

  openDatePicker() {
    let d = new Date();

    let selectedDate = (this.dueDate) ? new Date(this.dueDate) : d.setHours(17,0,0);
    this.$buefy.modal.open({
      component: SmartDateTime,
      props: {setDate: selectedDate, removeDueDate: true},
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {

          this.dueDate = value;
          this.updateKeyResult();

        }
      },
    });
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.$store.state.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId && member.user.deleted_at === null;
    });


    if (member[0].access_workspace == 0) {
      return false;
    } else {
      return true;
    }
  }
}
