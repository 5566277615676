












import { Component, Vue } from 'vue-property-decorator';
import { CompleteKeyResultMutation, ObjectiveQuery } from '@/graphql/objective';
import CreateOrUpdateKeyResult from '@/components/board/CreateOrUpdateKeyResult.vue';
import { KeyResult, Objective } from '@/typescript/types';
import UserAvatar from '@/components/user/UserAvatar.vue';
import KeyResultItem from '@/components/board/KeyResultItem.vue';

@Component({
  components: { KeyResultItem, UserAvatar, CreateOrUpdateKeyResult },
  props: {
    objectiveId: {
      required: true,
    },
  },

  apollo: {
    objective: {
      query: ObjectiveQuery,
      variables() {
        return {
          id: this.$props.objectiveId,
        };
      },
    },
  },
})
export default class KeyResults extends Vue {
  objective: Objective | null = null;
  selectedKeyResult: number | null = null;
  createKeyResultActive = false;

  completeKeyResult(id: string) {
    this.$apollo.mutate({
      mutation: CompleteKeyResultMutation,
      variables: {
        id: id,
      },
    });
  }

  newKeyResult(keyResult: KeyResult) {
    this.objective!.key_results!.data.push(keyResult);
    this.createKeyResultActive = false;
  }
}
