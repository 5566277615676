

































































import { Component, Vue } from 'vue-property-decorator';
import formatDistance from "date-fns/formatDistance";
import { UpdateInitiativeMutation } from '@/graphql/objective';
import InitiativeDetailed from "@/components/board/InitiativeDetailed.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";


@Component({
  components: {UserAvatar, InitiativeDetailed},
  props: {
    initiative: {},
    index: {},
    showAssignee: {},
  },

})
export default class Initiative extends Vue {
  priority: string | null = 'normal';
  expanded = false;
  status: string | null = null;

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  toggleInitiativeStatus() {
    if(this.status === 'completed') {
      this.status = null;
    } else {
      this.status = 'completed';
      this.playSound();
    }
    this.updateInitiative();
  }

  playSound() {
    /**
     * this plays when an initiative is marked as completed
     */
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.1;
      audio.play();
    }
  }

  updateInitiative() {
    this.$apollo
      .mutate({
        mutation: UpdateInitiativeMutation,
        variables: {
          id: this.$props.initiative.id,
          status: this.status
        },
      })
      .then(_ => {
        //
      });
  }

  openInitiativeDetailed() {
    this.$buefy.modal.open({
      component: InitiativeDetailed,
      // @ts-ignore
      customClass: 'initiative-detailed-modal',
      props: {
        initiative: this.$props.initiative,
      },
      width: '600px',
      parent: this,
    });
  }

  mounted() {
    this.status = this.$props.initiative.status;
    this.priority = this.$props.initiative.priority;
  }

}
