
























































import gql from 'graphql-tag';
import { Component, Vue, Prop } from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import { BoardQuery } from '@/graphql/board';
import { Board } from '@/typescript/types';
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import Roles from "@/roles";

@Component({
  components: {ConfirmDelete, UserAvatar },
})
export default class ListedBoardUsers extends Vue {
  requestDeleteActive: boolean = false;

  @Prop({ required: true })
  board!: Board;

  get isSingleMember() {
    if(this.$props.board.members.length == 1) {
      return true;
    }
    return false;
  }

  get boardMembers() {
    return this.$props.board.members.filter((member: any)=>member.user.deleted_at===null);
  }

  openDeleteModal(name: string, id: string) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: "Are you sure you want to delete " + name + "?"
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if(value === true) {
            this.removeUser(id);
          }
        }
      },
      width: '500px',
    });
  }

  roleName(role: string) {
    return Roles.formateRole(role);
  }

  setRole(user_id: string, role: string) {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation SetBoardRole($board_id: ID!, $user_id: ID!, $role: Role!) {
                        setBoardRole(board_id: $board_id, user_id: $user_id, role: $role) {
                            role
                        }
                    }
                `,
        variables: {
          user_id: user_id,
          board_id: this.board.id,
          role: role,
        },
        refetchQueries: [{ query: BoardQuery, variables: { id: this.board.id } }],
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'User role updated!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }

  removeUser(user_id: string) {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation removeUserFromBoard($user_id: ID!, $board_id: ID!) {
                        removeUserFromBoard(user_id: $user_id, board_id: $board_id) {
                            user {
                                id
                                name
                            }
                            board {
                                id
                                pid
                                name
                            }
                            role
                        }
                    }
                `,
        variables: {
          user_id: user_id,
          board_id: this.board.id,
        },
        refetchQueries: [{ query: BoardQuery, variables: { id: this.$store.state.board.id } }],
      })
      .catch((_error) => {
        
      })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'User removed!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }
}
