








import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {format} from "date-fns";
import {User} from "../../typescript/types";
@Component({
  components: {}
})
export default class UserStatusText extends Vue {
  @Prop({ default: undefined, required: true })
  user!: User;

  @Prop({ required: false, default: true })
  showTime!: boolean;

  getTime(end: Date, tenure: string){
    let localData = this.$options.filters?.getLocalDateTime(end);
    if(tenure === '1 Hour' || tenure === '4 Hours' || tenure === '30 Minutes'){
      return ' | Until '+format(new Date(localData), 'h:mm a');
    }else if(tenure === 'This week'){
      return ' | This week'
    }else if(tenure === 'Today'){
      return ' | All day';
    }
    return '';
  }
}
