import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import store from './store';

class PusherService {
  public channel: PusherTypes.Channel | null = null;
  // @ts-ignore Todo: check type..
  pusher: PusherTypes.default;

  constructor() {
    this.pusherAuth();

  }

  pusherAuth() {
    /**
     * Creating pusher broadcast auth after login
     */
    this.pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_KEY ?? 'FAILED - you need to set VUE_APP_PUSHER_APP_KEY', {
      cluster: 'eu',
      authEndpoint: process.env.VUE_APP_BACKEND_HOST + `/broadcasting/auth`,

      auth: {
        headers: {
          authorization: `Bearer ${store.state.token}`, //ToDo: This need to be changed on token refresh
        },
        params: {}
      },
    });

    /**
     * Checking state of pusher
     */
    this.pusher.connection.bind('state_change', function (states: { current: string; }) {

      console.log("Channels current state is " + states.current);
    });
  }

  subscribe(name: string, pid: string) {
    this.channel = this.pusher.subscribe('presence-' + name + '-' + pid);

    if (!this.channel) {
      return;
    }

    this.bindSubscriptionError(function (_err: any) {

    });
  }

  unsubscribe(name: string, pid: string) {
    this.pusher.unsubscribe('presence-' + name + '-' + pid);
  }

  bindSubscription(pusherKey: string, callback: Function) {
    if (!this.channel) {
      return;
    }
    this.channel.bind(pusherKey, callback);
  }

  bindSubscriptionError(callback: Function) {
    this.bindSubscription('pusher:subscription_error', callback);
  }

  bindSubscriptionSucceded(callback: Function) {
    this.bindSubscription('pusher:subscription_succeeded', callback);
  }

  bindAddedMember(callback: Function) {
    this.bindSubscription('pusher:member_added', callback);
  }

  bindRemoveMember(callback: Function) {
    this.bindSubscription('pusher:member_removed', callback);
  }

  unsubscribeWorkspaceSubscription(pid: string): void {
    this.unsubscribe('workspace', pid);
  }

  filterDuplicates(onlineIds: any, data: any) {
    return onlineIds.filter((objective: any) => {
      return objective.pid == data.pid && objective.memberId !== data.memberId;
    })
  }

  workspaceSubscription(pid: string): void {
    this.unsubscribeWorkspaceSubscription(pid);
    this.subscribe('workspace', pid);
    this.bindSubscriptionSucceded(function (members: any) {
      members.each((member: any) => {
        store.commit('add_online_id', member.id)
      });
    });
    this.bindAddedMember(function (member: any) {
      store.commit('add_online_id', member.id);
    });
    this.bindRemoveMember(function (member: any) {
      store.commit('remove_online_id', member.id);
    })
  }

  isViewingObjective(id: string, objectivePid: string): boolean {
    return !!store.state.objective_view_user_ids.find((objective: any) => {
      return objective.memberId == id && objective.pid == objectivePid;
    });
  }

  unsubscribeObjectiveSubscription(pid: string): void {
    this.unsubscribe('objective', pid);
  }

  getMember(pid: string, member: any) {
    return {
      pid: pid,
      memberId: member.id
    };
  }

  objectiveSubscription(pid: string): void {
    this.unsubscribeObjectiveSubscription(pid);
    this.subscribe('objective', pid);
    this.bindSubscriptionSucceded((members: any) => {
      members.each((member: any) => {
        store.commit('add_objective_view_user_id', this.getMember(pid, member))
      });
    });
    this.bindAddedMember((member: any) => {
      store.commit('add_objective_view_user_id', this.getMember(pid, member));
    });
    this.bindRemoveMember((member: any) => {
      store.commit('remove_objective_view_user_id', this.getMember(pid, member));
    })
  }

  unsubscribeBoardSubscription(pid: string): void {
    this.unsubscribe('board', pid);
  }

  boardSubscription(pid: string): void {
    this.unsubscribeBoardSubscription(pid)
    this.subscribe('board', pid);
    this.bindSubscriptionSucceded((members: any) => {

      members.each((member: any) => {
        store.commit('add_board_view_user_id', this.getMember(pid, member))
      });
    });
    this.bindAddedMember((member: any) => {
      store.commit('add_board_view_user_id', this.getMember(pid, member));
      store.commit('set_member_added_to_board', {pid: pid, message: member.info.name+ ' just entered the room'});
    });
    this.bindRemoveMember((member: any) => {
      store.commit('remove_board_view_user_id', this.getMember(pid, member));
      store.commit('set_member_removed_from_board', {pid: pid, message: member.info.name+ ' just left the room'});
    })
  }

  isViewingBoard(memberId: string, boardPid: string): boolean {
    return !!store.state.board_view_user_ids.find((board: any) => {
      return board.memberId == memberId && board.pid == boardPid;
    });
  }


}

export default new PusherService();
