




















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Objective} from "@/typescript/types";
import Encryption from "@/encryption";
import {
  startOfTomorrow,
  addHours,
  nextMonday,
  startOfToday,
  addMonths,
  startOfMonth, subMinutes
} from "date-fns";
import ObjectiveCreateReminderModal from "@/components/board/objective/ObjectiveCreateReminderModal.vue";
import {isNull} from "lodash";
import SmartDateTime from "@/components/general/SmartDateTime.vue";


@Component({
  components: {},
})
export default class ObjectiveReminder extends Vue {
  decryptedObjectiveName: string | null = null;
  currentlyClosing: boolean = false;

  @Prop({required: true})
  objective!: Objective;

  reminderSuggestion: Array<{name: string, time: any}> = [
    {name: "Tomorrow (9 AM)", time: this.setTime('Tomorrow (9 AM)', new Date())},
    {name: "Next week (Mon,9 AM)", time: this.setTime('Next week (Mon,9 AM)', new Date())},
    {name: "Next month", time: this.setTime('Next month', new Date())},
    {name: "Set custom reminder", time: new Date()}
  ]


  createReminder(index: any) {
    let reminder = this.reminderSuggestion[index].time;
    this.$buefy.modal.open({
      component: ObjectiveCreateReminderModal,
      width: '480px',
      parent: this,
      props: {
        'date_time': reminder,
        'remindable_id': this.objective.id,
        'remindable_type': 'objective',
        'objective_title': this.decryptedObjectiveName,
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'reminder-created': value => {
          if (value) {
            this.close();
            this.$buefy.toast.open({
              message: value,
              position: 'is-bottom-right',
              type: 'is-black',
            });
          }
        }
      },
    });
  }

  openDatePicker(date: any) {
    if(date == null) {
      date = new Date();
    }
    //@ts-ignore
    if(this.objective?.recurrences?.length <= 0){
      this.$buefy.modal.open({
        component: SmartDateTime,
        width: '280px',
        // @ts-ignore
        customClass: 'is-paddingless',
        parent: this,
        props: { 'remove-due-date': false, 'set-date': date, 'min-date': (this.objective.start_date) ? new Date(this.objective.start_date) : null},
        events: {
          //@ts-ignore ToDo: fix type issue
          'date': value => {
            if (value) {
              this.reminderSuggestion[this.reminderSuggestion.length -1].time = value;
              this.createReminder(this.reminderSuggestion.length -1);
            }
          }
        },
      });
    }

  }

  close() {
    this.currentlyClosing = true;
    setTimeout(() => {
      this.$emit('close', true);
      this.currentlyClosing = false;
    }, 500);
  }

  setTime(data: any, dateTime: Date){

    let date = null;
    switch(data)
    {
      case '5 minutes before start':
        date = subMinutes(new Date(dateTime), 5);
        break;
      case '15 minutes before start':
        date = subMinutes(new Date(dateTime), 15);
        break;
      case '30 minutes before start':
        date = subMinutes(new Date(dateTime), 30);
        break;
      case '5 minutes before end':
        date = subMinutes(new Date(dateTime), 5);
        break;
      case '15 minutes before end':
        date = subMinutes(new Date(dateTime), 15);
        break;
      case '30 minutes before end':
        date = subMinutes(new Date(dateTime), 30);
        break;
      case 'Tomorrow (9 AM)':
        date = addHours(startOfTomorrow(), 9);
        break;
      case 'Next week (Mon,9 AM)':
        date = addHours(nextMonday(startOfToday()), 9);
        break;
      case 'Next month':
        date = addHours(startOfMonth(addMonths(startOfToday(), 1)), 9);
        break;
    }

    return date;



  }

  createReminderSugesstion(){
    if(this.objective?.start_date && this.objective?.due_date) {
      let remArray = [
        {name: "5 minutes before start", time: this.setTime('5 minutes before start', this.objective.start_date)},
        {name: "15 minutes before start", time: this.setTime('15 minutes before start', this.objective.start_date)},
        {name: "30 minutes before start", time: this.setTime('30 minutes before start', this.objective.start_date)},
        {name: "5 minutes before end", time: this.setTime('5 minutes before end', this.objective.due_date)},
        {name: "15 minutes before end", time: this.setTime('15 minutes before end', this.objective.due_date)},
        {name: "30 minutes before end", time: this.setTime('30 minutes before end', this.objective.due_date)},
      ];

      this.reminderSuggestion = [...remArray, ...this.reminderSuggestion];
    }else if(this.objective?.start_date && isNull(this.objective.due_date)) {
      let remArray = [
        {name: "5 minutes before start", time: this.setTime('5 minutes before start', this.objective.start_date)},
        {name: "15 minutes before start", time: this.setTime('15 minutes before start', this.objective.start_date)},
        {name: "30 minutes before start", time: this.setTime('30 minutes before start', this.objective.start_date)},
      ];

      this.reminderSuggestion = [...remArray, ...this.reminderSuggestion];
    }else if(this.objective?.due_date && isNull(this.objective.start_date)) {
      let remArray = [
        {name: "5 minutes before end", time: this.setTime('5 minutes before end', this.objective.due_date)},
        {name: "15 minutes before end", time: this.setTime('15 minutes before end', this.objective.due_date)},
        {name: "30 minutes before end", time: this.setTime('30 minutes before end', this.objective.due_date)},
      ];

      this.reminderSuggestion = [...remArray, ...this.reminderSuggestion];
    }
  }

  mounted() {
    this.decryptedObjectiveName = Encryption.decrypt(this.objective?.name);
    this.createReminderSugesstion();

  }
}
