







































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CreateReminderMutation} from "@/graphql/auth";
import {User} from "@/typescript/types";
import SmartDateTime from "@/components/general/SmartDateTime.vue";

@Component({
  components: {},
})
export default class CreateReminder extends Vue {
  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  @Prop({required: false, default: false})
  showMemberLists!: boolean

  @Prop({required: false, default: false})
  isMasterDashboard!: boolean

  @Prop({required: false, default: null})
  remindDateTime!: Date

  @Prop({default: null, required: false})
  user!: User;

  @Prop({default: null, required: false})
  weekDates!: Array<{weekDayName: string, weekDay: Date}>

  successLoading: boolean = false;
  title: string | null = null;
  description: string | null = null;
  reminderUser: string | null = null;
  reminderTime: string | null = null;
  reminderDateTime: Date | null = null;
  selectedReminderUser: any | null = null;
  remindableId: string | null = null;
  selectedUsers: Array<string> = [];
  selectedDay: number = 0;
  selectedTime: number = 6;


  dayTimings: Array<{title: string, hours: number, minutes: number, seconds: number}> = [
    {
      'title' : '6:00 AM',
      'hours' : 6,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '6:30 AM',
      'hours' : 6,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '7:00 AM',
      'hours' : 7,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '7:30 AM',
      'hours' : 7,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '8:00 AM',
      'hours' : 8,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '8:30 AM',
      'hours' : 8,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '9:00 AM',
      'hours' : 9,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '9:30 AM',
      'hours' : 9,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '10:00 AM',
      'hours' : 10,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '10:30 AM',
      'hours' : 10,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '11:00 AM',
      'hours' : 11,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '11:30 AM',
      'hours' : 11,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '12:00 PM',
      'hours' : 12,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '12:30 PM',
      'hours' : 12,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '13:00 PM',
      'hours' : 13,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '13:30 PM',
      'hours' : 13,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '14:00 PM',
      'hours' : 14,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '14:30 PM',
      'hours' : 14,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '15:00 PM',
      'hours' : 15,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '15:30 PM',
      'hours' : 15,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '16:00 PM',
      'hours' : 16,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '16:30 PM',
      'hours' : 16,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '17:00 PM',
      'hours' : 17,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '17:30 PM',
      'hours' : 17,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '18:00 PM',
      'hours' : 18,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '18:30 PM',
      'hours' : 18,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '19:00 PM',
      'hours' : 19,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '19:30 PM',
      'hours' : 19,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '20:00 PM',
      'hours' : 20,
      'minutes' : 0,
      'seconds' : 0
    },
    {
      'title' : '20:30 PM',
      'hours' : 20,
      'minutes' : 30,
      'seconds' : 0
    },
    {
      'title' : '21:00 PM',
      'hours' : 21,
      'minutes' : 0,
      'seconds' : 0
    }
  ]



  get createReminderInput() {
    if(this.title && this.selectedReminderUser && this.reminderDateTime) {
      return true;
    }
    return false;
  }

  get allMemebersOfWorkspace() {
    let members = this.workspace.members.filter((member: any) => {
      if(member.user.id != this.me.id){
        return member.user;
      }

    })

    return members.map((u: any) => u.user);
  }



  mounted() {
    if(!this.showMemberLists) {
      this.reminderUser = this.$props.user.email;
      this.selectedReminderUser = this.$props.user.id;
      this.selectedUsers.push(this.$props.user.id);
      this.remindableId = this.$props.user.id;
    }
    if(this.showMemberLists) {
      let newDate = new Date();
      let d = new Date(this.$props.remindDateTime);
      d.setHours(newDate.getHours(), newDate.getMinutes(), newDate.getSeconds());

      this.reminderDateTime = d;
    }

    if(this.isMasterDashboard){
      this.setReminderDateTime();
    }

  }

  setReminderDateTime()
  {
    let d = new Date(this.weekDates[this.selectedDay].weekDay);
    d.setHours(this.dayTimings[this.selectedTime].hours);
    d.setMinutes(this.dayTimings[this.selectedTime].minutes);
    d.setSeconds(this.dayTimings[this.selectedTime].seconds);

    this.reminderDateTime = d;
  }

  openCalender(setDate: Date) {
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless top-right',
      parent: this,
      props:{minDate: setDate, setDate: setDate, maxDate: setDate, removeOtherButton: true},
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
         
          //if(value) {
          //@ts-ignore
          this.reminderTime = this.$options.filters.moment(value, 'MMM d, yyyy HH:mm a' );
          this.reminderDateTime = value;
          //}
        }
      },
    });
  }

  openDateTime() {
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
         
          //if(value) {
          //@ts-ignore
          this.reminderTime = this.$options.filters.moment(value, 'MMM d, yyyy HH:mm a' );
          this.reminderDateTime = value;
          //}
        }
      },
    });
  }

  createReminder() {
    this.successLoading = true;

    if(this.selectedReminderUser){
      this.remindableId = this.selectedReminderUser
      if(this.showMemberLists) {
        this.selectedUsers.push(this.selectedReminderUser);
      }

    }

    this.$apollo
      .mutate({
        mutation: CreateReminderMutation,
        variables: {
          name: this.title,
          description: this.description,
          date_time: this.reminderDateTime,
          remindable_type: 'user',
          remindable_id: this.remindableId,
          users: this.selectedUsers

        },
      })
      .then((res)=>{
        this.successLoading = false;
        if(res) {
          //@ts-ignore
          this.$parent.close();
          this.$buefy.toast.open({
            message: 'Reminder added',
            position: 'is-bottom-right',
            type: 'is-black',
          });
          this.$events.fire('user_reminder_created')
        }
      })
    ;
  }
}
