































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CreateObjectiveRecurrenceMutation, DeleteObjectiveRecurrenceMutation} from "@/graphql/objectiveRecurrence";
import {ObjectiveRecurrence} from "@/typescript/types";
import {ObjectiveQuery} from "@/graphql/objective";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";


@Component({
  components: {},
})
export default class ObjectiveRecurrences extends Vue {
  /**
   * interval setting props: daily/weekly/monthly
   */
  interval: string = 'daily';
  /**
   * day of week/month: 1-7 for week, 1-31 for month
   */
  day: any | null = null;
  /**
   * A time string in H:i:s format, e.g. 15:13:37
   */
  // @ts-ignore
  time = '09:00:00';

  /**
   * Exception when setting daily recurrence, day of week (1-7) - ex: repeat every day of the week except sat(6) & sun(7)
   */
  exceptions: Array<number> = [6, 7];

  showHelp: boolean | false = false;

  startDate: Date | null = null;
  endDate: Date | null = null;

  weekDays: Array<{ number: any, name: string }> = [
    {number: 1, name: 'Monday'},
    {number: 2, name: 'Tuesday'},
    {number: 3, name: 'Wednesday'},
    {number: 4, name: 'Thursday'},
    {number: 5, name: 'Friday'},
    {number: 6, name: 'Saturday'},
    {number: 7, name: 'Sunday'},
  ];

  // @ts-ignore
  hours: Array<{ time: any, name: string }> = [
    {time: '09:00:00', name: '9:00 AM'}, {time: '09:30:00', name: '9:30 AM'},
    {time: '10:00:00', name: '10:00 AM'}, {time: '10:30:00', name: '10:30 AM'},
    {time: '11:00:00', name: '11:00 AM'}, {time: '11:30:00', name: '11:30 AM'},
    {time: '12:00:00', name: '12:00 PM'}, {time: '12:30:00', name: '12:30 PM'},
    {time: '13:00:00', name: '1:00 PM'}, {time: '13:30:00', name: '1:30 PM'},
    {time: '14:00:00', name: '2:00 PM'}, {time: '14:30:00', name: '2:30 PM'},
    {time: '15:00:00', name: '3:00 PM'}, {time: '15:30:00', name: '3:30 PM'},
    {time: '16:00:00', name: '4:00 PM'}, {time: '16:30:00', name: '4:30 PM'},
    {time: '17:00:00', name: '5:00 PM'}, {time: '17:30:00', name: '5:30 PM'},
    {time: '18:00:00', name: '6:00 PM'}, {time: '18:30:00', name: '6:30 PM'},
    {time: '19:00:00', name: '7:00 PM'}, {time: '19:30:00', name: '7:30 PM'},
    {time: '20:00:00', name: '8:00 PM'}, {time: '20:30:00', name: '8:30 PM'},
    {time: '21:00:00', name: '9:00 PM'}, {time: '21:30:00', name: '9:30 PM'},
    {time: '22:00:00', name: '10:00 PM'}, {time: '22:30:00', name: '10:30 PM'},
    {time: '23:00:00', name: '11:00 PM'}, {time: '23:30:00', name: '11:30 PM'},
  ];


  @Prop({default: undefined, required: true})
  objectiveId!: string;

  @Prop({default: undefined, required: false})
  recurrences!: ObjectiveRecurrence;

  ordinal_suffix_of(i: number) {
    let j = i % 10, k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  get board() {
    return this.$store.state.board;
  }

  getDayName(number: any) {
    if(number === 1) { return 'Monday'; }
    else if(number === 2) { return 'Tuesday'; }
    else if(number === 3) { return 'Wednesday'; }
    else if(number === 4) { return 'Thursday'; }
    else if(number === 5) { return 'Friday'; }
    else if(number === 6) { return 'Saturday'; }
    else if(number === 7) { return 'Sunday'; }
    else {
      return null;
    }
  }

  intervalChanged() {
    if(this.interval === 'weekly' || this.interval === 'monthly') {
      this.day = 1;
    }

  }

  checkInputs() {
    let _errorMessage = '';

    if(this.interval === 'weekly' && !this.day) {
      _errorMessage = ("When selecting weekly recurrence you have to select a day also, for example Tuesday.");
    }
    if(this.interval === 'monthly' && !this.day) {
      _errorMessage = ("When selecting monthly recurrence you have to select a day also, for example 23");
    }
    if(this.interval === 'daily' || this.interval !== 'daily' && this.day) {
      this.setRecurrence();
    }
  
  }

  setRecurrence() {
    if(this.interval !== 'daily') {
      this.exceptions = [];
    }

    let d = new Date();

    let utcDate = new Date(d.getFullYear()+'-'+(d.getMonth()+1)+'-'+d.getDay()+' '+this.time );
    let calculateTime = new Date(utcDate).toLocaleTimeString('en',
      { hour12: false, timeZone: 'UTC' });
    this.$apollo
      .mutate({
        mutation: CreateObjectiveRecurrenceMutation,
        variables: {
          interval: this.interval,
          day: this.day,
          time: calculateTime,
          start_date: this.startDate,
          exception: this.exceptions,
          end_date: this.endDate,
          objective_id: this.objectiveId
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.objectiveId } }],
      })
      .then((res) => {
       
        this.$events.fire('set-objective-recurrence', {start_date: res.data.createObjectiveRecurrence.objective.start_date, objective_id: this.objectiveId })
        this.$buefy.toast.open({
          message: 'Recurrence set!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }

  confirmDeleteRecurrence(recurrence_id: string) {
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete this recurrence?',
        closeParentFirst: true
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.deleteRecurrence(recurrence_id);
          }
        }
      },
      width: '500px',
    });
  }

  deleteRecurrence(recurrence_id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteObjectiveRecurrenceMutation,
        variables: {
          id: recurrence_id,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.objectiveId } }],
      })
      .then(() => {
        this.$events.fire('delete-objective-recurrence', {objective_id: this.objectiveId })
        this.$buefy.toast.open({
          message: 'Deleted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }

  mounted() {
    // @ts-ignore
    this.startDate = new Date().toISOString().slice(0, 10);
  }
}
