











































import {Component, Vue} from 'vue-property-decorator';
import {CreatedReminders, DeleteReminderMutation} from "@/graphql/auth";

@Component({
  apollo: {
    createdReminders: {
      query: CreatedReminders,
    }
  },
  components: {},
})
export default class UserReminders extends Vue {
  newReminderId: string | null = null;
  scrollIntoView: boolean = false;

  mounted() {
    if(this.$store.state.new_reminder_id) {
      location.href = "#" + this.$store.state.new_reminder_id;
      this.scrollIntoView = true;
      setTimeout(() => {
        this.scrollIntoView = false;
        this.$store.commit('set_new_reminder_id', null);
      }, 3000);
    }
  }

  deleteReminder(reminderId: string) {
    this.$apollo.mutate({
      mutation: DeleteReminderMutation,
      variables: {
        id: reminderId,
      },
      refetchQueries: [{ query: CreatedReminders }],
    }).then(_ => {
      this.$buefy.toast.open({
        message: 'Deleted!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    });
  }

}
