


















































































































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import BoardsSelector from '@/components/formfields/BoardsSelector.vue';
import {Board} from '@/typescript/types';
import {CreateWorkspaceInviteMutation} from '@/graphql/workspace_invite';
import {WorkspaceQuery} from '@/graphql/workspace';
import VerifyUser from "@/components/auth/VerifyUser.vue";

@Component({
  components: {BoardsSelector},
})
export default class CreateWorkspaceInviteModal extends Vue {
  emails: Array<string> = new Array<string>();
  selectedRole: string | null = 'member';
  showHelp = false;
  submitLoading = false;
  submitError = false;
  readMore: boolean = false;
  noSelectedBoardWarning: boolean = false;
  emailError: boolean = false;
  selectedBoards: Array<Board> = [];

  @Prop({required: true})
  workspacePid!: string;

  @Prop({required: true})
  workspaceId!: string;

  get workspace() {
    return this.$store.state.workspace;
  }

  get boardIds() {
    return this.selectedBoards.map(board => board.id);
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get variables() {
    return {
      workspace: this.workspaceId,
      role: this.selectedRole,
      boards: this.boardIds,
    };
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  get haveEmails() {
    
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return this.emails.length >= 1 && this.emails.every(email => emailRegex.test(email));
  }

  validateEmails() {
    
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    
    this.emailError = this.emails.length > 0 && !this.emails.every(email => emailRegex.test(email));
  }
 
  get haveBoards() {
    return (this.selectedBoards.length >= 1) ? true : false;
  }

  get canSendInvitations() {
    return this.haveEmails && this.haveBoards;
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_create_invite');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }


  create() {
    this.$gtag.event('pmb_app_workspace_invite_created');
    if (!this.haveEmails) {
      this.$buefy.toast.open({
        message: 'Valid email needed',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      return;
    }
    if (!this.haveBoards) {
      this.noSelectedBoardWarning = true;
      return;
    }
    this.submitLoading = true;
    for (let i = 0; i < this.emails.length; i++) {
      this.$apollo
        .mutate({
          mutation: CreateWorkspaceInviteMutation,
          variables: {
            invitee_email: this.emails[i],
            ...this.variables,
          },
          refetchQueries: [
            {query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}},
          ],
        })
        .catch(error => {
          console.error(error);
          this.submitError = true;
          setTimeout(() => {
            this.submitError = false;
          }, 1200);
        })
        .then(_ => {
          this.$router.push({name: 'members-manager'})
          //@ts-ignore
          this.$parent.close();
        })
        .finally(() => {
          this.submitLoading = false;
          this.$buefy.toast.open({
            message: 'Invite sent!',
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$gtag.event('pembio_invite_workspace');
        });
    }
  }

  mounted() {
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainInput.focus();
    });
  }
}
