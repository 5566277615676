








import { Component, Vue } from 'vue-property-decorator';
import WorkspaceSettingsIndex from "@/components/settings/workspace/WorkspaceSettingsIndex.vue";


@Component({
  components: {
  },
})
export default class MasterLayout extends Vue {

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  openWorkspaceSettings() {
    if(this.isAdminOfWorkspace) {
      this.$buefy.modal.open({
        component: WorkspaceSettingsIndex,
        width: '480px',
        // @ts-ignore
        customClass: 'is-paddingless',
        parent: this,
      });
    }
  }

}
