


















































































import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import {objective_types, ObjectiveDetailedFragment, ObjectivesQuery} from "@/graphql/objective";

@Component({
  components: {},
  apollo: {
    objective_types: {
      query: objective_types,
    },
  },
  props: {
    objectiveId: {
      required: true
    },
    objectiveType: {
      required: false,
    },
  },
})
export default class ObjectiveTypeSelector extends Vue {
  isActive = false;
  currentObjectiveType: string | null = null;
  currentlyClosing: boolean = false;

  setNewObjectiveType(objectiveTypeId: string) {
    this.currentObjectiveType = objectiveTypeId;
    this.updateObjective(objectiveTypeId);
  }

  updateObjective(objectiveTypeId: string) {
    this.isActive = false;
    this.$apollo.mutate({
      mutation: gql`
                mutation updateObjective($id: ID!, $objective_type: ID!) {
                    updateObjective(id: $id, input: { objective_type: { connect: $objective_type } }) {
                        ...ObjectiveDetailed
                    }
                }
                ${ObjectiveDetailedFragment}
            `,
      variables: {
        id: this.$props.objectiveId,
        objective_type: objectiveTypeId,
      },
      refetchQueries:[{query: ObjectivesQuery, variables:{board_id: this.$store.state.board.id}}]
    }).finally(() => {
      this.close();
      this.$buefy.toast.open({
        message: 'Changes saved!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    });
  }

  close() {
    this.currentlyClosing = true;
    setTimeout(() => {
      this.$emit('close', true);
      this.currentlyClosing = false;
    }, 500);
  }


  mounted() {
    this.currentObjectiveType = this.$props.objectiveTypeId;
  }
}
