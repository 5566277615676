
















import {Component, Prop, Vue} from 'vue-property-decorator';
import AddTags from "@/components/tags/AddTags.vue";

@Component({
  components: {},
})

export default class ObjectiveTags extends Vue {
  @Prop({default: undefined, required: true})
  objectiveId!: string;

  @Prop({default: undefined, required: true})
  boardTags!: Array<any>;

  @Prop({default: undefined, required: true})
  objectiveTags!: Array<any>;

  @Prop({default: undefined, required: true})
  boardId!: string;

  openCreateTags() {
    this.$buefy.modal.open({
      component: AddTags,
      props: {
        board_id: this.$props.boardId,
        objective_id: this.$props.objectiveId,
        board_tags: this.$props.boardTags,
        objective_tags: this.$props.objectiveTags
      },
      width: '520px',
      parent: this,
    });
  }
}
