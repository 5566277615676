export const ipAddress = {
  async getIpAddress() {
    const localIpData = await fetch('https://api.ipify.org/?format=json');
    const data = await localIpData.json();
    return data.ip;
  }
}

export const timeZoneLocalTime = {
  getTimeZoneLocalTime(date: Date, timezone: string) {
    return date.toLocaleTimeString('en-US', {timeZone: timezone, hour: '2-digit', minute:'2-digit'})
  }
}

export const userAgent = {
  getUserAgent() {
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = ''+parseFloat(nVer);
    let majorVersion = parseInt(nVer,10);
    let nameOffset,verOffset,ix;

    // In Opera, the true version is after "Opera" or after "Version"
    if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset+6);
      if ((verOffset=nAgt.indexOf("Version"))!=-1)
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset+5);
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset+7);
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset+7);
      if ((verOffset=nAgt.indexOf("Version"))!=-1)
        fullVersion = nAgt.substring(verOffset+8);
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset+8);
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) <
      (verOffset=nAgt.lastIndexOf('/')) )
    {
      browserName = nAgt.substring(nameOffset,verOffset);
      fullVersion = nAgt.substring(verOffset+1);
      if (browserName.toLowerCase()==browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix=fullVersion.indexOf(";"))!=-1)
      fullVersion=fullVersion.substring(0,ix);
    if ((ix=fullVersion.indexOf(" "))!=-1)
      fullVersion=fullVersion.substring(0,ix);

    majorVersion = parseInt(''+fullVersion,10);
    if (isNaN(majorVersion)) {
      fullVersion  = ''+parseFloat(navigator.appVersion);
      majorVersion = parseInt(navigator.appVersion,10);
    }

    // get device
    //@ts-ignore
    let device = "Unknown device";
    if(nAgt.match(/(iPhone)/)){
      device = 'iPhone';
    }else if(nAgt.match(/(iPad)/)){
      device = 'iPad';
    }else if(nAgt.match(/(iPod)/)){
      device = 'iPod';
    }else if(nAgt.match(/(BlackBerry|BB10)/)){
      device = 'BlackBerry';
    }else if(nAgt.match(/(IEMobile)/)){
      device = 'WindowsMobile';
    }else if(nAgt.match(/(Android)/)){
      device = 'Android';
    }else if(nAgt.match(/(Macintosh)/)){
      device = 'Macintosh';
    }else if(nAgt.match(/(Windows)/)){
      device = 'Windows';
    }else if(nAgt.match(/(Linux)/)){
      device = 'Linux';
    }




    return {
      'browserName': browserName,
      'fullVersion': fullVersion,
      'navigator-appname': navigator.appName,
      'userAgent': navigator.userAgent,
      'platform': navigator.platform,
      'vendor': navigator.vendor,
      'device': device
    };


  }
}
