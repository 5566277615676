import gql from "graphql-tag";


export const BoardBackgroundDetailedFragment = gql`
    fragment BoardBackgroundDetailed on BoardBackground {
        id
        name
        url
    }
`;

export const BoardBackgroundImageQuery = gql`
    query BoardBackgroundImage {
        getBackgrounds {
            ...BoardBackgroundDetailed
        }
    }
    ${BoardBackgroundDetailedFragment}
`;
