import { ApolloClient } from 'apollo-client';
import {createUploadLink} from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import { ApolloLink } from 'apollo-link';
import store from './store';

import introspectionQueryResultData from './typescript/fragmentTypes.json';
import PusherLink from '@/pusher_link';
import Pusher from 'pusher-js';
import router from "@/router";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });


const httpLink = createUploadLink({
  uri: process.env.VUE_APP_API_ENDPOINT,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
})

const authLink = new ApolloLink((operation, forward) => {
  if (store.state.token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${store.state.token}`,
      },
    });
  }

  /**
  * Call the next link in the middleware chain.
  */
  if (forward) return forward(operation);
  else return null;
});

const error = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      if (message.toLowerCase() === 'unauthenticated.') {
        store.commit('logged_out')
        cache.reset()
        router.push({name: 'login'}).then(() => {
          location.reload();
        })
      }
    });
  if (networkError) {
    console.log(networkError)
    // ToDo: How to recover from this?
  }
});

const putMeInVuex = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    if (operation.operationName === 'Me') {
      //store.commit('set_me', data.data!.me);
    } else if (operation.operationName === 'VerifyEmailWithPin') {
      //store.commit('set_me', data.data!.verifyEmailWithPin);
    } else if (operation.operationName === 'Board') {
      //store.commit('set_board', data.data!.board);
    } else if (operation.operationName === 'Workspace') {
      //store.commit('set_workspace', data.data!.workspace);
    } else if (operation.operationName === 'Logout') {
      cache.reset();
    }
    return data;
  });
});
// TODO remove on prod

const pusherLink = new PusherLink({
  pusher: new Pusher(process.env.VUE_APP_PUSHER_APP_KEY ?? 'FAILED - you need to set VUE_APP_PUSHER_APP_KEY', {
    cluster: 'eu',
    authEndpoint: `${process.env.VUE_APP_API_ENDPOINT}/subscriptions/auth`,
    auth: {
      headers: {
        authorization: `Bearer ${store.state.token}`, //ToDo: This need to be changed on token refresh
      },
      params: {}
    },
  }),
});

// @ts-ignore
const link = ApolloLink.from([putMeInVuex, pusherLink, authLink, error, httpLink]);

/**
* Create Apollo client
*/
const apollo = new ApolloClient({
  link,
  cache,
});

export default new VueApollo({
  defaultClient: apollo,
});
