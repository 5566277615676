





































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import UserNotifications from "@/components/user/profile/UserNotifications.vue";
import UserStatus from "@/components/user/UserStatus.vue";
import Account from "@/components/settings/personal/Account.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import UserReminders from "@/components/user/profile/UserReminders.vue";
import FeedbackInput from "@/components/feedback/FeedbackInput.vue";
import AvatarModal from "@/components/settings/personal/AvatarModal.vue";
import UserStatusText from "@/components/user/UserStatusText.vue";
import Referral from "@/components/settings/personal/Referral.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";

@Component({
  components: {
    UserStatusText,
    FeedbackInput, UserReminders, 'Avatar': UserAvatar, Account, UserNotifications, AvatarModal, UserStatus, Referral, ConfirmDelete}
})
export default class UserProfile extends Vue {
  activeTab: string = 'notifications';
  muteUnmuteNotificationSound: boolean = this.$store.state.mute_notification_sound;

  @Prop({required: false, default: false})
  redirect!: string | null;

  openAvatarModal() {
    this.$buefy.modal.open({
      component: AvatarModal,
      width: '300px',
      parent: this,
      // @ts-ignore
      animation: 'none',
    });
  }

  get hasUnreadReminders() {
    if(this.$store.state.new_reminder_id) {
      return true;
    } else {
      return false;
    }
  }

  get me() {
    return this.$store.state.me;
  }

  logout() {
    this.$gtag.event('pmb_user_logout');
    this.$router.push({ name: 'logout' });
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  closeFromChild() {
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  openStatusModal() {
    //@ts-ignore
    this.$parent.close();
    this.$buefy.modal.open({
      component: UserStatus,
      // @ts-ignore
      customClass: 'is-paddingless',
      width: '520px',
      parent: this,
    });
  }

  setMuteUnmuteNotification() {
    this.muteUnmuteNotificationSound = !this.muteUnmuteNotificationSound;
    Vue.prototype.$muteUnmuteNotificationSound();
  }

  mounted() {
    this.$events.listen('close-notification-modal', (_) => {
      //@ts-ignore ToDo: fix type issue
      this.$parent.close();
    })
    if(this.redirect) {
      this.activeTab = this.redirect;
    }
  }


}
