import gql from "graphql-tag";

export const ThirdPartyProviderDetailedFragment = gql `
  fragment ThirdPartyProviderDetailed on ThirdPartyProvider {
      id
      name
      provider_url
  }
`;

export const ThirdPartyQuery = gql `
    query GetThirdPartyProviders{
        getThirdPartyProviders{
            ...ThirdPartyProviderDetailed
        }
    }
    ${ThirdPartyProviderDetailedFragment}
`;

export const ThirdPartyAuth = gql `
  mutation ThirdPartyAuth($token: String!, $codeVarifier: String, $provider: String!){
      thirdPartyAuth(
          input:{
             token: $token
             code_verifier: $codeVarifier
             provider: $provider 
          }
      ){
          access_token
          expires_in
          token_type
      }
  }
`;