import gql from 'graphql-tag';
import {NorthStarFragment} from "@/graphql/NorthStar";
import {BookmarkDetailedFragment} from "@/graphql/bookmark";

export const BoardTemplateMetricsDetailedFragment = gql`
    fragment BoardTemplateMetricsDetailed on BoardTemplate {
        metrics {
            objectivesCount
            keyResultsCount
            keyResultsCompletedCount
            keyResultsOverallProgress
        }
    }
`;

export const BoardSprintDetailedFragment = gql`
    fragment BoardSprintDetailed on Sprint {
        id
        title
        description
        created_at
        interval
        status
        start_at
        end_at
        sprintReflection {
            id
            reflection {
                description
            }
        }
        metrics {
            objectivesCount
            keyResultsCount
            keyResultsCompletedCount
            keyResultsOverallProgress
        }
    }
`;

export const BoardTemplateDetailedFragment = gql`
    fragment BoardTemplateDetailed on BoardTemplate {
        id
        pid
        name
        description
        public
        permissions
        slackConnected
        boardLevel {
            identifier
        }
        sprint_interval
        active_sprint {
            ...BoardSprintDetailed
        }
        ...BoardTemplateMetricsDetailed
        workspace {
            id
            pid
            members {
                id
                user {
                    id
                    name
                    email
                    first_name
                    last_name
                    initials
                    avatar
                    deleted_at
                }
            }
        }
        members {
            id
            role
            user {
                id
                name
                email
                first_name
                last_name
                initials
                avatar
                deleted_at
            }
        }
        owner {
            id
            name
            initials
            avatar
            deleted_at
        }
        northStars {
            ...NorthStarDetailed
        }
        template
        templateDetails {
            template_type
            template_category {
                id
                name
                description
            }
            author_name
            author_description
            author_link
            author_role
            author_company
            cover
        }
        objectives {
            name
        }
    }
    ${BoardSprintDetailedFragment}
    ${BoardTemplateMetricsDetailedFragment}
    ${NorthStarFragment}
`;

export const PublicBoardTemplateDetailedFragment = gql`
    fragment PublicBoardTemplateDetailed on PublicBoardTemplate {
        id
        pid
        name
        description
        goal_method {
            id
            name
            description
        }
        public
        metrics {
            objectivesCount
            keyResultsCount
            keyResultsCompletedCount
            keyResultsOverallProgress
        }
        boardLevel {
            name
            identifier
        }
        permissions
        deleted_at
        template
        templateDetails {
            template_id
            template_type
            description
            short_description
            template_category {
                id
                name
                description
            }
            author_name
            author_description
            author_link
            author_role
            author_company
            cover
        }
        objectives {
            id
            name
            background_color
            objective_type {
                id
                name
                description
            }
            priority
            key_results {
                data {
                    id
                    description
                    start_value
                    target_value
                    unit
                    priority
                }
            }
        }
        northStars {
            id
            name
            description
            status
            due_date
            completed_at
            color
            is_public
        }
        attachments {
            id
            file_name
            file_type
            created_at
        }
        bookmarks {
            ...BookmarkDetailed
        }
    }
    ${BookmarkDetailedFragment}
`;


export const CreateSprintIfNotExistsMutation = gql`
    mutation CreateSprintIfNotExists($id: ID!, $date: DateTime) {
        CreateSprintIfNotExists(board_id: $id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const BoardTemplates = gql`
    query BoardTemplates {
        BoardTemplates: getBoardTemplates {
            ...BoardTemplateDetailed
        }
    }
    ${BoardTemplateDetailedFragment}
`;

export const PublicBoardTemplates = gql`
    query PublicBoardTemplates($first: Int, $page: Int) {
        PublicBoardTemplates: publicBoardTemplates(first: $first, page: $page) {
            data {
                id
                pid
                name
                description
                goal_method {
                    id
                    name
                    description
                }
                public
                templateDetails {
                    template_id
                    template_type
                    description
                    short_description
                    cover
                }
                objectives {
                    id
                    name
                    background_color
                }
                northStars {
                    id
                    name
                    description
                    color
                    objectives {
                        id
                        name
                        background_color
                    }
                }
                attachments {
                    id
                    file_name
                    file_type
                    created_at
                }
                bookmarks {
                    ...BookmarkDetailed
                }
            }            
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${BookmarkDetailedFragment}
    
`;


export const BoardTemplateQuery = gql`
    query BoardTemplate($id: ID!) {
        BoardTemplate: getBoardTemplate(id: $id) {
            ...BoardTemplateDetailed
        }
    }
    ${BoardTemplateDetailedFragment}
`;

export const PublicBoardTemplateQuery = gql`
    query publicBoardTemplate($id: ID!) {
        PublicBoardTemplate: publicBoardTemplate(id: $id) {
            ...PublicBoardTemplateDetailed
        }
    }
    ${PublicBoardTemplateDetailedFragment}
`;

export const PrivateBoardTemplateQuery = gql`
    query privateBoardTemplate($id: ID!) {
        PrivateBoardTemplate: privateBoardTemplate(id: $id) {
            ...PublicBoardTemplateDetailed
        }
    }
    ${PublicBoardTemplateDetailedFragment}
`;

export const SprintQuery = gql`
    query getSprint($board_id: ID!, $date: String!) {
        getSprint(board_id: $board_id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const SprintsQuery = gql`
    query getSprints($board_id: ID!, $date: String!) {
        getSprints(board_id: $board_id, date: $date) {
            data {
                id
                start_at
                interval
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
`;

export const CloseSprint = gql`
    mutation closeSprint($sprint_id: ID!) {
        closeSprint(sprint_id: $sprint_id) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;


export const CreateSprintIfNotExists = gql`
    mutation createSprintIfNotExists($board_id: ID!, $date: DateTime!) {
        createSprintIfNotExists(board_id: $board_id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;
