













































































import { Component, Vue} from 'vue-property-decorator';
import {CreateKeyResultMutation, ObjectiveQuery} from '@/graphql/objective';
import UserAvatar from '@/components/user/UserAvatar.vue';
import chrono from 'chrono-node';

@Component({
  components: { UserAvatar },

  props: {
    objectiveId: {
      required: false,
      default: null,
    },
  },
})
export default class CreateOrUpdateKeyResult extends Vue {
  keyResultInput: string | null = '';
  keyResultPriority: string | null = 'normal';
  assignedUserId: string | null = null;
  createKeyResultActive = false;
  isLoading: boolean = false;

  newKeyResult = false;

  manualMetricsActive = false;

  startValue: number | null = 0;
  targetValue: number | null = null;

  get me() {
    return this.$store.state.me;
  }

  get board() {
    return this.$store.state.board;
  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access 
  }

  get canAddSubtasks() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_subtasks_access) || this.hasFreemiumAccess
  }

  get firstDigits() {
    if (this.inputWithoutDates) {
      let numbers = this.inputWithoutDates.match(/(\d+)/g);
      if (numbers) {
        return numbers.map(x => parseInt(x));
      }
    }
    return [];
  }

  get datesFromInput() {
    if (this.keyResultInput) {
      return chrono.parse(this.keyResultInput);
    }
    return [];
  }

  get inputWithoutDates() {
    let net = this.keyResultInput;

    if (net) {
      // @ts-ignore
      this.datesFromInput.forEach(date => {
        // @ts-ignore
        net = net.replace(date.text, '');
      });
    }

    return net;
  }

  toggleCreate() {
    if(this.canAddSubtasks) {
      this.createKeyResultActive = !this.createKeyResultActive;
      if (this.createKeyResultActive === true) {
        this.$nextTick(function () {
        //@ts-ignore ToDo: fix type issue
          this.$refs.krInput.focus();
        });
      } else {
        this.manualMetricsActive = false;
        this.targetValue = null;
        this.startValue = 0;
        this.keyResultInput = '';
      }
    } else {
      this.$emit('open-paywall');

    }
  }

  setAssignee(id: string) {
    this.assignedUserId = id;
  }

  requestCreateKeyResult() {
    if (this.firstDigits === undefined || this.firstDigits.length == 0) {
      this.targetValue = 1;
    } else {
      this.targetValue = this.firstDigits[0];
    }
    this.createKeyResult();
  }

  createKeyResult() {
    if (!this.manualMetricsActive) {
      if (this.isLoading) {
        return
      }
      this.startValue = 0;
      this.targetValue = 1;
    }
    this.isLoading = true;
    this.$apollo
      .mutate({
        mutation: CreateKeyResultMutation,
        variables: {
          id: this.$props.objectiveId,
          description: this.keyResultInput,
          priority: this.keyResultPriority,
          assignee_id: this.assignedUserId,
          start_value: this.startValue,
          current_value: this.startValue,
          target_value: this.targetValue,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.objectiveId } }]
      })
      .then(data => {
        this.$emit('new-key-result', data.data.createKeyResult);
        this.keyResultInput = '';
        this.targetValue = null;
      })
      .finally(() => {
        this.isLoading = false

        this.$gtag.event('pembio_create_key_result');
      })
  }

  created() {
    this.assignedUserId = this.me.id;
  }
}
