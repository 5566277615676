













































import { Component, Vue, Prop } from 'vue-property-decorator';
import PusherService from "@/PusherService";
import UserProfileDetails from "@/components/user/UserProfileDetails.vue";
import UserProfile from "@/components/user/profile/UserProfile.vue";
import Template from "@/components/workspace/Template.vue";


@Component({
  components: {Template}
})
export default class UserAvatar extends Vue {
  generatedProfileColor: string | null = '';

  @Prop({ required: false, default: 'is-36x36' })
  imgSize!: string;

  @Prop({ required: false, default: 'is-rounded' })
  customClass!: string;

  @Prop({ required: false })
  initials!: string;

  @Prop({ required: false })
  avatar!: string;

  @Prop({ required: false })
  userId!: string;

  @Prop({required: false, default: false})
  isDeleted!: boolean

  // This only shows a green dot, meaning if user is online
  @Prop({ required: false, default: false })
  showStatus!: boolean;

  // This receives the icon if there's an active user status, e.g. "On a break"
  @Prop({ required: false, default: null })
  statusIcon!: string;

  @Prop({ required: false, default: null })
  displayName!: string;

  @Prop({ required: false, default: false })
  boardPresence!: boolean;

  @Prop({ required: false, default: false })
  unreadNotification!: boolean;

  @Prop({ required: false, default: false })
  isUserSidebar!: boolean;

  @Prop({required: false, default: false})
  openProfile!: boolean;

  @Prop({required: false, default: false})
  isBlocked!: boolean;

  get isOnline() {
    let workspacePresence = this.$store.state.online_user_ids.includes(this.userId);
    return this.boardPresence ? this.isOnlineOnBoard : workspacePresence;
  }


  get isOnlineOnBoard() {
    if(this.board){
      return PusherService.isViewingBoard(this.userId, this.board.pid);
    }
    return true;

  }

  get board() {
    return this.$store.state.board;
  }

  openProfileDetails() {
    if (this.$props.openProfile && !this.$props.isBlocked) {
      if (this.$props.userId === this.$store.state.me.id) {
        this.$buefy.modal.open({
          component: UserProfile,
          // @ts-ignore
          customClass: 'is-paddingless user_profile_modal',
          width: '700px',
          parent: this,
        });
      } else {
        //this.$events.fire('refetch-workspace');
        this.$buefy.modal.open({
          component: UserProfileDetails,
          // @ts-ignore
          customClass: 'is-paddingless other_user_profile_modal',
          props: {userId: this.$props.userId},
          width: '520px',
          parent: this,
        });
      }
    }
  }

}
