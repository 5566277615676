


































































import {Component, Prop, Vue} from "vue-property-decorator";
import {User} from "@/typescript/types";
import UsersSelector from '@/components/formfields/UsersSelector.vue';
import {CreatedReminders, CreateReminderMutation} from "@/graphql/auth";

@Component({
  components: {UsersSelector},
})

export default class ObjectiveCreateReminderModal extends Vue {
  selectedUsers: Array<User> = [];
  reminderName: string | null = null;
  reminderDescription: string | null = null;

  @Prop({required: true})
  date_time!: string;
  @Prop({required: true})
  remindable_id!: string;
  @Prop({required: true})
  remindable_type!: string;
  @Prop({required: false})
  objective_title!: string;

  mounted() {
    if(this.objective_title) {
      this.reminderName = 'reminder for ' + this.objective_title;
    }
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.reminderName.focus();
    });
  }

  get formComplete() {
    if (!this.reminderName || !this.usersForReminder) {
      return false;
    }
    return true;
  }

  get boardUsers() {
    return this.$store.state.board.members.map((member: any) => member.user);
  }

  get boardName() {
    return this.$store.state.board.name;
  }

  get usersForReminder() {
    if (this.selectedUsers.length == 0) {
      return [this.$store.state.me.id];
    } else {
      return this.selectedUsers.map((user) => user.id);
    }
  }

  createReminder() {
    this.$apollo
      .mutate({
        mutation: CreateReminderMutation,
        variables: {
          name: this.reminderName,
          description: this.reminderDescription,
          date_time: this.date_time,
          remindable_id: this.remindable_id,
          remindable_type: this.remindable_type,
          users: this.usersForReminder
        },
        refetchQueries: [{query: CreatedReminders}],
      })
      .then(({data: {createReminder}}) => {
        this.$store.commit('set_new_reminder_id', createReminder.id);
        //@ts-ignore ToDo: fix type issue
        this.$parent.close();
        this.$emit('reminder-created', 'Reminder Created!');
      })
    ;
  }

}
