



































































import {Component, Prop, Vue} from "vue-property-decorator";
import {formatDistance} from "date-fns";
import Roles from "@/roles";
import UserStatusText from "@/components/user/UserStatusText.vue";
import {BoardQuery, CreateBoardMutation} from "@/graphql/board";
//import {WorkspaceQuery} from "@/graphql/workspace";
import gql from "graphql-tag";
import {WorkspaceQuery} from "@/graphql/workspace";
import CreateReminder from "@/components/user/CreateReminder.vue";
import Template from "@/components/workspace/Template.vue";
import CountryFlag from 'vue-country-flag'


@Component({
  components: {Template, UserStatusText, CountryFlag}
})
export default class UserProfileDetails extends Vue {
  mutualRoomPid: string | null = null;
  openedFromBoard: boolean = false;
  userLocalTime: Date | null = null;
  interval: any | null = null;

  @Prop({ default: undefined, required: true })
  userId!: string;

  get profileData(){
    return this.$store.state.workspace.members.find((user: any) => user.user.id === this.$props.userId);
  }
  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceName() {
    return this.workspace.name;
  }

  get filteredActiveOnObjectives() {
    let currentMember = this.workspace.members.filter((member: any) => {
      return member.user.id === this.$props.userId;
    });


    const checkUser1 = (user: any) => user.user.id === this.$store.state.me.id;
    const checkUser2 = (user: any) => user.user.id === this.$props.userId;

    if(currentMember[0].user.activeOnObjectives.length > 0) {
      return currentMember[0].user.activeOnObjectives.filter((obj: any) => {
        if (obj.board.members.some(checkUser1) && obj.board.members.some(checkUser2)) {
          return obj;
        }
      })
    }

    return [];
  }


  hasMutualRoom() {

    let privateRooms = this.workspace.boards.filter((board: any) => {
      return board.permissions === 'private' && board.name.includes(this.$store.state.me.first_name) && board.name.includes(this.profileData.user.first_name);
    });

    if (privateRooms && privateRooms.length == 1 && privateRooms[0].members.length == 2) {
      const checkUser1 = (user: any) => user.user.id === this.$store.state.me.id;
      const checkUser2 = (user: any) => user.user.id === this.$props.userId;

      if (privateRooms[0].members.some(checkUser1) && privateRooms[0].members.some(checkUser2)) {
        this.mutualRoomPid = privateRooms[0].pid;
        if(this.$store.state.active_board_pid === this.mutualRoomPid) {
          this.openedFromBoard = true;
        }
        return true;
      }
      return false;
    }

    return false;
  }
  
  openUserSchedule(userId: string, profile: any) {
    this.$router.push({name: 'workspace-all-objectives', params: {
      user_id: userId, 
      member_data: profile
    }})
    this.$nextTick(function () {
      //@ts-ignore
      this.$parent.close();
    });
  }

  goToRoom() {
    this.$store.commit('set_new_board_pid', this.mutualRoomPid);
    this.$router.push({name: 'board-layout', params: {board_pid: this.mutualRoomPid!}});
    //@ts-ignore
    this.$parent.close();
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  roleName(role: string) {
    return Roles.formateRole(role);
  }

  createRoom() {
    let roomName = this.$store.state.me.first_name + ' x ' + this.profileData.user.first_name;


    this.$apollo
      .mutate({
        mutation: CreateBoardMutation,
        variables: {
          name: roomName,
          workspace: this.workspace.id,
          description: null,
          permissions: 'private',
          board_level_identifier: 2,
          sprint_interval: 3,
          board_background_id: null,
        },
        refetchQueries: [{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
      })
      .then(({data: {createBoard: board}}) => {
        this.addMembersToBoard(board.id);
        this.$store.commit('set_new_board_pid', board.pid);
        this.$buefy.toast.open({
          message: 'Room created',
          position: 'is-bottom-right',
          type: 'is-black',
        });

        this.$nextTick(function () {
          this.$router.push({name: 'board-layout', params: {board_pid: board.pid}});

          //@ts-ignore
          this.$parent.close();
        });
      })
      .catch((_error) => {

      })
      .finally(() => {

      });
  }

  addMembersToBoard(boardId: string) {

    this.$apollo
      .mutate({
        mutation: gql`
                      mutation addUserToBoard($user_id: ID!, $board_id: ID!) {
                          addUserToBoard(user_id: $user_id, board_id: $board_id) {
                              user {
                                  id
                                  name
                              }
                              board {
                                  id
                                  pid
                                  name
                              }
                              role
                          }
                      }
                  `,
        variables: {
          user_id: this.$props.userId,
          board_id: boardId,
        },
        refetchQueries: [{query: BoardQuery, variables: {id: boardId}}],
      })
      .finally(() => {
        //
      });


  }

  createReminder() {
    this.$buefy.modal.open({
      component: CreateReminder,
      parent: this,
      props: { 'user': this.profileData.user},
      width: '480px',
    });
  }

  mounted() {
    if(this.profileData.user.timezone){
      //@ts-ignore
      this.userLocalTime = this.$timezoneLocalTime.getTimeZoneLocalTime(new Date(), this.profileData.user.timezone);
      this.interval = setInterval(() => {
        //@ts-ignore
        this.userLocalTime = this.$timezoneLocalTime.getTimeZoneLocalTime(new Date(), this.profileData.user.timezone);
      }, 1000);
    }

  }

  beforeDestroy() {
    clearInterval(this.interval)
  }

}
