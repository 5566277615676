












































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {CopyBookmarkMutation, CreateBookmarkMutation} from "@/graphql/bookmark";
import {BoardQuery} from "@/graphql/board";
import Template from "@/components/templates/Template.vue";
import {Bookmark} from "@/typescript/types";

@Component({
  components: {Template},
})
export default class AddBookmark extends Vue {
  url: string  = 'https://';
  name: string | null = null;
  linkMatch: boolean = false;
  detectedUrl: string | null = null;
  allowNameEdit: boolean = true;

  urlSuggestions: Array<string> = [
    'https://spotify.com',
    'https://zoom.com',
  ];

  selectedSuggestions: Array<string> = [];


  @Prop({default: 1, required: true})
  origin!: number;

  @Prop({default: false, required: false})
  inModal!: boolean;

  @Prop({default: undefined, required: true})
  origin_id!: string;

  @Prop({default: undefined, required: false})
  urldata!: string;


  get favicon() {
    return 'https://www.google.com/s2/favicons?sz=64&domain_url=' + this.detectedUrl;
  }

  setSiteName() {
    let domain = (new URL(this.url!));
    // @ts-ignore
    domain = domain.hostname.replace('www.', '').replace('.com', '');
    this.name = domain.toString();
  }

  reset() {
    this.url = 'https://';
    this.name = null;
    this.detectedUrl = null;
    this.linkMatch = false;
    this.allowNameEdit = true;
  }

  get boardId() {
    return this.$store.state.board.id;
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close();
  }

  prefillHttps() {
    if (!this.url || this.url === 'https://' || this.url.trim() === '') {
      this.url = 'https://';
      
    }
  }

  addFromSuggestion(url: string) {
    this.allowNameEdit = false;
    this.url = url;
    this.linkMatch = true;
    this.setSiteName();
    this.createBookmark();
  }


  createBookmark() {
    
    if(this.url && this.name && this.linkMatch) {
      this.$apollo
        .mutate({
          mutation: CreateBookmarkMutation,
          variables: {
            name: this.name,
            url: this.url,
            bookmark_type_id: this.origin_id,
            bookmark_level: this.origin
          },
          refetchQueries:[{query: BoardQuery, variables:{pid: this.$route.params.board_pid }}]
        })
        .then((result: any) => {
          this.$gtag.event('pmb_room_bookmark_added');
          this.$emit('bookmark', result.data.createBookmark);
          if(this.origin == 1){
            this.$events.fire('bookmark-added', {board_pid: this.$route.params.board_pid, bookmark: result.data.createBookmark});
          }
          this.reset();
          this.$buefy.toast.open({
            message: 'Added',
            position: 'is-bottom-right',
            type: 'is-black',
          });
          if(this.inModal) {
            //@ts-ignore
            this.$parent.close();
          }
        })
      ;
    }
  }

  checkUrl() {
    const string = this.url;
    const matches = string!.match(/\bhttps?:\/\/\S+/gi);
    if (matches) {
      this.linkMatch = true;
      this.detectedUrl = matches[0];
      this.setSiteName();
    } else {
      this.linkMatch = false;
    }
  }

  get bookmarkSuggestions() {
    if(this.$store.state.board.board_template && this.$store.state.board.board_template.bookmarks.length > 0) {
      return this.$store.state.board.board_template.bookmarks.filter((bookmark: Bookmark) => {
        if(!this.selectedSuggestions.includes(bookmark.id)) {
          return bookmark;
        }
      })
    }
    return null;
  }

  copyBookmark(bookmarkid: string) {
    this.$apollo
      .mutate({
        mutation: CopyBookmarkMutation,
        variables: {
          bookmark_id: bookmarkid,
          board_id: this.$store.state.board.id
        },
        refetchQueries:[{query: BoardQuery, variables:{pid: this.$route.params.board_pid }}]
      }).then((result: any) => {
        this.selectedSuggestions.push(bookmarkid);
        this.$emit('bookmark', result.data.copyBookmark);
        if(this.origin == 1){
          this.$events.fire('bookmark-added', result.data.copyBookmark);
        }
        this.$buefy.toast.open({
          message: 'Added',
          position: 'is-bottom-right',
          type: 'is-black',
        });

      })
  }


  mounted() {
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainCreate.focus();
    });

    if(this.$props.urldata){
      this.url = this.$props.urldata;
      this.checkUrl();
    }
  }
}
