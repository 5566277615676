
























































import {Component, Vue, Watch} from "vue-property-decorator";
import {UpdateUserMutation, MeQuery} from "@/graphql/auth";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
//import CircleStencil from "@/components/general/CircleStencil.vue";



@Component({
  components: {Cropper, CircleStencil}
})
export default class AvatarModal extends Vue{
  clicked = false;

  image: null | File = null;
  image_url: null | string = null;
  image_base64: null | ArrayBuffer | string = null;
  figure: boolean = false;

  get hasAvatar() {
    if(this.$store.state.me.avatar) {
      return true;
    } else {
      return false;
    }
  }

  @Watch('image')
  onPropertyChanged(image: null | File) {
    if(image !== null) {
      this.figure = false;
      this.image_url = URL.createObjectURL(image);
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        this.image_base64 = reader.result;
      };
    }
  }

  clearUpload() {
    this.image = null;
    this.image_url = null;
    this.image_base64 = null;
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  save() {
    this.figure = false;
    this.uploadFirstAvatar();
  }

  replaceOldAvatar() {

    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.$store.state.me.id,
          avatar: null,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(_ => {
        this.uploadFirstAvatar();
        //location.reload(true);
      })
      .catch(error => {
        console.error(error);
      })
  }

  uploadFirstAvatar() {
    //@ts-ignore
    const { _coordinates, canvas, } = this.$refs.cropper.getResult();

    this.image_base64 = canvas.toDataURL("image/png", 0.85);

    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.$store.state.me.id,
          avatar: this.image_base64,
        },
        refetchQueries: [{query: MeQuery}],
      })
      .then(_ => {
        
        this.$forceUpdate();
        //this.$events.fire('avatar-updated', this.image_url)
        // location.reload(true);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.clicked = false;
        this.$buefy.toast.open({
          message: 'Avatar updated!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        this.$parent.close();
      });
  }

  mounted() {
    if(this.$store.state.me.avatar) {
      this.figure= true;
      this.image_url = this.$store.state.me.avatar;
    }
  }
}
