import store from './store';
import Aes from "crypto-js/aes";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";

class Encryption {
  decrypt(value: string): string {
    const workspace = store.state.workspace;

    if (!workspace) {
      return value;
    }

    const key = workspace.encryption_key;

    if (!key) {
      return value;
    }

    let encryptedData: any;

    try {
      encryptedData = JSON.parse(atob(value));
    } catch (e) {
      return value;
    }

    return Aes.decrypt(encryptedData.value, Base64.parse(key), {
      iv: Base64.parse(encryptedData.iv)
    }).toString(Utf8);
  }
}

export default new Encryption();
