
























































































































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";
import {CreateCustomTag, DeleteCustomTag} from "@/graphql/customTags";
import Template from "@/components/workspace/Template.vue";
import {AssignCustomTagsObjectives, ObjectivesQuery} from "@/graphql/objective";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import {BoardQuery} from "@/graphql/board";

@Component({
  components: {Template}
})
export default class AddTags extends Vue {
  @Prop({default: undefined, required: true})
  board_id!: string;

  @Prop({default: undefined, required: false})
  objective_id!: string;

  @Prop({default: undefined, required: false})
  board_tags!: Array<any>;

  @Prop({default: undefined, required: false})
  objective_tags!: Array<any>;

  @Prop({default: 'objective', required: false})
  level!: string;

  tagName: string | null = null;
  tagColor: string | null = null;
  isCreate: boolean = false;
  searchName: string | null = null;

  colors: Array<{name:string}> = [
    { name: 'is-primary' },
    { name: 'is-success' },
    { name: 'is-warning' },
    { name: 'is-danger' },
    { name: 'is-info' },
    { name: 'is-dark' },
    { name: 'is-light' },
    { name: 'is-black' },
    { name: 'is-white' },
  ];


  get filteredTags(){
    return this.board_tags.filter( (tag: any) => {
      return !this.searchName ||
        tag.name.toLowerCase().indexOf(this.searchName.toLowerCase()) > -1
    });
  }

  getIndex(obj: any, id: string){
    return obj.findIndex((tag: any) => tag.id === id);
  }


  objectiveTagPresent(id: string){
    let index = this.getIndex(this.objective_tags, id);
    if(index != -1){
      return true;
    }else{
      return false;
    }
  }

  removeOrAddTags(tag: any){
    if(this.level === 'objective'){
      let index = this.getIndex(this.objective_tags, tag.id);
      if(index != -1){
        this.objective_tags.splice(index, 1);
        return 'Removed Tag';
      }else{
        this.objective_tags.push(tag);
        return 'Assigned';
      }
    }else{
      let index = this.getIndex(this.board_tags, tag.id);
      if(index != -1){
        this.board_tags.splice(index, 1);
        return 'Removed Tag';
      }else{
        this.board_tags.push(tag);
        return 'Assigned';
      }
    }

  }

  removeBoardTags(tag: any){
    let index = this.getIndex(this.board_tags, tag.id);
    if(index != -1){
      this.board_tags.splice(index, 1);
    }
  }

  setColor(name: string){
    this.tagColor = name;
  }

  createTag(){
    this.$apollo
      .mutate({
        mutation: CreateCustomTag,
        variables: {
          name: this.tagName,
          color: this.tagColor,
          board_id: this.board_id,
          objective_id: this.objective_id
        },
        refetchQueries:[{query: ObjectivesQuery, variables:{board_id: this.board_id}},{query: BoardQuery, variables:{id: this.board_id}}]
      })
      .then((result) => {
        this.board_tags.push(result.data.createCustomTag);
        this.removeOrAddTags(result.data.createCustomTag);
        this.$buefy.toast.open({
          message: 'Added',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        //@ts-ignore
        this.$parent.close();
      })
    ;
  }

  createNew(){
    this.isCreate = true;
    this.tagName = this.searchName;
  }

  assignTags(tag: any){

    let msg = this.removeOrAddTags(tag);
    this.$apollo
      .mutate({
        mutation: AssignCustomTagsObjectives,
        variables: {
          objective_id: this.objective_id,
          custom_tag_ids: this.objective_tags.map((tag:any)=>{ return tag.id; })
        },
        refetchQueries:[{query: ObjectivesQuery, variables:{board_id: this.board_id}},{query: BoardQuery, variables:{id: this.board_id}}]
      })
      .then(() => {
        this.$buefy.toast.open({
          message: msg,
          position: 'is-bottom-right',
          type: 'is-black',
        });

      })
    ;
  }

  openDeleteTagModal(id: string){
    this.$buefy.modal.open({
      parent: this,
      component: ConfirmDelete,
      props: {
        deleteItemName: 'Are you sure you want to delete?',
        deleteParagraph: 'The tag will be deleted from the entire Room. You will also lose all content related to this tag, and you will not be able to recover it once you confirm this action.',
        closeParentFirst: true
      },
      events: {
        //@ts-ignore ToDo: fix type issue
        'confirmed': value => {
          if (value === true) {
            this.deleteTag(id);
          }
        }
      },
      width: '500px',
    });
  }

  deleteTag(id: string){
    this.$apollo
      .mutate({
        mutation: DeleteCustomTag,
        variables: {
          id: id
        },
      })
      .then((result) => {
        if(this.level === 'objective'){
          this.removeBoardTags(result.data.deleteCustomTag);
          this.removeOrAddTags(result.data.deleteCustomTag);
        }else{
          this.removeBoardTags(result.data.deleteCustomTag);
        }

        this.$buefy.toast.open({
          message: 'Deleted',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }



}
