






import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { ObjectiveDetailedFragment } from '@/graphql/objective';

@Component({
  components: {},
})

export default class DateTimeInput extends Vue {
  isActive = false;

  zzDate: Date | null = null;

  date: Date | null = null;

  @Prop({ default: null, required: false })
  dueDate!: Date | null;

  @Prop({ required: true })
  objectiveId!: string;

  changeDate() {
    
  }

  updateObjective() {
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation updateObjective($id: ID!, $date: DateTime) {
                        updateObjective(id: $id, input: { due_date: $date }) {
                            ...ObjectiveDetailed
                        }
                    }
                    ${ObjectiveDetailedFragment}
                `,
        variables: {
          id: this.objectiveId,
          date: this.zzDate,
        },
      })
      .then(() => {
        this.$buefy.toast.open({
          message: 'Changes saved!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
        this.isActive = false;
      })
      .finally(() => {
        //
      });
  }

  mounted() {
    if (this.dueDate) {
      this.date = this.dueDate;
    }
  }
}
