





























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {UpdateWorkspaceMutation, WorkspaceQuery} from "@/graphql/workspace";

@Component
export default class AvatarModal extends Vue{
  @Prop({ required: true })
  workspace_id!: string;

  clicked = false;

  image: null | File = null;
  image_url: null | string = null;
  image_base64: null | ArrayBuffer | string = null;

  @Watch('image')
  onPropertyChanged(image: null | File) {
    if(image !== null) {
      this.image_url = URL.createObjectURL(image);
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        this.image_base64 = reader.result;
      };
    }
  }

  clearUpload() {
    this.image = null;
    this.image_url = null;
    this.image_base64 = null;
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  /* Needed to to be able to call `this.$parent.close()` below,
     * see https://github.com/buefy/buefy/issues/1184
     */
  public $parent: any;

  save() {
    this.clicked = true;
    this.$apollo
      .mutate({
        mutation: UpdateWorkspaceMutation,
        variables: {
          id: this.workspace_id,
          avatar: this.image_base64,
        },
        refetchQueries:[{query: WorkspaceQuery, variables:{pid: this.$store.state.workspace.pid}}]
      })
      .then(w => {
        this.$parent.close();
        this.$events.fire('workspace-avatar-changed', w.data.updateWorkspace.avatar);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        this.clicked = false;
      });
  }
}
