import gql from "graphql-tag";

export const ObjectiveReccurenceDetailedFragment = gql`
    fragment ObjectiveRecurrenceDetailed on ObjectiveRecurrence 
    {
        id
        interval
        day
        time
        start_date
        end_date
        objective {
            id
            name
        }
        next_occurrence
        status
        creator {
            id
            name
            deleted_at
        }
        exception
        current_streak
        longest_streak
        times_repeated
        created_at
        updated_at
        history {
            data {
                id
                start_date
                objective_status
                task_count
                completed_task_count
            }
        }
    }
`;

export const CreateObjectiveRecurrenceMutation = gql`
    mutation createObjectiveRecurrence(
        $interval: RecurrenceInterval!
        $day: Int, 
        $time: Time, 
        $exception: [Int!]
        $start_date: Date!
        $end_date: Date
        $objective_id: ID!,
    ) { 
        createObjectiveRecurrence(
            input: {
                interval: $interval
                day: $day
                time: $time
                start_date: $start_date
                end_date: $end_date
                objective: { connect: $objective_id }
                exception: $exception
            }
        ) {
            id,
            objective {
                start_date
            }
        }
    }
`;

export const DeleteObjectiveRecurrenceMutation = gql `
    mutation deleteObjectiveRecurrence($id: ID!){
        deleteObjectiveRecurrence(id: $id){
            id
        }
    }
`;