import gql from 'graphql-tag';

export const feedback_types = gql`
    query feedback_types {
        feedback_types {
            id
            name
        }
    }
`;

export const CreateFeedbackMutation = gql`
    mutation createFeedback($workspace_id: ID!, $creator_id: ID!, $feedback_type_id: ID!, $title: String!, $description: String!) {
        createFeedback(
            input: {
                workspace_id: $workspace_id
                creator_id: $creator_id
                feedback_type_id: $feedback_type_id
                title: $title
                description: $description
            }
        ) {
            id
        }
    }
`;