









































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class SocialAuth extends Vue {
  url: string | null = null;
  redirect_url: string | null = null;
  redirectClicked = false;

  @Prop({default: false, required: false})
  inRegister!: boolean;

  // Use the incoming url parameters to determine which social auth to use.
  // Checks if user is coming from ad url
  created() {
    this.redirectClicked = false;
    const urlParams = new URLSearchParams(window.location.search);
    const network = urlParams.get('network');
    const campaign = urlParams.get('campaign');
    const validNetworks = ['google', 'facebook', 'linkedin'];

    if (network !== null && validNetworks.includes(network)) {
      if(campaign === 'true') {
        this.$store.commit('set_user_from_ad', true);
      }
      this.socialAuth(network)
    } else {
      Vue.prototype.$consoleLog(`network url param not found`);
    }
  }

  socialAuth(network: string) {
    if(network) {
      this.$gtag.event('pmb_app_decision_social_' + network);
    }
    this.redirect_url = window.location.origin;
    switch (network) {
      case 'google':
        this.redirect_url = this.redirect_url + '/authcallback/google';
        this.url = 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=965126139567-mq7r25ei3o7ima0rostc0h28e5lvhnqi.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=' + this.redirect_url;
        break;
      case 'facebook':
        this.redirect_url = this.redirect_url + '/authcallback/facebook';
        this.url = 'https://www.facebook.com/v6.0/dialog/oauth?client_id=485296225720771&redirect_uri=' + this.redirect_url + '&state=&scope=email';
        break;
      case 'linkedin':
        this.redirect_url = this.redirect_url + '/authcallback/linkedin';
        this.url = 'https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86a1mb9j6txwi0&redirect_uri=' + this.redirect_url + '&state=&scope=r_liteprofile%20r_emailaddress';
        break;
      default:
        this.url = '/';
    }
    window.location.href = this.url;

  }
}
