import gql from "graphql-tag";

export const BookmarkDetailedFragment = gql`
    fragment BookmarkDetailed on Bookmark {
        id
        name
        url
        favicon
#        creator {
#            id
#            name
#            email
#            initials
#            avatar
#            deleted_at
#        }
        created_at
    }
`;

export const CreateBookmarkMutation = gql `
    mutation createBookmark($name: String!, $url: String!, $bookmark_level: Int!, $bookmark_type_id: ID!){
        createBookmark(input:{ name: $name, url: $url, bookmark_level: $bookmark_level, bookmark_type_id: $bookmark_type_id }){
            ...BookmarkDetailed
        }
    }
    ${BookmarkDetailedFragment}
`;

export const UpdateBookmarkMutation = gql `
    mutation updateBookmark($id: ID!, $name: String!, $url: String!){
        updateBookmark(id: $id, input:{ name: $name, url: $url}){
            ...BookmarkDetailed
        }
    }
    ${BookmarkDetailedFragment}
`;

export const DeleteBookmarkMutation = gql `
    mutation deleteBookmark($id: ID!){
        deleteBookmark(id: $id){
            id
        }
    }    
`;

export const CopyBookmarkMutation = gql `
    mutation copyBookmark($bookmark_id: ID!, $board_id: ID!){
        copyBookmark(bookmark_id: $bookmark_id, board_id: $board_id){
            ...BookmarkDetailed
        }
    }
    ${BookmarkDetailedFragment}
`;

