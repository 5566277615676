import gql from 'graphql-tag';
import {BookmarkDetailedFragment} from "@/graphql/bookmark";
import {CustomTagDetailedFragment} from "@/graphql/customTags";
import {ObjectiveDetailedFragment} from "@/graphql/objective";
import {AttachmentDetailedFragment} from "@/graphql/attachment";
import {BoardBackgroundDetailedFragment} from "@/graphql/BoardBackground";

export const BoardMetricsDetailedFragment = gql`
    fragment BoardMetricsDetailed on Board {
        metrics {
            objectivesCount
            keyResultsCount
            keyResultsCompletedCount
            keyResultsOverallProgress
        }
    }
`;

export const BoardSprintDetailedFragment = gql`
    fragment BoardSprintDetailed on Sprint {
        id
        title
        description
        created_at
        interval
        status
        start_at
        end_at
        sprintReflection {
            id
            reflection {
                description
            }
        }
#        metrics {
#            objectivesCount
#            keyResultsCount
#            keyResultsCompletedCount
#            keyResultsOverallProgress
#        }
    }
`;

export const BoardDetailedFragment = gql`
    fragment BoardDetailed on Board {
        id
        pid
        name
        description
        public
        background_color
        starred
        my_role
        permissions
        slackConnected
        order
        boardLevel {
            identifier
        }
        sprint_interval
        active_sprint {
            ...BoardSprintDetailed
        }        
        workspace {
            id
            pid
            name
            members {
                id
                user {
                    id
                    name
                    email
                    first_name
                    last_name
                    initials
                    avatar
                    deleted_at
                }
            }
        }
        members {
            id
            role
            user {
                id
                name
                email
                first_name
                last_name
                initials
                avatar
                deleted_at
                status {
                    id
                    user_id
                    emoji
                    status_text
                }
            }
            last_seen_board_message_id
        }
        owner {
            id
            name
            initials
            avatar
            deleted_at
        }
        milestones {
            id
            name
        }
        northStars {
            id
            name
            description
            status
            due_date
            completed_at
            color
            boards {
                id
            }
            objectives {
                id
                pid
                name
                description
                status
                created_at
                priority
                is_recurring
                order
                background_color
                start_date
                due_date
                deleted_at
                completed_at
            }
            objective_suggestions {
                id
                pid
                name
                description
                status
                created_at
                priority
                is_recurring
                order
                background_color
                start_date
                due_date
                deleted_at
                completed_at
            }
            northstar_copied_from_id
        }
        bookmarks {
            ...BookmarkDetailed
        }
        tags {
            ...CustomTagDetailed
        }        
        backgroundImage {
            ...BoardBackgroundDetailed
        }
        board_template {
            id
            pid
            name
            northStars {
                id
                name
                description
                status
                due_date
                completed_at
                color
                northstar_copied_from_id
                objectives {
                    id
                    pid
                    name                    
                }
            }
            bookmarks {
                ...BookmarkDetailed
            }
            attachments {
                ...AttachmentDetailed
            }
        }
        
    }
    ${BoardSprintDetailedFragment} 
    ${BookmarkDetailedFragment}
    ${CustomTagDetailedFragment}
    ${BoardBackgroundDetailedFragment}
    ${AttachmentDetailedFragment}
`;

export const BoardMessageDetailedFragment = gql`
    fragment BoardMessageDetailed on BoardMessage {
        id
        board {
            id
            pid
            name
        }
        sender {
            id
            name
            first_name
            initials
            avatar
        }
        reactionsCount
        reactions {
            id
            reaction
            reacter {
                ...on User {
                    id
                    name
                    initials
                    avatar
                    deleted_at
                }
            }
            created_at
        }
        reactionSummaries {
            reaction
            count
        }
        edited
        created_at
        updated_at
        deleted_at
        messageFormatted
        attachments {
            ...AttachmentDetailed
        }
        pinnedUsers {
            id
        }
    }
    ${AttachmentDetailedFragment}
`;

export const CreateBoardMessageMutation = gql`
    mutation CreateBoardMessage($id: ID!, $message: String, $files: [Upload!]) {
        createBoardMessage(input: { message: $message, board: { connect: $id }, files: $files }) {
            ...BoardMessageDetailed
        }
    }
    ${BoardMessageDetailedFragment}
`;

export const DeleteBoardMutation = gql`
    mutation DeleteBoard($id: ID!) {
        deleteBoard(id: $id) {
            id
        }
    }
`;

export const DeleteBoardHardMutation = gql`
    mutation DeleteBoardHard($id: ID!) {
        deleteBoardHard(id: $id) {
            id
        }
    }
`;

export const BoardMessagesQuery = gql`
    query BoardMessages($id: ID!, $first: Int, $page: Int) {
        getMessagesForBoard(id: $id, first: $first, page: $page) {
            data {
                ...BoardMessageDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${BoardMessageDetailedFragment}
`;

export const CreateSprintIfNotExistsMutation = gql`
    mutation CreateSprintIfNotExists($id: ID!, $date: DateTime) {
        CreateSprintIfNotExists(board_id: $id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const CreateBoardMutation = gql`
    mutation CreateBoard($workspace: ID!, $name: String!, $description: String, $public: Boolean, $permissions: String!, $board_level_identifier: Int!, $sprint_interval: Int, $board_background_id: ID) {
        createBoard(
            input: {
                workspace: { connect: $workspace }
                name: $name
                public: $public
                description: $description
                permissions: $permissions
                sprint_interval: $sprint_interval
                boardLevel: {
                    identifier: $board_level_identifier
                }
                board_background_id: $board_background_id
            }
        ) {
            id
            pid
            name
            permissions
            sprint_interval
            description
            boardLevel {
                identifier
            }
            workspace {
                id
                pid
                name
                domain
            }
            backgroundImage {
                ...BoardBackgroundDetailed
            }
        }
    }
    ${BoardBackgroundDetailedFragment}
`;

export const CreateBoardFromTemplateMutation = gql`
    mutation createBoardFromTemplate($template_id: ID!, $name: String, $permissions: String!, $workspace: ID!, $copyall: Boolean) {
        createBoardFromTemplate(
            input: {
                workspace: { connect: $workspace }
                name: $name
                template_id: $template_id
                permissions: $permissions
                copyall: $copyall
            }
        ) {
            id
            pid
            name
        }
    }
`;

export const BoardQuery = gql`
    query Board($id: ID, $pid: ID) {
        board(id: $id, pid: $pid) {
            ...BoardDetailed
        }
    }
    ${BoardDetailedFragment}
`;

export const SprintQuery = gql`
    query getSprint($board_id: ID!, $date: String!) {
        getSprint(board_id: $board_id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const SprintsQuery = gql`
    query getSprints($board_id: ID!, $date: String!) {
        getSprints(board_id: $board_id, date: $date) {
            data {
                id
                start_at
                interval
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
`;

export const CloseSprint = gql`
    mutation closeSprint($sprint_id: ID!) {
        closeSprint(sprint_id: $sprint_id) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;


export const CreateSprintIfNotExists = gql`
    mutation createSprintIfNotExists($board_id: ID!, $date: DateTime!) {
        createSprintIfNotExists(board_id: $board_id, date: $date) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const CreateReflection = gql`
    mutation createReflection($user_id: ID!, $sprint_id: ID!, $reflection: SprintReflectionObjectInput) {
        createReflection(
            input: {
                user_id: $user_id,
                sprint_id: $sprint_id,
                reflection: $reflection
            }
        ) {
            id
            reflection {
                description
            }
        }
    }
`;

export const UpdateReflection = gql`
    mutation updateReflection($id: ID!, $reflection: SprintReflectionObjectInput) {
        updateReflection(
            id: $id
            input: {
                reflection: $reflection
            }
        ) {
            id
            reflection {
                description
            }
        }
    }
`;

export const GetPersonalBoardQuery = gql`
    query getPersonalBoard($id: ID, $pid: ID){
        getPersonalBoard(id: $id, pid: $pid){
            ...BoardDetailed
        }
    }
    ${BoardDetailedFragment}
`;

export const ReadAllBoardNotificationsMutation = gql`
    mutation readAllNotifications($board_id: ID!) {
        readAllBoardNotifications(board_id: $board_id)
    }
`;

export const LeaveBoardMutation = gql`
    mutation leaveBoard($id: ID!) {
        leaveBoard(id: $id) {
            id
        }
    }
`;

export const UpdateSprintMutation = gql`
    mutation UpdateSprint(
        $id: ID!
        $title: String
        $description: String
    ) {
        updateSprint(
            id: $id,
            input: {
                title: $title
                description: $description,
            }
        ) {
            ...BoardSprintDetailed
        }
    }
    ${BoardSprintDetailedFragment}
`;

export const UpdateBoardObjectives = gql`
    mutation UpdatedBoard(
        $id: ID!
        $objectives: [UpdateObjectiveInput!]
    ) {
        updateBoardGl(
            id: $id,
            data: {
                objectives: {
                    update: $objectives
                }
            }
        ) {
            objectives {
                ...ObjectiveDetailed
            }
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const RestoreBoardMutation = gql`
    mutation RestoreBoard($id: ID!) {
        restoreBoard(id: $id) {
            id,
            pid
        }
    }
`;

export const AddBoardAttachments = gql `
    mutation addBoardAttachments($board_id: ID! ,$workspace_id: ID!, $files: [Upload!]!) {
        addBoardAttachments(
            input: {
                board_id: $board_id
                workspace_id: $workspace_id
                files: $files
            }
        ){
            ...AttachmentDetailed
        }
    }
    ${AttachmentDetailedFragment}
`;

export const PinBoardMessage = gql `
    mutation pinBoardMessage($id: ID!) {
        pinMessage(id: $id){
            id
        }
    }
`;

export const UnPinBoardMessage = gql `
    mutation unPinBoardMessage($id: ID!) {
        unPinMessage(id: $id){
            id
        }
    }
`;

export const UpdateBoardMessageMutation = gql`
    mutation UpdateBoardMessage($id: ID!, $message: String!, $files: [Upload!]) {
        updateBoardMessage(id: $id, input: { message: $message, files: $files }) {
            ...BoardMessageDetailed
        }
    }
    ${BoardMessageDetailedFragment}
`;

export const UpdateLastSeenMessageMutation = gql`
    mutation UpdateLastSeenMessage($user_id: ID!, $board_id: ID!, $chat_message_id: ID!) {
        updateLastSeenMessage(user_id: $user_id, board_id: $board_id, chat_message_id: $chat_message_id) {
            id
        }
    }    
`;

export const CreatePersonalTemplateFromBoard = gql`
    mutation CreatePersonalTemplateFromBoard($board_id: ID!) {
        createPersonalWorkspaceTemplate(board_id: $board_id) {
            ...BoardDetailed
        }
    }
    ${BoardDetailedFragment}
`;

export const RemoveUnseenBoardMessageMutation = gql`
    mutation RemoveUnseenBoardMessage($board_id: ID!, $user_id: ID!) {
        removeUnseenBoardMessage(board_id: $board_id, user_id: $user_id)
    }
`;

