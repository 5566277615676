























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {NorthStar, Objective} from "@/typescript/types";
import NorthStarService from "@/NorthStarService";

@Component({
  components: {},
})
export default class ObjectiveNorthStarLinker extends Vue {
  selectedNorthStar: Array<any> | null = null;
  service: NorthStarService | null = null;
  previousSelectedNorthstar: Array<any> | null = null;

  @Prop({required: true})
  objective!: Objective;

  created(){
    this.selectedNorthStar =  this.previousSelectedNorthstar =  (this.objective!.northStars)? this.objective!.northStars : null;
  }

  mounted() {
    this.service = new NorthStarService(this.$apollo);
  }

  get northStars() {
    return this.board.northStars?.filter((northStart: any) => {
      return northStart.status != 'completed'
    });
  }

  get linkedNorthStars() {
    let goalCount = this.objective?.northStars!!.length;
    if(goalCount > 0 && !!this.selectedNorthStar){
      let text = '';
      let goalText = this.selectedNorthStar!!.map((goal:any, i: any)=>{

        if(goalCount === i + 1 && goalCount != 1){
          return text+' & '+goal.name;
        }else if(goalCount-1 === i + 1){
          return text+goal.name;
        }else if(goalCount === 1){
          return text+goal.name;
        }else{
          return text+goal.name+', ';
        }

      }).join('');
      return goalCount+((goalCount > 1)?' linked goals: ':' linked goal: ')+ goalText;
    }

    return null;

  }

  get objectiveNorthStar() {
    let stars = this.objective?.northStars;

    if (!stars) {
      return null;
    }

    return stars[stars.length - 1];
  }

  get board() {
    return this.$store.state.board;
  }

  checkIfPresent(star: any) {
    return this.selectedNorthStar!!.some((stars: any) => stars.id === star.id);
  }

  setNorthStar(northStar: NorthStar){
    // let index = this.selectedNorthStar!!.findIndex((star: any) => {
    //return star.id === northStar.id;
    //});

    // if (index != -1) {
    //   this.selectedNorthStar!!.splice(index, 1);
    // } else {
    this.selectedNorthStar = [];
    this.selectedNorthStar!!.push(northStar);
    // }

    this.service?.addNorthStarToObjective(this.objective, this.selectedNorthStar!!).then(_ => {
      this.$events.fire('northstar-linked-to-objective', {objective: this.objective, northStar: northStar, previousNorthstar: this.previousSelectedNorthstar});
      this.previousSelectedNorthstar = this.selectedNorthStar;
      this.$buefy.toast.open({
        //message: (index!=-1) ? 'Goal link removed!' : 'Goal linked!',
        message: 'Goal linked!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    });
  }

  removeNorthStar() {
    this.service?.removeNorthStarFromObjective(this.objective).then(_ => {
      this.selectedNorthStar = [];
      this.$events.fire('northstar-linked-to-objective', {objective: this.objective, northStar: null, previousNorthstar: this.previousSelectedNorthstar});
      this.$buefy.toast.open({
        //message: (index!=-1) ? 'Goal link removed!' : 'Goal linked!',
        message: 'Goal disconnected!',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    });
  }

}
