







































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import UserAvatar from "../user/UserAvatar.vue";
import AvatarModal from "@/components/settings/personal/AvatarModal.vue";
import { UpdateUserMutation } from '@/graphql/auth';
import UserService from '@/services/UserService';
import BoardMembers from "@/components/board/BoardMembers.vue";

@Component({
  components: { UserAvatar, AvatarModal },
})
export default class EmailClaimSuccess extends Vue {
  firstNameInput: string = "";
  lastNameInput: string = "";
  emailInput: string = "";
  emailClaim: string = "";
  emailClaimInput: string = "";
  
  passwordInput: string = "";
  email: string = "";

  isFirstNameFocused: boolean = false;
  isLastNameFocused: boolean = false;
  isEmailClaimFocused: boolean = false;
  isEmailFocused: boolean = false;
  isPasswordFocused: boolean = false;
  avatarUploaded: boolean = false;
  step: number = 1;
  password: string = "";
  service: UserService | null = null;
  loading: boolean = false;

  @Prop({default: false, required: false})
  userEmail!: string;

  @Prop({default: false, required: false})
  userClaimed!: boolean;

  @Prop({default: false, required: false})
  fromMemberInviteClick!: boolean;

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get firstNamePlaceholder() {
    const placeholder = this.me.first_name || 'Enter your first name';
    return this.truncateText(placeholder, 16);  
  }

  get lastNamePlaceholder() {
    const placeholder = this.me.last_name || 'Enter your last name';
    return this.truncateText(placeholder, 16);  
  }

  get emailPlaceholder() {
    const placeholder = this.me.email || 'Enter your email';
    return this.truncateText(placeholder, 16);  
  }

  get isSaveDisabled(): boolean {
    return (
      this.firstNameInput.trim() === "" &&
      this.lastNameInput.trim() === "" &&
      this.emailInput.trim() === "" &&
      this.passwordInput.trim() === ""
    );
  }

  get isEmailValid() {
    const email = this.step === 0 ? this.emailClaim : this.emailInput;
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.trim());
  }


  get hasAvatar() {
    if(this.$store.state.me.avatar) {
      return true;
    } else {
      return false;
    }
  }

  get isPasswordValid() {
    const password = this.passwordInput ?? '';
    return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(password);
  }

  get onboardingStep() {
    return this.$store.state.user_onboarding_number;
  }

  get showPasswordError() {
  // Show error if the password is invalid, the password input is not empty, and the field is blurred
    return !this.isPasswordValid && this.passwordInput.trim() !== '' && !this.isPasswordFocused;
  }

  get showEmailError() {
  // Show error if the email is invalid, the email input is not empty, and the field is blurred
    return !this.isEmailValid && this.emailInput.trim() !== '' && !this.isEmailFocused;
  }

  get canSaveChanges(): boolean {
  // Enable if either name is changed or avatar is uploaded
    const hasChanges = !this.isSaveDisabled || this.hasAvatar;

    // Email and password fields must be empty or valid
    const isEmailValidOrEmpty = this.emailInput.trim() === '' || this.isEmailValid;
    const isPasswordValidOrEmpty = this.passwordInput.trim() === '' || this.isPasswordValid;

    // Button should be enabled if there are changes and email/password are valid or empty
    return hasChanges && isEmailValidOrEmpty && isPasswordValidOrEmpty;
  }

  get claimAccountPrompt() {
    return this.$store.state.claim_account_prompt_count;
  }


  truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return text.substring(0, maxLength - 3) + '...';
    }
    return text;
  }

  openAvatarModal() {
    this.$buefy.modal.open({
      component: AvatarModal,
      props: {
        workspace_id: this.workspace.id,
      },
      width: '300px',
      parent: this,
      // @ts-ignore
      animation: 'none',
    });
  }

  async updateUser(event: MouseEvent) {
    event.stopPropagation();
    const updatedFirstName = this.firstNameInput || this.me.first_name;
    const updatedLastName = this.lastNameInput || this.me.last_name;
    const updatedEmail = this.emailInput || this.me.email;

   

    try {
      // Update user information
      const { data: userData } = await this.$apollo.mutate({
        mutation: UpdateUserMutation,
        variables: {
          id: this.me.id,
          first_name: updatedFirstName,
          last_name: updatedLastName,
          email: updatedEmail,
          country: this.me.country,
          country_code: this.me.country_code,
          city: this.me.city,
          timezone: this.me.timezone,
        },
      });

      // if (this.onboardingStep === 2) {
      //   this.$store.commit('set_user_onboarding_step', 3);
      //   this.$gtag.event('user_reached_onboarding_step_3');
      // }

      this.$store.commit('set_me', userData.updateUser);

      // If password is entered, use SetInitialPassword mutation
      if (this.passwordInput) {
        const userService = new UserService(this.$apollo);
        await userService.setInitialPassword(this.passwordInput);
      }

      this.$buefy.toast.open({
        message: 'Updated successfully',
        position: 'is-bottom-right',
        type: 'is-black',
      });

      if (this.claimAccountPrompt === 1 && this.fromMemberInviteClick) {
        this.openBoardMembers();
        this.$store.commit('set_claim_account_prompt', null);
      }

     
      
      this.closeModal();

    } catch (error) {
      console.error('Failed to update user', error);
      this.$buefy.toast.open({
        message: 'Failed to update user',
        position: 'is-bottom-right',
        type: 'is-danger',
      });
    }
  }

  focusInput(refName: string) {
    const input = this.$refs[refName] as HTMLInputElement;
    if (input) {
      input.focus();
    }
  }

  claimAccount() {

    if(this.userClaimed) {
      this.email = this.userEmail;
      console.log("user email: " + this.userEmail)
    }
    // else if(this.emailClaim) {
    //   this.email = this.emailClaim;
      
    // }
  
  this.service?.claimAccount(this.email, this.me.first_name, this.me.last_name, this.password).then(() => {
    this.$gtag.event('pmb_user_claimed_account_manually');
    this.$store.commit('set_auto_gen_user_data', null);
    this.handleSuccess();
    
  },

  ).catch(() => {
    this.$gtag.event('pmb_user_claimed_account_failed');
  }).finally(() => {
  });
  }

  handleSuccess() {
  // Your logic after successful verification
    this.$gtag.event('pmb_user_claim_account_verification_success');
    this.$buefy.toast.open({
      message: 'You can now access your account',
      position: 'is-bottom-right',
      type: 'is-black',
    });
    this.$store.commit('set_claim_account_verify', false);
    this.$store.commit('set_currently_on_verification_code_step', false);
    this.$store.commit('set_currently_onboarding_in_room', false);
    this.$store.commit('set_show_claim_account_notification_bar', false);
    this.$emit('account-claimed', true)
    this.loading = false;
  }

  handleFocus(field: string, isFocused: boolean) {
    switch(field) {
      case 'emailClaimInput':
        this.isEmailClaimFocused = isFocused;
        break;
      case 'firstName':
        this.isFirstNameFocused = isFocused;
        break;
      case 'lastName':
        this.isLastNameFocused = isFocused;
        break;
      case 'email':
        this.isEmailFocused = isFocused;
        break;
      case 'password':
        this.isPasswordFocused = isFocused;
        break;
    }
  }

  generatePassword() {
    const length = 15;
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const allCharacters = uppercase + lowercase + numbers;
  
    // Ensure at least one of each required character type
    let password = '';
    password += uppercase.charAt(Math.floor(Math.random() * uppercase.length));
    password += lowercase.charAt(Math.floor(Math.random() * lowercase.length));
    password += numbers.charAt(Math.floor(Math.random() * numbers.length));

    // Fill the rest of the password length with random characters
    for (let i = password.length; i < length; i++) {
      password += allCharacters.charAt(Math.floor(Math.random() * allCharacters.length));
    }

    // Shuffle the password to prevent predictable patterns
    this.password = password.split('').sort(() => 0.5 - Math.random()).join('');
  }

  openBoardMembers() {
    this.$buefy.modal.open({
      component: BoardMembers,
      width: '480px',
      parent: this,
    }); 
  }

  closeModal() {
    if (this.onboardingStep === 2) {
      // this.$store.commit('set_user_onboarding_step', 3);
      // this.$gtag.event('user_reached_onboarding_step_3');
    }
    // @ts-ignore
    this.$parent.close() 
  }


  clearOpenState() {
    if (this.claimAccountPrompt === 1 && this.fromMemberInviteClick) {
      this.openBoardMembers();
      this.$store.commit('set_claim_account_prompt', null);
    }
    this.$store.commit('set_claim_account_open', null);
    this.closeModal();
  }

  beforeDestroy() {
    window.removeEventListener('popstate', this.clearOpenState);
    window.removeEventListener('beforeunload', this.clearOpenState); 
    this.clearOpenState();
  }

  mounted() {
    this.service = new UserService(this.$apollo);

    window.addEventListener('popstate', this.clearOpenState);
    window.addEventListener('beforeunload', this.clearOpenState); 

    

    this.$nextTick(() => {
      //@ts-ignore
      this.$refs.emailClaimInput.focus();
    });

    this.generatePassword();

    if(this.userClaimed) {
      console.log("user claimed")
      this.loading = true;
      this.claimAccount();
    }

    this.$gtag.event('pmb_user_email_claim_mounted');
  }
}
