































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {ObjectiveDetailedFragment, ObjectivesQuery} from '@/graphql/objective';
import gql from 'graphql-tag';
import Formatting from "@/components/chat/Formatting.vue";
import {Objective} from "@/typescript/types";
import Encryption from '@/encryption';


@Component({
  components: {Formatting},
})
export default class ObjectiveInformation extends Vue {
  @Prop({required: true})
  objective: Objective | null | undefined;

  @Prop({required: false})
  descAutoFocus!: boolean;

  objectiveNameInput: string | undefined | null = null;
  objectiveDescriptionInput: string | undefined | null = null;
  hasChangedFields = false;

  editDescriptionActive = false;

  mounted() {
    this.load();
    if (this.descAutoFocus) {
      //@ts-ignore ToDo: fix type issue
      this.$refs.oDescription.focus();
      this.editDescriptionActive = true;
    }
    // @ts-ignore
    this.$refs.oDescription.innerHTML = Encryption.decrypt(this.objective?.description);
    this.$nextTick(() => {
      this.fixDescriptionHeight();
      this.fixTitleHeight();
    });
    // @ts-ignore
    document.querySelector('#oDescription').addEventListener("paste", function (e) {
      e.preventDefault();
      // @ts-ignore
      let text = e.clipboardData.getData("text/plain");
      document.execCommand("insertHTML", false, text);
    });
  }

  load() {
    if (this.objective?.name) {
      this.objectiveNameInput = Encryption.decrypt(this.objective?.name);
    }
    if (this.objective?.description) {
      this.objectiveDescriptionInput = Encryption.decrypt(this.objective?.description);

      // @ts-ignore
      this.$refs.oDescription.innerHTML = this.objectiveDescriptionInput;
    }

    this.$forceUpdate();
  }

  undoChanges() {
    if (this.objective?.description) {
      // @ts-ignore
      this.$refs.oDescription.innerHTML = Encryption.decrypt(this.objective?.description);
    }

    if (this.objective?.name) {
      this.objectiveNameInput = Encryption.decrypt(this.objective?.name);
    }

    this.hasChangedFields = false;
    this.editDescriptionActive = false;
  }

  editDescription() {
    this.editDescriptionActive = true;
  }

  applyFormat(type: string) {
    // @ts-ignore
    document.execCommand(type);
    // @ts-ignore
    let e = document.createEvent('HTMLEvents');
    // @ts-ignore
    e.initEvent('input', true, true);
    // @ts-ignore
    this.$refs.oDescription.dispatchEvent(e);
  }

  updateObjective() {
    this.hasChangedFields = false;
    this.editDescriptionActive = false;
    //@ts-ignore
    this.objectiveDescriptionInput = this.$refs.oDescription.innerHTML;
    this.$apollo
      .mutate({
        mutation: gql`
                    mutation updateObjective($id: ID!, $name: String, $description: String) {
                        updateObjective(id: $id, input: { name: $name, description: $description }) {
                            ...ObjectiveDetailed
                        }
                    }
                    ${ObjectiveDetailedFragment}
                `,
        variables: {
          id: this.objective?.id,
          name: this.objectiveNameInput,
          description: this.objectiveDescriptionInput,
        },
        refetchQueries:[{query: ObjectivesQuery, variables:{ board_id: this.$store.state.board.id}}]
      }).then((response) => {
        if (response.data) {
          this.$events.fire('objective-updated', response.data.updateObjective);
          this.$emit('objective-updated', response.data.updateObjective) //@TODO need to check is this is working
        }
      }).finally(() => {
        this.$buefy.toast.open({
          message: 'Changes saved!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }

  fixDescriptionHeight() {
    let oDescription = document.querySelector('.o-description') as HTMLInputElement;

    if (!oDescription) {
      return;
    }

    oDescription.style.height = "";
    oDescription.style.height = oDescription.scrollHeight + "px";
  }

  fixTitleHeight() {
    let oTitle = document.querySelector('.o-title') as HTMLInputElement;
    oTitle.style.height = "";
    oTitle.style.height = oTitle.scrollHeight + "px";
  }

  beforeDestroy() {
    //@ts-ignore
    if (Encryption.decrypt(this.objective?.name) != this.objectiveNameInput || Encryption.decrypt(this.objective?.description) != this.$refs.oDescription.innerHTML ) {
      this.updateObjective();
    }

  }

  @Watch('objective')
  objectiveUpdated() {
    if (this.objective) {
      this.load();
    }
  }
}
