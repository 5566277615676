




























import { Component, Prop, Vue } from 'vue-property-decorator';
import { User } from '@/typescript/types';

@Component
export default class UsersSelector extends Vue {
  selected: Array<User> = [];
  available: Array<User> = [];

  emails: Array<string> = [];

  @Prop({ required: false, default: null })
  workspaceUsers!: Array<User>;

  @Prop({ required: false, default: false })
  allowNew!: boolean;

  @Prop({ required: false, default: false })
  open!: boolean;

  @Prop({ required: true })
  boardUsers!: Array<User>;

  @Prop({ required: false, default: 'Add workspace members to this room'})
  placeholder!: string;

  @Prop({ required: true })
  nomatch!: string;

  @Prop({required: false, default: true})
  isInputFocus!: boolean

  mounted() {
    this.available = this.availableUsers;
    if (this.isInputFocus) {
      //@ts-ignore ToDo: fix type issue
      this.$refs.selectorInput.focus();
    }

  }

  changed() {
    this.$emit('input', this.selected);
  }



  get availableUsers() {
    if(this.workspaceUsers){
      return this.workspaceUsers.filter(user1 => !this.boardUsers.some(user2 => user1.id === user2.id));
    }else{
      return this.boardUsers;
    }

  }

  checkIfOk() {
    if(this.available.length < 1) {
      return false;
    } else {
      return true;
    }
  }

  getFilteredAvailableUsers(text: string) {
    this.available = this.availableUsers;
    //@ts-ignore
    this.available = this.available.filter((option) => {
      return option.name
        .toString()
        .toLowerCase()
        .indexOf(text.toLowerCase()) >= 0
    })
  }
}
