export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string in ISO8601 format, e.g. `2019-12-16T15:13:37Z`. */
  DateTime: any;
  /** A time string in H:i:s format, e.g. `15:13:37`. */
  Time: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
};

export type AccountClaimInput = {
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type ActivateUserInObjectiveRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
};

export type Activity = Reactable & {
  __typename?: 'Activity';
  id: Scalars['ID'];
  log_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  properties?: Maybe<Scalars['String']>;
  causer?: Maybe<Causer>;
  subject?: Maybe<Subject>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount: Scalars['Int'];
  reactionSummaries?: Maybe<Array<ReactionSummary>>;
};

/** A paginated list of Activity items. */
export type ActivityPaginator = {
  __typename?: 'ActivityPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Activity items. */
  data: Array<Activity>;
};

export type AddUserStatusInput = {
  emoji?: Maybe<Scalars['String']>;
  status_text?: Maybe<Scalars['String']>;
  tenure?: Maybe<Scalars['String']>;
  start_at?: Maybe<Scalars['DateTime']>;
  end_at?: Maybe<Scalars['DateTime']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  id?: Maybe<Scalars['ID']>;
  file_name?: Maybe<Scalars['String']>;
  file_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  owner?: Maybe<User>;
  board?: Maybe<Board>;
  objectives?: Maybe<Array<Maybe<Objective>>>;
};

/** A paginated list of Attachment items. */
export type AttachmentPaginator = {
  __typename?: 'AttachmentPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Attachment items. */
  data: Array<Attachment>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

/**
 * These are images that can be used to customize boards or objectives background.
 * Use Unsplash API (https://unsplash.com/developers) to get images.
 *
 * If it's messy, we can manually upload background images to the database.
 */
export type BackgroundImage = {
  __typename?: 'BackgroundImage';
  id: Scalars['ID'];
  image_url?: Maybe<Scalars['String']>;
};

export type BillingDetail = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  email: Scalars['String'];
  account_type?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};

export type Board = {
  __typename?: 'Board';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  /** Depending on the chosen method, we display different types of guides within the app */
  goal_method?: Maybe<GoalMethod>;
  /** Defines who's the owner of the board - often times the team-leader or manager */
  owner?: Maybe<Array<User>>;
  /** Show board without being logged in */
  public: Scalars['Boolean'];
  /** Background Color */
  background_color?: Maybe<Scalars['String']>;
  /** Stats for board */
  metrics: BoardMetrics;
  milestones?: Maybe<Array<Milestone>>;
  /** Members who belong to board */
  my_role?: Maybe<Role>;
  /** Members who belong to board */
  members?: Maybe<Array<BoardMember>>;
  /** Current active sprint of board */
  active_sprint: Sprint;
  /** Sets the interval of days for how long each sprint should be */
  sprint_interval: Scalars['Int'];
  /** Defines the workspace that owns this board, null if template */
  workspace?: Maybe<Workspace>;
  /** Set board level */
  boardLevel?: Maybe<BoardLevel>;
  /** Defines who can load the board */
  permissions?: Maybe<Scalars['String']>;
  /** Boards can be archived, and restored from the list of archived boards */
  deleted_at?: Maybe<Scalars['DateTime']>;
  /** Description of the board - example: This is where we create objectives for the sales activities this year  */
  description?: Maybe<Scalars['String']>;
  /** Name of the board - example: Sales objectives 2020 */
  name: Scalars['String'];
  /** You can mark a board as starred */
  starred: Scalars['Boolean'];
  /** If created from template board */
  board_template?: Maybe<BoardTemplate>;
  /** Check if slack is connected */
  slackConnected: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  northStars?: Maybe<Array<NorthStar>>;
  objectives?: Maybe<Array<Objective>>;
  bookmarks?: Maybe<Array<Bookmark>>;
  tags?: Maybe<Array<CustomTag>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  backgroundImage?: Maybe<BoardBackground>;
  ongoingObjectives?: Maybe<Array<Maybe<Objective>>>;
};

export type BoardBackground = {
  __typename?: 'BoardBackground';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type BoardBoardMessageRelation = {
  connect: Scalars['ID'];
};

export type BoardFromTemplate = {
  template_id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Scalars['String']>;
  workspace: BoardWorkspaceRelation;
  copyall?: Maybe<Scalars['Boolean']>;
};

export type BoardLevel = {
  __typename?: 'BoardLevel';
  name: Scalars['String'];
  identifier: Scalars['Int'];
  is_default: Scalars['Boolean'];
  is_enabledt: Scalars['Boolean'];
};

export type BoardLiveMembers = {
  __typename?: 'BoardLiveMembers';
  boardId?: Maybe<Scalars['ID']>;
  members?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type BoardMember = {
  __typename?: 'BoardMember';
  id: Scalars['ID'];
  user: User;
  board: Board;
  role: Role;
  last_seen_board_message_id?: Maybe<Scalars['ID']>;
};

/** Every board has a main chat where users can post comments, and Pembio can send system messages to. */
export type BoardMessage = Reactable & {
  __typename?: 'BoardMessage';
  id: Scalars['ID'];
  board: Board;
  sender: User;
  edited: Scalars['Boolean'];
  message: Scalars['String'];
  messageAsHtml: Scalars['String'];
  messageFormatted: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount: Scalars['Int'];
  reactionSummaries?: Maybe<Array<ReactionSummary>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  pinnedUsers?: Maybe<Array<Maybe<User>>>;
};

/** A paginated list of BoardMessage items. */
export type BoardMessagePaginator = {
  __typename?: 'BoardMessagePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of BoardMessage items. */
  data: Array<BoardMessage>;
};

export type BoardMetrics = {
  __typename?: 'BoardMetrics';
  objectivesCount: Scalars['Int'];
  keyResultsCount: Scalars['Int'];
  keyResultsCompletedCount: Scalars['Int'];
  keyResultsOverallProgress?: Maybe<Scalars['Float']>;
};

export type BoardNotification = {
  __typename?: 'BoardNotification';
  id: Scalars['ID'];
  user: User;
  board: Board;
  data: BoardNotificationData;
  is_read: Scalars['Boolean'];
};

export type BoardNotificationData = {
  __typename?: 'BoardNotificationData';
  message: Scalars['String'];
};

/** A paginated list of BoardNotification items. */
export type BoardNotificationPaginator = {
  __typename?: 'BoardNotificationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of BoardNotification items. */
  data: Array<BoardNotification>;
};

export type BoardObjectiveRelation = {
  connect: Scalars['ID'];
};

/** A paginated list of Board items. */
export type BoardPaginator = {
  __typename?: 'BoardPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Board items. */
  data: Array<Board>;
};

export type BoardTemplate = {
  __typename?: 'BoardTemplate';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  /** Depending on the chosen method, we display different types of guides within the app */
  goal_method?: Maybe<GoalMethod>;
  /** Defines who's the owner of the board - often times the team-leader or manager */
  owner?: Maybe<Array<User>>;
  /** Show board without being logged in */
  public: Scalars['Boolean'];
  /** Stats for board */
  metrics: BoardMetrics;
  milestones?: Maybe<Array<Milestone>>;
  /** Members who belong to board */
  members?: Maybe<Array<Maybe<BoardMember>>>;
  /** Current active sprint of board */
  active_sprint: Sprint;
  /** Sets the interval of days for how long each sprint should be */
  sprint_interval: Scalars['Int'];
  /** Defines the workspace that owns this board, null if template */
  workspace?: Maybe<Workspace>;
  /** Set board level */
  boardLevel?: Maybe<BoardLevel>;
  /** Defines who can load the board */
  permissions?: Maybe<Scalars['String']>;
  /** Boards can be archived, and restored from the list of archived boards */
  deleted_at?: Maybe<Scalars['DateTime']>;
  /** Description of the board - example: This is where we create objectives for the sales activities this year  */
  description?: Maybe<Scalars['String']>;
  /** Name of the board - example: Sales objectives 2020 */
  name: Scalars['String'];
  /** If board is template */
  template?: Maybe<Scalars['Int']>;
  /** Template Details */
  templateDetails?: Maybe<BoardTemplateDetails>;
  slackConnected: Scalars['Boolean'];
  northStars?: Maybe<Array<NorthStar>>;
  objectives?: Maybe<Array<Objective>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  bookmarks?: Maybe<Array<Bookmark>>;
};

export type BoardTemplateDetails = {
  __typename?: 'BoardTemplateDetails';
  id: Scalars['ID'];
  template_id: Scalars['ID'];
  short_description: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Template type */
  template_type: BoardTemplateTypes;
  /** Template Category */
  template_category: TemplateCategory;
  author_name?: Maybe<Scalars['String']>;
  author_description?: Maybe<Scalars['String']>;
  author_link?: Maybe<Scalars['String']>;
  author_role?: Maybe<Scalars['String']>;
  author_company?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
};

export enum BoardTemplateTypes {
  Corporate = 'corporate',
  Personal = 'personal'
}

export type BoardWorkspaceRelation = {
  connect: Scalars['ID'];
};

export type Bookmark = {
  __typename?: 'Bookmark';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  favicon: Scalars['String'];
  creator: User;
  bookmark_id: Scalars['ID'];
  created_at: Scalars['DateTime'];
};

export type Card = {
  __typename?: 'Card';
  brand: Scalars['String'];
  last_4: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  fingerprint: Scalars['String'];
};

export type CardData = {
  __typename?: 'CardData';
  id: Scalars['String'];
  card: Card;
  is_default: Scalars['Boolean'];
};

export type Causer = User | Board | Objective | KeyResult;

export type ConnectBoardLevel = {
  identifier?: Maybe<Scalars['Int']>;
};

export type ConnectObjectiveTypeRelation = {
  connect: Scalars['ID'];
};

export type ConnectOwnerRelation = {
  connect: Scalars['ID'];
};

export type ConnectReactionReactableInput = {
  type: Scalars['String'];
  id: Scalars['ID'];
};

export type ConnectRemindableMorphTo = {
  connect: ConnectReminderRemindableInput;
};

export type ConnectReminderRemindableInput = {
  id: Scalars['ID'];
  type: RemindableType;
};

export type Coupon = {
  __typename?: 'Coupon';
  id: Scalars['String'];
  object: Scalars['String'];
  amount_off?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['String']>;
  duration_in_months?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  percent_off?: Maybe<Scalars['Float']>;
  redeem_by?: Maybe<Scalars['DateTime']>;
  times_redeemed?: Maybe<Scalars['Int']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type CreateAttachments = {
  workspace_id: Scalars['ID'];
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateBoardAttachments = {
  board_id: Scalars['ID'];
  workspace_id: Scalars['ID'];
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateBoardInput = {
  workspace: BoardWorkspaceRelation;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  goal_method?: Maybe<GoalMethodBoardRelation>;
  permissions?: Maybe<Scalars['String']>;
  sprint_interval?: Maybe<Scalars['Int']>;
  boardLevel?: Maybe<ConnectBoardLevel>;
  board_background_id?: Maybe<Scalars['ID']>;
};

export type CreateBoardMessageInput = {
  board: BoardBoardMessageRelation;
  message?: Maybe<Scalars['String']>;
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type CreateBookmarkInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  bookmark_level: Scalars['Int'];
  bookmark_type_id: Scalars['ID'];
};

export type CreateCustonTagInput = {
  name: Scalars['String'];
  board_id: Scalars['ID'];
  color?: Maybe<Scalars['String']>;
  objective_id?: Maybe<Scalars['ID']>;
};

export type CreateFeedbackInput = {
  title: Scalars['String'];
  description: Scalars['String'];
  workspace_id: Scalars['ID'];
  creator_id: Scalars['ID'];
  feedback_type_id: Scalars['ID'];
};

export type CreateInitiativeInput = {
  assignee?: Maybe<InitiativeAssigneeRelation>;
  objective: InitiativeObjectiveRelation;
  description: Scalars['String'];
  priority?: Maybe<InitiativePriority>;
  status?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
};

export type CreateKeyResultInput = {
  assignee?: Maybe<KeyResultAssigneeRelation>;
  objective: KeyResultObjectiveRelation;
  description: Scalars['String'];
  priority?: Maybe<KeyResultPriority>;
  due_at?: Maybe<Scalars['DateTime']>;
  start_value?: Maybe<Scalars['Int']>;
  target_value?: Maybe<Scalars['Int']>;
  current_value?: Maybe<Scalars['Int']>;
  increment_value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
};

export type CreateMilestoneInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  north_star: MilestoneNorthStarRelation;
  reward?: Maybe<Scalars['String']>;
  status: MilestoneStatus;
  due_date?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
};

export type CreateNorthStarInput = {
  workspace: NorthStarWorkspaceRelation;
  boards?: Maybe<NorthStarBoardRelation>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  is_public?: Maybe<Scalars['Boolean']>;
};

export type CreateObjectiveCommentInput = {
  objective: ObjectiveObjectiveCommentRelation;
  comment: Scalars['String'];
};

export type CreateObjectiveInput = {
  owner: ConnectOwnerRelation;
  board: BoardObjectiveRelation;
  background_color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  objective_type?: Maybe<ConnectObjectiveTypeRelation>;
  priority?: Maybe<ObjectivePriority>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
};

export type CreateObjectiveRecurrenceInput = {
  interval: RecurrenceInterval;
  /**
   * day - null for daily, int week number (ISO-8601 - 1 for Monday through 7 for
   * Sunday) for weekly, int date (1-31) for monthly
   */
  day?: Maybe<Scalars['Int']>;
  /** time in H:i:s format */
  time?: Maybe<Scalars['Time']>;
  start_date: Scalars['Date'];
  /** Y-m-d date */
  end_date?: Maybe<Scalars['Date']>;
  /** Y-m-d date */
  objective: ObjectiveRecurrenceObjectiveRelation;
  /** Comma seperated int week number (ISO-8601 - 1 for Monday through 7 for Sunday) */
  exception?: Maybe<Array<Scalars['Int']>>;
};

export type CreateReactableMorphTo = {
  connect: ConnectReactionReactableInput;
};

export type CreateReactionInput = {
  reactable: CreateReactableMorphTo;
  reaction: ReactionType;
};

export type CreateReflectionInput = {
  user_id: Scalars['ID'];
  sprint_id: Scalars['ID'];
  reflection?: Maybe<SprintReflectionObjectInput>;
};

export type CreateReminderInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date_time: Scalars['DateTime'];
  notification_type?: Maybe<NotificationType>;
  remindable?: Maybe<ConnectRemindableMorphTo>;
  users: Array<Scalars['ID']>;
};

export type CreateThirdPartyObjectivesInput = {
  board: Scalars['ID'];
  background_color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  priority?: Maybe<ObjectivePriority>;
  third_party_provider_id: Scalars['ID'];
  provider_card_id: Scalars['ID'];
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
};

export type CreateWorkspaceInput = {
  name: Scalars['String'];
  domain: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  referral_code?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
};

export type CreateWorkspaceInviteInput = {
  invitee_email: Scalars['String'];
  workspace: WorkspaceInviteWorkspaceRelation;
  role?: Maybe<Role>;
  boards: WorkspaceInviteBoardsRelation;
};

export type CustomTag = {
  __typename?: 'CustomTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
  board: Board;
};



export type EmailUpdateResponse = {
  __typename?: 'EmailUpdateResponse';
  id: Scalars['ID'];
  user_id: Scalars['ID'];
  verification_pin: Scalars['String'];
  email: Scalars['String'];
};

export type FakeRegisterInput = {
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  additional_registration_info?: Maybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  workspace: Workspace;
  creator: User;
  feedbackType: FeedbackType;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type FeedbackType = {
  __typename?: 'FeedbackType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type Goal = {
  __typename?: 'Goal';
  assignee: User;
  board: Board;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  due_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  priority: GoalPriority;
  status: GoalStatus;
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type GoalMethod = {
  __typename?: 'GoalMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  created_at: Scalars['DateTime'];
};

export type GoalMethodBoardRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export enum GoalPriority {
  Urgent = 'urgent',
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

export type GoalStatus = {
  __typename?: 'GoalStatus';
  color: Scalars['String'];
  created_at: Scalars['DateTime'];
  group: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type HaveWorkspace = {
  __typename?: 'HaveWorkspace';
  present: Scalars['Boolean'];
};

export type IncrementKeyResultInput = {
  key_result_id: Scalars['ID'];
  value?: Maybe<Scalars['Int']>;
};

export type Initiative = {
  __typename?: 'Initiative';
  id: Scalars['ID'];
  assignee?: Maybe<User>;
  creator: User;
  objective: Objective;
  completed_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  start_date?: Maybe<Scalars['DateTime']>;
  due_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  priority: InitiativePriority;
};

export type InitiativeAssigneeRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type InitiativeObjectiveRelation = {
  connect: Scalars['ID'];
};

export enum InitiativePriority {
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

export type Intent = {
  __typename?: 'Intent';
  id: Scalars['String'];
  client_secret: Scalars['String'];
};

export type InvoiceData = {
  __typename?: 'InvoiceData';
  id: Scalars['String'];
  amount: Scalars['Float'];
  status: Scalars['String'];
  hosted_invoice_url?: Maybe<Scalars['String']>;
  invoice_pdf?: Maybe<Scalars['String']>;
  period_end?: Maybe<Scalars['DateTime']>;
  period_start?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
};

export type Invoices = {
  __typename?: 'Invoices';
  has_more?: Maybe<Scalars['Boolean']>;
  data?: Maybe<Array<Maybe<InvoiceData>>>;
};

/**
 * This is a list of tasks that's required for the objective to be completed. Each
 * key result is a checkable to-do item that can be assigned an owner and a due date.
 *
 * Key results are responsible for giving the objective a progress completion rate.
 * If 2/5 keyresults in an objective is marked as done, we display 40% completion
 * on the objective.
 */
export type KeyResult = {
  __typename?: 'KeyResult';
  id: Scalars['ID'];
  assignee?: Maybe<User>;
  creator: User;
  objective: Objective;
  completed: Scalars['Boolean'];
  completed_at?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  due_at?: Maybe<Scalars['DateTime']>;
  priority: KeyResultPriority;
  start_value: Scalars['Int'];
  target_value: Scalars['Int'];
  current_value: Scalars['Int'];
  increment_value: Scalars['Int'];
  unit: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
};

export type KeyResultAssigneeRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type KeyResultObjectiveRelation = {
  connect: Scalars['ID'];
};

/** A paginated list of KeyResult items. */
export type KeyResultPaginator = {
  __typename?: 'KeyResultPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of KeyResult items. */
  data: Array<KeyResult>;
};

export enum KeyResultPriority {
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

export type LinkPreview = {
  __typename?: 'LinkPreview';
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** Milestones linked to North Stars */
export type Milestone = {
  __typename?: 'Milestone';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Defines the purpose of the milestone */
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  /** North star to which milestone is added to */
  north_star: NorthStar;
  /** Defines who's the creator of the milestone */
  creator: User;
  reward?: Maybe<Scalars['String']>;
  status: MilestoneStatus;
  created_at: Scalars['DateTime'];
  due_date?: Maybe<Scalars['DateTime']>;
  boards?: Maybe<Array<Board>>;
};

export type MilestoneNorthStarRelation = {
  connect: Scalars['ID'];
};

/** A paginated list of Milestone items. */
export type MilestonePaginator = {
  __typename?: 'MilestonePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Milestone items. */
  data: Array<Milestone>;
};

export enum MilestoneStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started'
}

export type Mutation = {
  __typename?: 'Mutation';
  createBoard?: Maybe<Board>;
  /**  Uses extended php mutation  */
  updateBoard?: Maybe<Board>;
  /**  Uses the graphql mutation  */
  updateBoardGl?: Maybe<Board>;
  deleteBoard?: Maybe<Board>;
  addUserToBoard?: Maybe<BoardMember>;
  joinBoard?: Maybe<Board>;
  leaveBoard?: Maybe<Board>;
  removeUserFromBoard?: Maybe<BoardMember>;
  setBoardRole?: Maybe<BoardMember>;
  connectSlack: Board;
  connectSlackRemove: Board;
  createSprintIfNotExists: Sprint;
  createBoardFromTemplate?: Maybe<Board>;
  restoreBoard?: Maybe<Board>;
  addBoardAttachments?: Maybe<Array<Maybe<Attachment>>>;
  deleteBoardHard?: Maybe<Board>;
  updateLastSeenMessage?: Maybe<BoardMessage>;
  createPersonalWorkspaceTemplate?: Maybe<Board>;
  createBoardMessage?: Maybe<BoardMessage>;
  deleteBoardMessage?: Maybe<BoardMessage>;
  updateBoardMessage?: Maybe<BoardMessage>;
  pinMessage?: Maybe<User>;
  unPinMessage?: Maybe<User>;
  readBoardNotification: BoardNotification;
  readAllBoardNotifications: Scalars['Boolean'];
  deleteInitiative?: Maybe<Initiative>;
  createInitiative?: Maybe<Initiative>;
  updateInitiative?: Maybe<Initiative>;
  createObjective?: Maybe<Objective>;
  deleteObjective?: Maybe<Objective>;
  updateObjective?: Maybe<Objective>;
  assignUsers?: Maybe<Array<User>>;
  assignCustomTags?: Maybe<Array<CustomTag>>;
  createThirdPartyObjective?: Maybe<Array<Maybe<Objective>>>;
  createObjectiveAttachments?: Maybe<Array<Maybe<Attachment>>>;
  deleteObjectiveAttachment?: Maybe<Attachment>;
  duplicateObjective?: Maybe<Objective>;
  removeNorthstarFromObjective?: Maybe<Objective>;
  updateObjectivesOrder?: Maybe<Scalars['Boolean']>;
  createObjectiveComment: ObjectiveComment;
  deleteObjectiveComment?: Maybe<ObjectiveComment>;
  deleteKeyResult?: Maybe<KeyResult>;
  createKeyResult?: Maybe<KeyResult>;
  incrementKeyResult: KeyResult;
  updateKeyResult?: Maybe<KeyResult>;
  createMilestone: Milestone;
  updateMilestone: Milestone;
  deleteMilestone?: Maybe<Milestone>;
  addBoardToMilestone: Milestone;
  removeBoardFromMilestone: Milestone;
  updateUser: User;
  updateUserSettings?: Maybe<UserSettings>;
  markNotificationAsRead?: Maybe<Scalars['Boolean']>;
  markNotificationAsUnread?: Maybe<Scalars['Boolean']>;
  markAllNotificationsAsRead?: Maybe<Scalars['Boolean']>;
  markAllNotificationsAsUnread?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Scalars['Boolean']>;
  deleteAllNotifications?: Maybe<Scalars['Boolean']>;
  resendEmailVerification?: Maybe<Scalars['Boolean']>;
  addNewReferralCode: User;
  selfDelete?: Maybe<Scalars['Boolean']>;
  setUserTimeZone: User;
  verifyAlternatively?: Maybe<Scalars['Boolean']>;
  updateUserAccountType: User;
  getAllPaymentMethods?: Maybe<Array<Maybe<CardData>>>;
  setPaymentMethodPrimary?: Maybe<Array<Maybe<CardData>>>;
  removePaymentMethod: Scalars['Boolean'];
  addNewCard?: Maybe<Array<Maybe<CardData>>>;
  addRemoveThumbsUpCount?: Maybe<Scalars['Boolean']>;
  react: Reactable;
  removeReact?: Maybe<Reaction>;
  createReminder: Reminder;
  addUserToReminder: Reminder;
  removeUserFromReminder: Reminder;
  updateReminder: Reminder;
  deleteReminder: Scalars['Boolean'];
  createWorkspace?: Maybe<Workspace>;
  deleteWorkspace?: Maybe<Workspace>;
  updateWorkspace: Workspace;
  removeUserFromWorkspace?: Maybe<WorkspaceMember>;
  setWorkspaceRole?: Maybe<WorkspaceMember>;
  transferPrimaryOwner?: Maybe<WorkspaceMember>;
  addWorkspaceAttachments?: Maybe<Array<Maybe<Attachment>>>;
  updateWorkspaceBoards?: Maybe<Workspace>;
  setWorkspaceAccess?: Maybe<WorkspaceMember>;
  deleteWorkspaceAvatar?: Maybe<Workspace>;
  rejectWorkspaceInvite: WorkspaceInvite;
  createWorkspaceInvite: WorkspaceInvite;
  acceptWorkspaceInvite?: Maybe<WorkspaceMember>;
  deleteWorkspaceInvite: WorkspaceInvite;
  closeSprint: Sprint;
  updateSprint?: Maybe<Sprint>;
  createReflection: SprintReflection;
  updateReflection: SprintReflection;
  createNorthStar?: Maybe<NorthStar>;
  deleteNorthStar?: Maybe<NorthStar>;
  updateNorthStar?: Maybe<NorthStar>;
  copyNorthStar?: Maybe<NorthStar>;
  copyObjectiveForNorthstar?: Maybe<Objective>;
  copyMilestoneForNorthstar?: Maybe<Milestone>;
  updateNorthstarOrder?: Maybe<Scalars['Boolean']>;
  createOnboardingNorthstars?: Maybe<Array<Maybe<NorthStar>>>;
  createObjectiveRecurrence?: Maybe<ObjectiveRecurrence>;
  deleteObjectiveRecurrence?: Maybe<ObjectiveRecurrence>;
  updateObjectiveRecurrence?: Maybe<ObjectiveRecurrence>;
  setMyStatus?: Maybe<UserStatus>;
  deleteStatus?: Maybe<UserStatus>;
  createFeedback?: Maybe<Feedback>;
  createBookmark: Bookmark;
  updateBookmark: Bookmark;
  deleteBookmark?: Maybe<Bookmark>;
  copyBookmark?: Maybe<Bookmark>;
  createCustomTag: CustomTag;
  updateCustomTag: CustomTag;
  deleteCustomTag: CustomTag;
  thirdPartyAuth?: Maybe<ThirdPartyAuthPayload>;
  deleteAttachments?: Maybe<Attachment>;
  copyAttachment?: Maybe<Attachment>;
  readAllWorkspaceNotificationsForBoard: Scalars['Boolean'];
  createPaymentIntent?: Maybe<Intent>;
  createSetupIntent?: Maybe<Intent>;
  makeSubscription?: Maybe<WorkspaceSubscription>;
  updateSubscription?: Maybe<WorkspaceSubscription>;
  checkPromotionCode?: Maybe<PromotionCode>;
  cancelSubscription?: Maybe<WorkspaceSubscription>;
  addBillingDetails?: Maybe<UserBillingDetail>;
  updateBillingDetails?: Maybe<UserBillingDetail>;
  removeBillingDetails?: Maybe<UserBillingDetail>;
  addUserTaxDetail?: Maybe<UserTaxDetail>;
  updateUserTaxDetail?: Maybe<UserTaxDetail>;
  removeUserTaxDetail?: Maybe<UserTaxDetail>;
  removeUnseenBoardMessage?: Maybe<Scalars['Boolean']>;
  login: AuthPayload;
  forgotPassword: ForgotPasswordResponse;
  updateForgottenPassword: ForgotPasswordResponse;
  register: RegisterAuthPayload;
  socialLogin?: Maybe<AuthPayload>;
  getAccessTokenWithEmail?: Maybe<RegisterAuthPayload>;
  registerFakeUser?: Maybe<RegisterAuthPayload>;
  refreshToken: RefreshTokenPayload;
  logout: LogoutResponse;
  verifyEmailWithPin: User;
  setInitialPassword: Scalars['Boolean'];
  updateEmail: EmailUpdateResponse;
  verifyPinAndUpdateEmail: User;
  claimAccount?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateBoardArgs = {
  input: CreateBoardInput;
};


export type MutationUpdateBoardArgs = {
  id: Scalars['ID'];
  data: UpdateBoardInput;
};


export type MutationUpdateBoardGlArgs = {
  id: Scalars['ID'];
  data: UpdateBoardInput;
};


export type MutationDeleteBoardArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserToBoardArgs = {
  user_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationJoinBoardArgs = {
  id: Scalars['ID'];
};


export type MutationLeaveBoardArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUserFromBoardArgs = {
  user_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationSetBoardRoleArgs = {
  board_id: Scalars['ID'];
  user_id: Scalars['ID'];
  role?: Maybe<Role>;
};


export type MutationConnectSlackArgs = {
  code: Scalars['String'];
  board_id: Scalars['ID'];
};


export type MutationConnectSlackRemoveArgs = {
  board_id: Scalars['ID'];
};


export type MutationCreateSprintIfNotExistsArgs = {
  board_id: Scalars['ID'];
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationCreateBoardFromTemplateArgs = {
  input: BoardFromTemplate;
};


export type MutationRestoreBoardArgs = {
  id: Scalars['ID'];
};


export type MutationAddBoardAttachmentsArgs = {
  input: CreateBoardAttachments;
};


export type MutationDeleteBoardHardArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateLastSeenMessageArgs = {
  user_id: Scalars['ID'];
  board_id: Scalars['ID'];
  chat_message_id: Scalars['ID'];
};


export type MutationCreatePersonalWorkspaceTemplateArgs = {
  board_id: Scalars['ID'];
};


export type MutationCreateBoardMessageArgs = {
  input: CreateBoardMessageInput;
};


export type MutationDeleteBoardMessageArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateBoardMessageArgs = {
  id: Scalars['ID'];
  input: UpdateBoardMessageInput;
};


export type MutationPinMessageArgs = {
  id: Scalars['ID'];
};


export type MutationUnPinMessageArgs = {
  id: Scalars['ID'];
};


export type MutationReadBoardNotificationArgs = {
  notification_id: Scalars['ID'];
};


export type MutationReadAllBoardNotificationsArgs = {
  board_id: Scalars['ID'];
};


export type MutationDeleteInitiativeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateInitiativeArgs = {
  input: CreateInitiativeInput;
};


export type MutationUpdateInitiativeArgs = {
  id: Scalars['ID'];
  input: UpdateInitiativeInput;
};


export type MutationCreateObjectiveArgs = {
  input: CreateObjectiveInput;
};


export type MutationDeleteObjectiveArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateObjectiveArgs = {
  id: Scalars['ID'];
  input: UpdateObjectiveInput;
};


export type MutationAssignUsersArgs = {
  input: ObjectiveUserRelation;
};


export type MutationAssignCustomTagsArgs = {
  input: ObjectiveCustomTagRelation;
};


export type MutationCreateThirdPartyObjectiveArgs = {
  input: Array<Maybe<CreateThirdPartyObjectivesInput>>;
};


export type MutationCreateObjectiveAttachmentsArgs = {
  input: ObjectiveAttachmentRelation;
};


export type MutationDeleteObjectiveAttachmentArgs = {
  objective_id: Scalars['ID'];
  attachment_id: Scalars['ID'];
};


export type MutationDuplicateObjectiveArgs = {
  objective_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationRemoveNorthstarFromObjectiveArgs = {
  objective_id: Scalars['ID'];
};


export type MutationUpdateObjectivesOrderArgs = {
  input: ObjectivesOrderInput;
};


export type MutationCreateObjectiveCommentArgs = {
  input: CreateObjectiveCommentInput;
};


export type MutationDeleteObjectiveCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteKeyResultArgs = {
  id: Scalars['ID'];
};


export type MutationCreateKeyResultArgs = {
  input: CreateKeyResultInput;
};


export type MutationIncrementKeyResultArgs = {
  input: IncrementKeyResultInput;
};


export type MutationUpdateKeyResultArgs = {
  id: Scalars['ID'];
  input: UpdateKeyResultInput;
};


export type MutationCreateMilestoneArgs = {
  input?: Maybe<CreateMilestoneInput>;
};


export type MutationUpdateMilestoneArgs = {
  id: Scalars['ID'];
  input?: Maybe<UpdateMilestoneInput>;
};


export type MutationDeleteMilestoneArgs = {
  id: Scalars['ID'];
};


export type MutationAddBoardToMilestoneArgs = {
  board_id: Scalars['ID'];
  milestone_id: Scalars['ID'];
};


export type MutationRemoveBoardFromMilestoneArgs = {
  board_id: Scalars['ID'];
  milestone_id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};


export type MutationMarkNotificationAsReadArgs = {
  id: Scalars['ID'];
};


export type MutationMarkNotificationAsUnreadArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};


export type MutationSetUserTimeZoneArgs = {
  ipAddress: Scalars['String'];
};


export type MutationVerifyAlternativelyArgs = {
  password: Scalars['String'];
};


export type MutationUpdateUserAccountTypeArgs = {
  account_type?: Maybe<Scalars['String']>;
};


export type MutationGetAllPaymentMethodsArgs = {
  stripe_customer_id: Scalars['String'];
};


export type MutationSetPaymentMethodPrimaryArgs = {
  stripe_customer_id: Scalars['String'];
  paymentMethod: Scalars['String'];
};


export type MutationRemovePaymentMethodArgs = {
  paymentMethod: Scalars['String'];
};


export type MutationAddNewCardArgs = {
  stripe_customer_id: Scalars['String'];
  paymentMethod: Scalars['String'];
};


export type MutationAddRemoveThumbsUpCountArgs = {
  type: Scalars['String'];
};


export type MutationReactArgs = {
  input: CreateReactionInput;
};


export type MutationRemoveReactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateReminderArgs = {
  input: CreateReminderInput;
};


export type MutationAddUserToReminderArgs = {
  user_id: Scalars['ID'];
  reminder_id: Scalars['ID'];
};


export type MutationRemoveUserFromReminderArgs = {
  user_id: Scalars['ID'];
  reminder_id: Scalars['ID'];
};


export type MutationUpdateReminderArgs = {
  id: Scalars['ID'];
  input: UpdateReminderInput;
};


export type MutationDeleteReminderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};


export type MutationDeleteWorkspaceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateWorkspaceArgs = {
  id: Scalars['ID'];
  input: UpdateWorkspaceInput;
};


export type MutationRemoveUserFromWorkspaceArgs = {
  user_id: Scalars['ID'];
  workspace_id: Scalars['ID'];
};


export type MutationSetWorkspaceRoleArgs = {
  workspace_id: Scalars['ID'];
  user_id: Scalars['ID'];
  role: Role;
};


export type MutationTransferPrimaryOwnerArgs = {
  workspace_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationAddWorkspaceAttachmentsArgs = {
  input: CreateAttachments;
};


export type MutationUpdateWorkspaceBoardsArgs = {
  id: Scalars['ID'];
  data?: Maybe<Array<UpdateBoardBulkInput>>;
};


export type MutationSetWorkspaceAccessArgs = {
  workspace_id: Scalars['ID'];
  user_id: Scalars['ID'];
  access_workspace: Scalars['String'];
};


export type MutationDeleteWorkspaceAvatarArgs = {
  workspace_id: Scalars['ID'];
};


export type MutationRejectWorkspaceInviteArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWorkspaceInviteArgs = {
  input: CreateWorkspaceInviteInput;
};


export type MutationAcceptWorkspaceInviteArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkspaceInviteArgs = {
  id: Scalars['ID'];
};


export type MutationCloseSprintArgs = {
  sprint_id: Scalars['ID'];
};


export type MutationUpdateSprintArgs = {
  id: Scalars['ID'];
  input: UpdateSprintInput;
};


export type MutationCreateReflectionArgs = {
  input: CreateReflectionInput;
};


export type MutationUpdateReflectionArgs = {
  id: Scalars['ID'];
  input: UpdateReflectionInput;
};


export type MutationCreateNorthStarArgs = {
  input: CreateNorthStarInput;
};


export type MutationDeleteNorthStarArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateNorthStarArgs = {
  id: Scalars['ID'];
  input: UpdateNorthStarInput;
};


export type MutationCopyNorthStarArgs = {
  id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  workspace_id: Scalars['ID'];
  forCopyObj?: Maybe<Scalars['String']>;
};


export type MutationCopyObjectiveForNorthstarArgs = {
  northstar_id: Scalars['ID'];
  objective_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationCopyMilestoneForNorthstarArgs = {
  northstar_id: Scalars['ID'];
  milestone_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationUpdateNorthstarOrderArgs = {
  input: NorthStarOrderInput;
};


export type MutationCreateOnboardingNorthstarsArgs = {
  input: OnboardingNorthstarInput;
};


export type MutationCreateObjectiveRecurrenceArgs = {
  input: CreateObjectiveRecurrenceInput;
};


export type MutationDeleteObjectiveRecurrenceArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateObjectiveRecurrenceArgs = {
  id: Scalars['ID'];
  input: UpdateObjectiveRecurrenceInput;
};


export type MutationSetMyStatusArgs = {
  input: AddUserStatusInput;
};


export type MutationDeleteStatusArgs = {
  id: Scalars['ID'];
};


export type MutationCreateFeedbackArgs = {
  input: CreateFeedbackInput;
};


export type MutationCreateBookmarkArgs = {
  input: CreateBookmarkInput;
};


export type MutationUpdateBookmarkArgs = {
  id: Scalars['ID'];
  input: UpdateBookmarkInput;
};


export type MutationDeleteBookmarkArgs = {
  id: Scalars['ID'];
};


export type MutationCopyBookmarkArgs = {
  bookmark_id: Scalars['ID'];
  board_id: Scalars['ID'];
};


export type MutationCreateCustomTagArgs = {
  input: CreateCustonTagInput;
};


export type MutationUpdateCustomTagArgs = {
  id: Scalars['ID'];
  input: UpdateCustomTagInput;
};


export type MutationDeleteCustomTagArgs = {
  id: Scalars['ID'];
};


export type MutationThirdPartyAuthArgs = {
  input: ThirdPartyAuthInput;
};


export type MutationDeleteAttachmentsArgs = {
  attachmentId: Scalars['ID'];
};


export type MutationCopyAttachmentArgs = {
  attachment_id: Scalars['ID'];
  board_id: Scalars['ID'];
  workspace_id: Scalars['ID'];
};


export type MutationReadAllWorkspaceNotificationsForBoardArgs = {
  board_id: Scalars['ID'];
};


export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int'];
};


export type MutationMakeSubscriptionArgs = {
  workspace_id: Scalars['ID'];
  paymentMethod: Scalars['String'];
  stripe_plan_id: Scalars['String'];
  itemQuantity: Scalars['Int'];
  coupon?: Maybe<Scalars['String']>;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscription;
};


export type MutationCheckPromotionCodeArgs = {
  code: Scalars['String'];
};


export type MutationCancelSubscriptionArgs = {
  subscription_id: Scalars['String'];
  workspace_id: Scalars['ID'];
};


export type MutationAddBillingDetailsArgs = {
  input?: Maybe<BillingDetail>;
};


export type MutationUpdateBillingDetailsArgs = {
  id: Scalars['ID'];
  input: BillingDetail;
};


export type MutationRemoveBillingDetailsArgs = {
  id: Scalars['ID'];
};


export type MutationAddUserTaxDetailArgs = {
  tax_type: Scalars['String'];
  tax_id: Scalars['String'];
};


export type MutationUpdateUserTaxDetailArgs = {
  id: Scalars['ID'];
  tax_type: Scalars['String'];
  tax_id: Scalars['String'];
};


export type MutationRemoveUserTaxDetailArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveUnseenBoardMessageArgs = {
  board_id: Scalars['ID'];
  user_id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: Maybe<NewPasswordWithCodeInput>;
};


export type MutationRegisterArgs = {
  input?: Maybe<RegisterInput>;
};


export type MutationSocialLoginArgs = {
  input: SocialLoginInput;
};


export type MutationGetAccessTokenWithEmailArgs = {
  email: Scalars['String'];
};


export type MutationRegisterFakeUserArgs = {
  input: FakeRegisterInput;
};


export type MutationRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>;
};


export type MutationVerifyEmailWithPinArgs = {
  pin: Scalars['String'];
};


export type MutationSetInitialPasswordArgs = {
  password: Scalars['String'];
};


export type MutationUpdateEmailArgs = {
  input?: Maybe<UpdateEmailInput>;
};


export type MutationVerifyPinAndUpdateEmailArgs = {
  pin: Scalars['String'];
  email: Scalars['String'];
};


export type MutationClaimAccountArgs = {
  input?: Maybe<AccountClaimInput>;
};

export type MyNorthstar = {
  __typename?: 'MyNorthstar';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  objectives?: Maybe<MyNorthstarObjectives>;
  is_public?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  northstar_copied_from_id?: Maybe<Scalars['ID']>;
  ws_order?: Maybe<Scalars['Int']>;
  b_order?: Maybe<Scalars['Int']>;
  boards?: Maybe<Array<Board>>;
  objective_suggestions?: Maybe<Array<Maybe<Objective>>>;
};

export type MyNorthstarObjectivePaginatorInfo = {
  __typename?: 'MyNorthstarObjectivePaginatorInfo';
  total: Scalars['Int'];
  hasMorePages: Scalars['Boolean'];
  currentPage: Scalars['Int'];
};

export type MyNorthstarObjectives = {
  __typename?: 'MyNorthstarObjectives';
  paginatorInfo?: Maybe<MyNorthstarObjectivePaginatorInfo>;
  data?: Maybe<Array<Objective>>;
};

/** A paginated list of MyNorthstar items. */
export type MyNorthstarPaginator = {
  __typename?: 'MyNorthstarPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of MyNorthstar items. */
  data: Array<MyNorthstar>;
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
  token: Scalars['String'];
};

export type NorthStar = {
  __typename?: 'NorthStar';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  objectives?: Maybe<Array<Objective>>;
  boards?: Maybe<Array<Board>>;
  workspace?: Maybe<Workspace>;
  milestones?: Maybe<Array<Milestone>>;
  is_public?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  northstar_copied_from_id?: Maybe<Scalars['ID']>;
  ws_order?: Maybe<Scalars['Int']>;
  b_order?: Maybe<Scalars['Int']>;
  milestone_suggestions?: Maybe<Array<Maybe<Milestone>>>;
  objective_suggestions?: Maybe<Array<Maybe<Objective>>>;
};

export type NorthStarBoardRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type NorthStarObjectiveRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type NorthStarOnboardingDataInput = {
  name: Scalars['String'];
  color: Scalars['String'];
};

export type NorthStarOrderInput = {
  orderType: Scalars['String'];
  northStarIds: Array<Scalars['ID']>;
};

/** A paginated list of NorthStar items. */
export type NorthStarPaginator = {
  __typename?: 'NorthStarPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of NorthStar items. */
  data: Array<NorthStar>;
};

export type NorthStarWorkspaceRelation = {
  connect: Scalars['ID'];
};

export type NotificationData = {
  __typename?: 'NotificationData';
  source?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  message_detail_id?: Maybe<Scalars['String']>;
  message_detail_board_pid?: Maybe<Scalars['ID']>;
  message_detail_workspace_pid?: Maybe<Scalars['ID']>;
};

export enum NotificationType {
  Email = 'email'
}

/**
 * Objectives (goals) are the key components of each board. They describe what
 * we're trying to achieve to move towards the purpose of the board. Each objective
 * have a priority, progress, owner, due date, etc.
 *
 * The objective can be a task by itself - example: "Cold call 50 customers each week"
 *
 * -- OR --
 *
 * It can have keyresults, which can be viewed as sub-tasks, or a to-do list within
 * the objective. Visualising the action plan to fulfill the individual objective.
 *
 *
 * If an objective doesn't have keyresults, the objective itself becomes a checkable item that can be marked as done.
 *
 * --- REGEX ----
 *
 * If an objective doesn't have any keyresults, we should guess if it's an objective that's repetitive.
 *
 * Example: "Cold call 50 customers each week"
 *
 * We should use the number '50' as a KPI and break it down into a counter.
 * Depending on the size of the number we should recommend a progress rate that can
 * be checked of the main objective. In this example 50 is
 * a too high number to be checked once, and the user probably wants to check the
 * progress more regularly. For example we can decide to break down a number to
 * 1/10 of the total.
 *
 * This would result in Pembio automatically giving the user a counter of 0/50 that
 * adds 10 every time the submit progress on the objective.
 *
 *
 * This is just used as a help feature for users, and they can edit the counter and progress percentage.
 *
 * -----------
 *
 *
 * Objectives can also be cascaded through the board and milestones. Which means
 * that the objective: 'Increase Monthly Recurring Revenue with $5M' can
 * automatically be devided and inserted in the different milestones.
 *
 * If we suppose the milestones are weekly based (52 weeks), we show a breakdown of the cascaded objective. Example
 *
 * Cascaded objective: Increase MRR with $5M
 *
 * M1 (week 1) - $5M/52 ≈ $96 000 / week
 */
export type Objective = Reactable & {
  __typename?: 'Objective';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  background?: Maybe<BackgroundImage>;
  comments?: Maybe<Array<ObjectiveComment>>;
  /** Connection to the board that owns this objective */
  board?: Maybe<Board>;
  initiatives?: Maybe<Array<Initiative>>;
  metrics: ObjectiveMetrics;
  creator: User;
  /** Connection to the user that's responsible for the objective - by default it's set to the creator */
  owner: User;
  /** Connection to the Objective type to which this objective type is associated to - null by default */
  objective_type?: Maybe<ObjectiveType>;
  tags?: Maybe<Array<CustomTag>>;
  /** List users assigned to key results in objective */
  assignees?: Maybe<Array<User>>;
  /** Rank the objectives in terms of importance for user */
  score: Scalars['Float'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  /** Hex color set to the background of the objective-card - by default #fff  */
  background_color?: Maybe<Scalars['String']>;
  /** Value in px that decides the height of the objective-card in the GUI */
  display_height?: Maybe<Scalars['Int']>;
  /** Automatically showing the progress of the objective */
  status?: Maybe<ObjectiveStatus>;
  /** Setting the priority of the objective */
  priority?: Maybe<ObjectivePriority>;
  is_recurring: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  /** Shows when the objective is due */
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  northStars?: Maybe<Array<NorthStar>>;
  bookmarks?: Maybe<Array<Bookmark>>;
  recurrences?: Maybe<Array<Maybe<ObjectiveRecurrence>>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount: Scalars['Int'];
  reactionSummaries?: Maybe<Array<ReactionSummary>>;
  active_users?: Maybe<Array<User>>;
  key_results?: Maybe<KeyResultPaginator>;
};


/**
 * Objectives (goals) are the key components of each board. They describe what
 * we're trying to achieve to move towards the purpose of the board. Each objective
 * have a priority, progress, owner, due date, etc.
 *
 * The objective can be a task by itself - example: "Cold call 50 customers each week"
 *
 * -- OR --
 *
 * It can have keyresults, which can be viewed as sub-tasks, or a to-do list within
 * the objective. Visualising the action plan to fulfill the individual objective.
 *
 *
 * If an objective doesn't have keyresults, the objective itself becomes a checkable item that can be marked as done.
 *
 * --- REGEX ----
 *
 * If an objective doesn't have any keyresults, we should guess if it's an objective that's repetitive.
 *
 * Example: "Cold call 50 customers each week"
 *
 * We should use the number '50' as a KPI and break it down into a counter.
 * Depending on the size of the number we should recommend a progress rate that can
 * be checked of the main objective. In this example 50 is
 * a too high number to be checked once, and the user probably wants to check the
 * progress more regularly. For example we can decide to break down a number to
 * 1/10 of the total.
 *
 * This would result in Pembio automatically giving the user a counter of 0/50 that
 * adds 10 every time the submit progress on the objective.
 *
 *
 * This is just used as a help feature for users, and they can edit the counter and progress percentage.
 *
 * -----------
 *
 *
 * Objectives can also be cascaded through the board and milestones. Which means
 * that the objective: 'Increase Monthly Recurring Revenue with $5M' can
 * automatically be devided and inserted in the different milestones.
 *
 * If we suppose the milestones are weekly based (52 weeks), we show a breakdown of the cascaded objective. Example
 *
 * Cascaded objective: Increase MRR with $5M
 *
 * M1 (week 1) - $5M/52 ≈ $96 000 / week
 */
export type ObjectiveKey_ResultsArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ObjectiveAttachmentRelation = {
  objective_id: Scalars['ID'];
  files: Array<Scalars['Upload']>;
};

export type ObjectiveComment = {
  __typename?: 'ObjectiveComment';
  id: Scalars['ID'];
  author: User;
  objective: Objective;
  comment: Scalars['String'];
  commentAsHtml: Scalars['String'];
  created_at: Scalars['DateTime'];
  deleted_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

/** A paginated list of ObjectiveComment items. */
export type ObjectiveCommentPaginator = {
  __typename?: 'ObjectiveCommentPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ObjectiveComment items. */
  data: Array<ObjectiveComment>;
};

export type ObjectiveCount = {
  __typename?: 'objectiveCount';
  totalObjectiveCount?: Maybe<Scalars['Int']>;
  totalCompletedObjectiveCount?: Maybe<Scalars['Int']>;
};

export type ObjectiveCustomTagRelation = {
  objective_id: Scalars['ID'];
  custom_tag_ids?: Maybe<Array<Scalars['ID']>>;
};

export type ObjectiveFilterInputs = {
  userId?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['DateTime']>;
  due_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  completed_at_start?: Maybe<Scalars['DateTime']>;
  completed_at_end?: Maybe<Scalars['DateTime']>;
  master_dashboard_start?: Maybe<Scalars['DateTime']>;
  master_dashboard_end?: Maybe<Scalars['DateTime']>;
  sortByDateType?: Maybe<Scalars['String']>;
};

export type ObjectiveGrape = {
  __typename?: 'objectiveGrape';
  completed_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export type ObjectiveMetrics = {
  __typename?: 'ObjectiveMetrics';
  keyResultsCount: Scalars['Int'];
  keyResultsCompletedCount: Scalars['Int'];
  keyResultsOverallProgress?: Maybe<Scalars['Float']>;
};

export type ObjectiveNorthStarRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type ObjectiveObjectiveCommentRelation = {
  connect: Scalars['ID'];
};

/** A paginated list of Objective items. */
export type ObjectivePaginator = {
  __typename?: 'ObjectivePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Objective items. */
  data: Array<Objective>;
};

export enum ObjectivePriority {
  High = 'high',
  Normal = 'normal',
  Low = 'low'
}

export type ObjectiveRecurrence = {
  __typename?: 'ObjectiveRecurrence';
  id: Scalars['ID'];
  interval: RecurrenceInterval;
  /**
   * day - null for daily, int week number (ISO-8601 - 1 for Monday through 7 for
   * Sunday) for weekly, int date (1-31) for monthly
   */
  day?: Maybe<Scalars['Int']>;
  /** time in H:i:s format */
  time?: Maybe<Scalars['Time']>;
  /** Y-m-d date */
  start_date: Scalars['Date'];
  /** Y-m-d date */
  end_date?: Maybe<Scalars['Date']>;
  objective?: Maybe<Objective>;
  next_occurrence?: Maybe<Scalars['DateTime']>;
  status: Scalars['Boolean'];
  creator: User;
  exception?: Maybe<Array<Scalars['Int']>>;
  current_streak: Scalars['Int'];
  longest_streak: Scalars['Int'];
  times_repeated: Scalars['Int'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  upcoming?: Maybe<Array<Scalars['DateTime']>>;
  history?: Maybe<ObjectiveRecurrenceHistoryPaginator>;
};


export type ObjectiveRecurrenceUpcomingArgs = {
  limit: Scalars['Int'];
};


export type ObjectiveRecurrenceHistoryArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type ObjectiveRecurrenceHistory = {
  __typename?: 'ObjectiveRecurrenceHistory';
  id: Scalars['ID'];
  start_date: Scalars['DateTime'];
  objective_status: ObjectiveStatus;
  task_count: Scalars['Int'];
  completed_task_count: Scalars['Int'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

/** A paginated list of ObjectiveRecurrenceHistory items. */
export type ObjectiveRecurrenceHistoryPaginator = {
  __typename?: 'ObjectiveRecurrenceHistoryPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ObjectiveRecurrenceHistory items. */
  data: Array<ObjectiveRecurrenceHistory>;
};

export type ObjectiveRecurrenceObjectiveRelation = {
  connect: Scalars['ID'];
};

export type ObjectivesOrderInput = {
  objectiveIds: Array<Scalars['ID']>;
};

export enum ObjectiveStatus {
  Cancelled = 'cancelled',
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
  Overdelivered = 'overdelivered',
  Pending = 'pending'
}

export type ObjectiveTag = {
  __typename?: 'ObjectiveTag';
  board: Board;
  objectives?: Maybe<Array<Objective>>;
  tag: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type ObjectiveType = {
  __typename?: 'ObjectiveType';
  id: Scalars['ID'];
  /** Showing name of the Objective type */
  name: Scalars['String'];
  /** Description about the Objective type */
  description?: Maybe<Scalars['String']>;
};

export type ObjectiveUserRelation = {
  objective_id: Scalars['ID'];
  user_ids?: Maybe<Array<Scalars['ID']>>;
};

export type OnboardingNorthstarInput = {
  workspace_id: Scalars['ID'];
  board_id: Scalars['ID'];
  northStars?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
  objectives?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']>>>>>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
  /** Amount of items. */
  Count = 'COUNT'
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
  /** Average. */
  Avg = 'AVG',
  /** Minimum. */
  Min = 'MIN',
  /** Maximum. */
  Max = 'MAX',
  /** Sum. */
  Sum = 'SUM',
  /** Amount of items. */
  Count = 'COUNT'
}

/** Information about pagination using a Relay style cursor connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** The cursor to continue paginating backwards. */
  startCursor?: Maybe<Scalars['String']>;
  /** The cursor to continue paginating forwards. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of nodes in the paginated connection. */
  total: Scalars['Int'];
  /** Number of nodes in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
};

/** Information about pagination using a fully featured paginator. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Index of the last available page. */
  lastPage: Scalars['Int'];
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Number of total available items. */
  total: Scalars['Int'];
};

export type Plan = {
  __typename?: 'Plan';
  id: Scalars['ID'];
  stripe_plan_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  monthly_base_price: Scalars['Float'];
  interval: Scalars['String'];
  planAccess: PlanAccess;
  stripeProduct: StripeProduct;
};

export type PlanAccess = {
  __typename?: 'PlanAccess';
  id: Scalars['ID'];
  max_user: Scalars['Int'];
  max_board: Scalars['Int'];
  file_manager_access?: Maybe<Scalars['Boolean']>;
  create_chat_message_access?: Maybe<Scalars['Boolean']>;
  pinned_message_access?: Maybe<Scalars['Boolean']>;
  room_privacy_settings_access?: Maybe<Scalars['Boolean']>;
  max_room_templates?: Maybe<Scalars['Int']>;
  archive_room_access?: Maybe<Scalars['Boolean']>;
  chat_history_access?: Maybe<Scalars['Boolean']>;
  all_timeline_period_access?: Maybe<Scalars['Boolean']>;
  convert_to_template_access?: Maybe<Scalars['Boolean']>;
  bookmark_access?: Maybe<Scalars['Boolean']>;
  app_integration_access?: Maybe<Scalars['Boolean']>;
  milestone_access?: Maybe<Scalars['Boolean']>;
  ns_visibility_access?: Maybe<Scalars['Boolean']>;
  max_goal_templates?: Maybe<Scalars['Int']>;
  objective_recurrence_access?: Maybe<Scalars['Boolean']>;
  objective_duplicaion_access?: Maybe<Scalars['Boolean']>;
  objective_priority_access?: Maybe<Scalars['Boolean']>;
  objective_tags_access?: Maybe<Scalars['Boolean']>;
  objective_reminders_access?: Maybe<Scalars['Boolean']>;
  objective_subtasks_access?: Maybe<Scalars['Boolean']>;
  has_lobby_access?: Maybe<Scalars['Boolean']>;
};

export type PromotionCode = {
  __typename?: 'PromotionCode';
  id: Scalars['String'];
  active: Scalars['Boolean'];
  code: Scalars['String'];
  coupon?: Maybe<Coupon>;
  created?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  livemode?: Maybe<Scalars['Boolean']>;
  max_redemptions?: Maybe<Scalars['Int']>;
  first_time_transaction?: Maybe<Scalars['Boolean']>;
  minimum_amount?: Maybe<Scalars['Float']>;
  times_redeemed?: Maybe<Scalars['Int']>;
};

export type PublicBoardTemplate = {
  __typename?: 'PublicBoardTemplate';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  /** Depending on the chosen method, we display different types of guides within the app */
  goal_method?: Maybe<GoalMethod>;
  /** Show board without being logged in */
  public: Scalars['Boolean'];
  /** Stats for board */
  metrics: BoardMetrics;
  /** Sets the interval of days for how long each sprint should be */
  sprint_interval: Scalars['Int'];
  /** Set board level */
  boardLevel?: Maybe<BoardLevel>;
  /** Defines who can load the board */
  permissions?: Maybe<Scalars['String']>;
  /** Boards can be archived, and restored from the list of archived boards */
  deleted_at?: Maybe<Scalars['DateTime']>;
  /** Description of the board - example: This is where we create objectives for the sales activities this year  */
  description?: Maybe<Scalars['String']>;
  /** Name of the board - example: Sales objectives 2020 */
  name: Scalars['String'];
  /** If board is template */
  template?: Maybe<Scalars['Int']>;
  /** Template Details */
  templateDetails?: Maybe<BoardTemplateDetails>;
  objectives?: Maybe<Array<PublicObjective>>;
  northStars?: Maybe<Array<NorthStar>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  bookmarks?: Maybe<Array<Bookmark>>;
};

/** A paginated list of PublicBoardTemplate items. */
export type PublicBoardTemplatePaginator = {
  __typename?: 'PublicBoardTemplatePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PublicBoardTemplate items. */
  data: Array<PublicBoardTemplate>;
};

export type PublicKeyResult = {
  __typename?: 'PublicKeyResult';
  id: Scalars['ID'];
  description: Scalars['String'];
  start_value: Scalars['Int'];
  target_value: Scalars['Int'];
  unit: Scalars['String'];
  priority: KeyResultPriority;
};

/** A paginated list of PublicKeyResult items. */
export type PublicKeyResultPaginator = {
  __typename?: 'PublicKeyResultPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PublicKeyResult items. */
  data: Array<PublicKeyResult>;
};

export type PublicObjective = {
  __typename?: 'PublicObjective';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Hex color set to the background of the objective-card - by default #fff  */
  background_color?: Maybe<Scalars['String']>;
  /** Connection to the Objective type to which this objective type is associated to - null by default */
  objective_type?: Maybe<ObjectiveType>;
  /** Setting the priority of the objective */
  priority?: Maybe<ObjectivePriority>;
  key_results?: Maybe<PublicKeyResultPaginator>;
};


export type PublicObjectiveKey_ResultsArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  /** Get Board by Priamy ID (id) or Pseudo ID (pid) */
  board?: Maybe<Board>;
  boards: Array<Board>;
  getPersonalBoard?: Maybe<Array<Board>>;
  getBackgrounds?: Maybe<Array<BoardBackground>>;
  boardMessage?: Maybe<BoardMessage>;
  getMetricsForBoards: BoardMetrics;
  getBoardTemplates?: Maybe<Array<BoardTemplate>>;
  getBoardTemplate?: Maybe<BoardTemplate>;
  publicBoardTemplate?: Maybe<PublicBoardTemplate>;
  Initiative?: Maybe<Initiative>;
  /** Get Objective by Primary ID (id) or Pseudo ID (pid) */
  objective?: Maybe<Objective>;
  getObjectives?: Maybe<Array<Maybe<Objective>>>;
  getCompletedObjectivesByDay?: Maybe<Array<Maybe<ObjectiveGrape>>>;
  getTotalObjectiveCountInDateRange?: Maybe<ObjectiveCount>;
  objectiveComment: ObjectiveComment;
  getMetricsForObjectives: ObjectiveMetrics;
  keyResult?: Maybe<KeyResult>;
  me?: Maybe<User>;
  user?: Maybe<User>;
  checkEmail?: Maybe<Scalars['Boolean']>;
  reminder?: Maybe<Reminder>;
  myReminders: Array<Reminder>;
  createdReminders: Array<Reminder>;
  workspaceAvailable: WorkspaceAvailability;
  haveWorkspace: HaveWorkspace;
  /** Get Workspace by Primary ID (id) or Pseudo ID (pid) */
  workspace?: Maybe<Workspace>;
  workspaceBoards?: Maybe<Array<Board>>;
  workspaces?: Maybe<Array<Workspace>>;
  workspaceObjectives?: Maybe<Array<Objective>>;
  privateWorkspaceTemplates?: Maybe<Array<Maybe<PublicBoardTemplate>>>;
  workspaceInvite?: Maybe<WorkspaceInvite>;
  getSprint?: Maybe<Sprint>;
  getMetricsForSprint: SprintMetrics;
  getNorthStars?: Maybe<Array<NorthStar>>;
  getNorthStar?: Maybe<NorthStar>;
  /** Get Objective Type by Priamy ID (id) */
  objective_type?: Maybe<ObjectiveType>;
  objective_types: Array<ObjectiveType>;
  /** Get Template Category by Priamy ID (id) */
  template_category?: Maybe<TemplateCategory>;
  template_categories: Array<TemplateCategory>;
  /** Get Feedback Type by Priamy ID (id) */
  feedback_type?: Maybe<FeedbackType>;
  feedback_types: Array<FeedbackType>;
  getThirdPartyProviders: Array<ThirdPartyProvider>;
  getPreviewData?: Maybe<LinkPreview>;
  getAllWorkspaceNotifications?: Maybe<Array<Maybe<WorkspaceNotification>>>;
  plans?: Maybe<Array<Plan>>;
  invoices?: Maybe<Invoices>;
  activitiesForSubjects?: Maybe<ActivityPaginator>;
  getUserBoards?: Maybe<BoardPaginator>;
  getMessagesForBoard?: Maybe<BoardMessagePaginator>;
  publicBoardTemplates?: Maybe<PublicBoardTemplatePaginator>;
  getBoardNotifications?: Maybe<BoardNotificationPaginator>;
  getMissedObjectives?: Maybe<ObjectivePaginator>;
  getCompletedObjectives?: Maybe<ObjectivePaginator>;
  getObjectivesInDateRange?: Maybe<ObjectivePaginator>;
  getObjectivesLists?: Maybe<ObjectivePaginator>;
  objectiveComments?: Maybe<ObjectiveCommentPaginator>;
  allMilestonesInWorkspace?: Maybe<MilestonePaginator>;
  users?: Maybe<UserPaginator>;
  userNotifications?: Maybe<UserNotificationPaginator>;
  myRemindersOnDateRange?: Maybe<ReminderPaginator>;
  getSprints?: Maybe<SprintPaginator>;
  getCompletedNorthStarOnDateRange?: Maybe<NorthStarPaginator>;
  myNorthstarsInWorkspace?: Maybe<MyNorthstarPaginator>;
  workspaceAttachments?: Maybe<AttachmentPaginator>;
  boardAttachments?: Maybe<AttachmentPaginator>;
  getWorkspaceNotificationsForBoard?: Maybe<WorkspaceNotificationPaginator>;
};


export type QueryBoardArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryGetPersonalBoardArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryBoardMessageArgs = {
  id: Scalars['ID'];
};


export type QueryGetMetricsForBoardsArgs = {
  boards: Array<Scalars['ID']>;
};


export type QueryGetBoardTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryPublicBoardTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryInitiativeArgs = {
  id: Scalars['ID'];
};


export type QueryObjectiveArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryGetObjectivesArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
  objective_category?: Maybe<Scalars['String']>;
};


export type QueryGetCompletedObjectivesByDayArgs = {
  workspace_id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};


export type QueryGetTotalObjectiveCountInDateRangeArgs = {
  workspace_id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};


export type QueryObjectiveCommentArgs = {
  id: Scalars['ID'];
};


export type QueryGetMetricsForObjectivesArgs = {
  objectives: Array<Scalars['ID']>;
};


export type QueryKeyResultArgs = {
  id: Scalars['ID'];
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCheckEmailArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryReminderArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryWorkspaceAvailableArgs = {
  name: Scalars['String'];
};


export type QueryWorkspaceArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryWorkspaceBoardsArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryWorkspaceObjectivesArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryPrivateWorkspaceTemplatesArgs = {
  id: Scalars['ID'];
};


export type QueryWorkspaceInviteArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type QueryGetSprintArgs = {
  board_id: Scalars['ID'];
  date: Scalars['String'];
};


export type QueryGetMetricsForSprintArgs = {
  sprint_id: Scalars['ID'];
};


export type QueryGetNorthStarsArgs = {
  board_id: Scalars['ID'];
  workspace: Scalars['Boolean'];
};


export type QueryGetNorthStarArgs = {
  goal_id: Scalars['ID'];
};


export type QueryObjective_TypeArgs = {
  id: Scalars['ID'];
};


export type QueryTemplate_CategoryArgs = {
  id: Scalars['ID'];
};


export type QueryFeedback_TypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetPreviewDataArgs = {
  url: Scalars['String'];
};


export type QueryGetAllWorkspaceNotificationsArgs = {
  workspace_id: Scalars['ID'];
};


export type QueryInvoicesArgs = {
  workspace_id: Scalars['ID'];
  page: Scalars['Int'];
};


export type QueryActivitiesForSubjectsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetUserBoardsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetMessagesForBoardArgs = {
  id: Scalars['ID'];
  block_history?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryPublicBoardTemplatesArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetBoardNotificationsArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetMissedObjectivesArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
  date?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetCompletedObjectivesArgs = {
  workspace_id?: Maybe<Scalars['ID']>;
  board_id?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetObjectivesInDateRangeArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetObjectivesListsArgs = {
  filter_type: Scalars['String'];
  filter_type_id: Scalars['ID'];
  filter_type_category?: Maybe<Scalars['String']>;
  filter_options?: Maybe<ObjectiveFilterInputs>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryObjectiveCommentsArgs = {
  objective_id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryAllMilestonesInWorkspaceArgs = {
  workspace_id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  sortByDateType?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryUsersArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryUserNotificationsArgs = {
  isWorkspace?: Maybe<Scalars['Boolean']>;
  workspacePid?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryMyRemindersOnDateRangeArgs = {
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetSprintsArgs = {
  board_id: Scalars['ID'];
  date: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetCompletedNorthStarOnDateRangeArgs = {
  workspace_id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  start_date: Scalars['DateTime'];
  end_date: Scalars['DateTime'];
  userId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryMyNorthstarsInWorkspaceArgs = {
  workspace_id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  user_id?: Maybe<Scalars['ID']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  sortByDateType?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryWorkspaceAttachmentsArgs = {
  id: Scalars['ID'];
  board_id?: Maybe<Scalars['ID']>;
  owner_id?: Maybe<Scalars['ID']>;
  file_type?: Maybe<Scalars['String']>;
  boardIds?: Maybe<Array<Maybe<UserBoards>>>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryBoardAttachmentsArgs = {
  board_id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetWorkspaceNotificationsForBoardArgs = {
  board_id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Reactable = {
  reactions?: Maybe<Array<Reaction>>;
  reactionsCount: Scalars['Int'];
  reactionSummaries?: Maybe<Array<ReactionSummary>>;
};

export type Reacter = User;

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID'];
  reaction: ReactionType;
  reactable: Reactable;
  reacter: Reacter;
  created_at: Scalars['DateTime'];
};

export type ReactionSummary = {
  __typename?: 'ReactionSummary';
  reaction: ReactionType;
  count: Scalars['Int'];
};

export enum ReactionType {
  Like = 'like',
  Dislike = 'dislike',
  Wave = 'wave',
  Clap = 'clap',
  Strong = 'strong',
  Pray = 'pray',
  Smile = 'smile',
  Smiley = 'smiley',
  Wink = 'wink',
  StarStruck = 'star_struck',
  Grimacing = 'grimacing',
  Angel = 'angel',
  Sad = 'sad',
  SweatSmile = 'sweat_smile',
  Love = 'love',
  Laugh = 'laugh'
}

export enum RecurrenceInterval {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export type ReferredData = {
  __typename?: 'ReferredData';
  total_referred_workspaces?: Maybe<Scalars['Int']>;
  total_members?: Maybe<Scalars['Int']>;
  referredWorkspaces?: Maybe<Array<Maybe<ReferredWorkspace>>>;
};

export type ReferredWorkspace = {
  __typename?: 'ReferredWorkspace';
  id: Scalars['ID'];
  referredBy: User;
  referral_code: Scalars['String'];
  workspace: Workspace;
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type RefreshTokenInput = {
  refresh_token?: Maybe<Scalars['String']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

export type RegisterAuthPayload = {
  __typename?: 'RegisterAuthPayload';
  access_token: Scalars['String'];
};

export type RegisterInput = {
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  password_confirmation?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
};

export type Remindable = BoardMessage | KeyResult | Objective | User;

export enum RemindableType {
  BoardMessage = 'board_message',
  KeyResult = 'key_result',
  Objective = 'objective',
  User = 'user'
}

export type Reminder = {
  __typename?: 'Reminder';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  date_time: Scalars['DateTime'];
  notification_type?: Maybe<NotificationType>;
  creator: User;
  remindable?: Maybe<Remindable>;
  users?: Maybe<Array<User>>;
  created_at: Scalars['DateTime'];
};

export type ReminderCreatorRelation = {
  connect?: Maybe<Scalars['ID']>;
};

/** A paginated list of Reminder items. */
export type ReminderPaginator = {
  __typename?: 'ReminderPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Reminder items. */
  data: Array<Reminder>;
};

export enum Role {
  Admin = 'admin',
  Guest = 'guest',
  Member = 'member',
  Observer = 'observer',
  Owner = 'owner',
  PrimaryOwner = 'primary_owner'
}

/** Information about pagination using a simple paginator. */
export type SimplePaginatorInfo = {
  __typename?: 'SimplePaginatorInfo';
  /** Number of items in the current page. */
  count: Scalars['Int'];
  /** Index of the current page. */
  currentPage: Scalars['Int'];
  /** Index of the first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** Index of the last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Number of items per page. */
  perPage: Scalars['Int'];
  /** Are there more pages after this one? */
  hasMorePages: Scalars['Boolean'];
};

export type SocialLoginInput = {
  provider: Scalars['String'];
  token: Scalars['String'];
  ipAddress?: Maybe<Scalars['String']>;
  isClaimAccount?: Maybe<Scalars['Boolean']>;
  claimAccountUserId?: Maybe<Scalars['ID']>;
};

/** Directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type Sprint = {
  __typename?: 'Sprint';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  board: Board;
  interval?: Maybe<Scalars['Int']>;
  status?: Maybe<SprintStatus>;
  start_at?: Maybe<Scalars['DateTime']>;
  end_at?: Maybe<Scalars['DateTime']>;
  metrics: SprintMetrics;
  sprintReflection?: Maybe<SprintReflection>;
};

export type SprintMetrics = {
  __typename?: 'SprintMetrics';
  objectivesCount: Scalars['Int'];
  keyResultsCount: Scalars['Int'];
  keyResultsCompletedCount: Scalars['Int'];
  keyResultsOverallProgress?: Maybe<Scalars['Float']>;
};

/** A paginated list of Sprint items. */
export type SprintPaginator = {
  __typename?: 'SprintPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Sprint items. */
  data: Array<Sprint>;
};

export type SprintReflection = {
  __typename?: 'SprintReflection';
  id: Scalars['ID'];
  user: User;
  sprint: Sprint;
  reflection: SprintReflectionObject;
};

export type SprintReflectionObject = {
  __typename?: 'SprintReflectionObject';
  description?: Maybe<Scalars['String']>;
};

export type SprintReflectionObjectInput = {
  description?: Maybe<Scalars['String']>;
};

export enum SprintStatus {
  NotStarted = 'NOT_STARTED',
  Ongoing = 'ONGOING',
  Due = 'DUE',
  Closed = 'CLOSED'
}

export type StripeProduct = {
  __typename?: 'StripeProduct';
  id: Scalars['ID'];
  stripe_product_id: Scalars['String'];
  name: Scalars['String'];
};

export type Subject = User | Board | Objective | KeyResult;

export type Subscription = {
  __typename?: 'Subscription';
  activityUpdated?: Maybe<Activity>;
  boardUpdated?: Maybe<Board>;
  boardMessageUpdated?: Maybe<BoardMessage>;
  boardNotificationCreated?: Maybe<BoardNotification>;
  objectiveUpdated?: Maybe<Objective>;
  completedObjectiveReacted?: Maybe<Objective>;
  objectiveCommentUpdated?: Maybe<ObjectiveComment>;
  meUpdated?: Maybe<User>;
  userNotificationUpdate?: Maybe<UserNotification>;
  userInviteUpdate?: Maybe<WorkspaceInvite>;
  workspaceUpdated?: Maybe<Workspace>;
  statusUpdated?: Maybe<UserStatus>;
  workspaceNotificationCreated?: Maybe<WorkspaceNotification>;
};


export type SubscriptionActivityUpdatedArgs = {
  id: Array<Scalars['ID']>;
};


export type SubscriptionBoardUpdatedArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type SubscriptionBoardMessageUpdatedArgs = {
  board_id: Scalars['ID'];
};


export type SubscriptionBoardNotificationCreatedArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
};


export type SubscriptionObjectiveUpdatedArgs = {
  board_id?: Maybe<Scalars['ID']>;
  board_pid?: Maybe<Scalars['ID']>;
};


export type SubscriptionCompletedObjectiveReactedArgs = {
  workspace_id?: Maybe<Scalars['ID']>;
};


export type SubscriptionObjectiveCommentUpdatedArgs = {
  objective_id: Scalars['ID'];
};


export type SubscriptionWorkspaceUpdatedArgs = {
  id?: Maybe<Scalars['ID']>;
  pid?: Maybe<Scalars['ID']>;
};


export type SubscriptionWorkspaceNotificationCreatedArgs = {
  workspace_id: Scalars['ID'];
};

export type TemplateCategory = {
  __typename?: 'TemplateCategory';
  id: Scalars['ID'];
  /** Showing name of the Template Category */
  name: Scalars['String'];
  /** Description about the Template Category */
  description?: Maybe<Scalars['String']>;
};

export type ThirdPartyAuthInput = {
  token: Scalars['String'];
  code_verifier?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
};

export type ThirdPartyAuthPayload = {
  __typename?: 'ThirdPartyAuthPayload';
  access_token: Scalars['String'];
  expires_in?: Maybe<Scalars['Int']>;
  token_type: Scalars['String'];
};

export type ThirdPartyProvider = {
  __typename?: 'ThirdPartyProvider';
  id: Scalars['ID'];
  name: Scalars['String'];
  provider_url?: Maybe<Scalars['String']>;
};


/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type UnseenBoardMessage = {
  __typename?: 'UnseenBoardMessage';
  id: Scalars['ID'];
  user: User;
  board: Board;
};

export type UpdateBoardBulkInput = {
  id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateBoardInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  starred?: Maybe<Scalars['Boolean']>;
  public?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Scalars['String']>;
  boardLevel?: Maybe<ConnectBoardLevel>;
  sprint_interval?: Maybe<Scalars['Int']>;
  background_color?: Maybe<Scalars['String']>;
  objectives?: Maybe<UpdateObjectivesInput>;
  board_background_id?: Maybe<Scalars['ID']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateBoardMessageInput = {
  message: Scalars['String'];
  files?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateBookmarkInput = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateCustomTagInput = {
  name: Scalars['String'];
  color?: Maybe<Scalars['String']>;
};

export type UpdateEmailInput = {
  email: Scalars['String'];
};

export type UpdateInitiativeInput = {
  assignee?: Maybe<InitiativeAssigneeRelation>;
  completed_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  priority?: Maybe<InitiativePriority>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type UpdateKeyResultInput = {
  assignee?: Maybe<KeyResultAssigneeRelation>;
  completed?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  priority?: Maybe<KeyResultPriority>;
  due_at?: Maybe<Scalars['DateTime']>;
  start_value?: Maybe<Scalars['Int']>;
  target_value?: Maybe<Scalars['Int']>;
  increment_value?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  current_value?: Maybe<Scalars['Int']>;
};

export type UpdateMilestoneInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  reward?: Maybe<Scalars['String']>;
  status: MilestoneStatus;
  due_date?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['Int']>;
};

export type UpdateNorthStarInput = {
  workspace?: Maybe<NorthStarWorkspaceRelation>;
  boards?: Maybe<NorthStarBoardRelation>;
  objectives?: Maybe<NorthStarObjectiveRelation>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  is_public?: Maybe<Scalars['Boolean']>;
  ws_order?: Maybe<Scalars['Int']>;
  b_order?: Maybe<Scalars['Int']>;
};

export type UpdateObjectiveInput = {
  id?: Maybe<Scalars['ID']>;
  owner?: Maybe<ConnectOwnerRelation>;
  board?: Maybe<BoardObjectiveRelation>;
  background_color?: Maybe<Scalars['String']>;
  display_height?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  objective_type?: Maybe<ConnectObjectiveTypeRelation>;
  priority?: Maybe<ObjectivePriority>;
  status?: Maybe<ObjectiveStatus>;
  is_recurring?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  due_date?: Maybe<Scalars['DateTime']>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  completed_at?: Maybe<Scalars['DateTime']>;
  northStars?: Maybe<ObjectiveNorthStarRelation>;
  active_users?: Maybe<ActivateUserInObjectiveRelation>;
};

export type UpdateObjectiveRecurrenceInput = {
  interval: RecurrenceInterval;
  /**
   * day - null for daily, int week number (ISO-8601 - 1 for Monday through 7 for
   * Sunday) for weekly, int date (1-31) for monthly
   */
  day?: Maybe<Scalars['Int']>;
  /** time in H:i:s format */
  time?: Maybe<Scalars['Time']>;
  /** Y-m-d date */
  start_date: Scalars['Date'];
  /** Y-m-d date */
  end_date?: Maybe<Scalars['Date']>;
  /** Comma seperated int week number (ISO-8601 - 1 for Monday through 7 for Sunday) */
  exception?: Maybe<Array<Scalars['Int']>>;
};

export type UpdateObjectivesInput = {
  update?: Maybe<Array<UpdateObjectiveInput>>;
};

export type UpdateReflectionInput = {
  reflection?: Maybe<SprintReflectionObjectInput>;
};

export type UpdateReminderInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['DateTime']>;
  notification_type?: Maybe<NotificationType>;
  remindable?: Maybe<ConnectRemindableMorphTo>;
};

export type UpdateSprintInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type UpdateSubscription = {
  subscription_id: Scalars['String'];
  stripe_plan_id?: Maybe<Scalars['String']>;
  itemQuantity?: Maybe<Scalars['Int']>;
  billingCycleAnchor?: Maybe<Scalars['String']>;
  prorationBehavior?: Maybe<Scalars['String']>;
  payment_method?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  last_active_date?: Maybe<Scalars['DateTime']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  has_freemium_access?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserSettingsInput = {
  dark_mode?: Maybe<Scalars['Boolean']>;
  weekly_digest?: Maybe<Scalars['Boolean']>;
  email_marketing?: Maybe<Scalars['Boolean']>;
};

export type UpdateWorkspaceInput = {
  name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};


/** A regular user */
export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  groups?: Maybe<Array<UserGroup>>;
  settings: UserSettings;
  /** Shows information about the users connection to different workspaces */
  workspaces?: Maybe<Array<Workspace>>;
  /** User worksapce invites */
  invites?: Maybe<Array<WorkspaceInvite>>;
  status?: Maybe<UserStatus>;
  /** URL where user avatar can be found */
  avatar?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  /**
   * Pretty-name of first name and last name - example: Gustav Olsson
   * @deprecated Use `name` field instead
   */
  full_name: Scalars['String'];
  has_verified_email: Scalars['Boolean'];
  /** First letter of first name and last name - example: GO */
  initials: Scalars['String'];
  last_name?: Maybe<Scalars['String']>;
  /** Pretty-name of first name and last name - example: Gustav Olsson */
  name: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  reminders?: Maybe<Array<Reminder>>;
  last_active_date?: Maybe<Scalars['DateTime']>;
  unique_day_session_count?: Maybe<Scalars['Int']>;
  /** Timezone Country and city */
  timezone?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  isFake?: Maybe<Scalars['Boolean']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  /** Shows the user that have connected thirdparty apps */
  thirdPartyProviders?: Maybe<Array<Maybe<ThirdPartyProvider>>>;
  account_type?: Maybe<Scalars['String']>;
  /** Referral Code */
  referral_code?: Maybe<Scalars['String']>;
  referredData?: Maybe<ReferredData>;
  activeOnObjectives?: Maybe<Array<Objective>>;
  engagement_score?: Maybe<Scalars['Float']>;
  billingDetails?: Maybe<Array<Maybe<UserBillingDetail>>>;
  taxDetails?: Maybe<Array<Maybe<UserTaxDetail>>>;
  has_freemium_access?: Maybe<Scalars['Boolean']>;
  thumbs_up_count?: Maybe<Scalars['Int']>;
  boardMessages?: Maybe<BoardMessagePaginator>;
  /** User notifications */
  notifications?: Maybe<UserNotificationPaginator>;
};


/** A regular user */
export type UserBoardMessagesArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


/** A regular user */
export type UserNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type UserBillingDetail = {
  __typename?: 'UserBillingDetail';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  account_type: Scalars['String'];
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  is_default?: Maybe<Scalars['Boolean']>;
};

export type UserBoards = {
  id?: Maybe<Scalars['ID']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  id: Scalars['ID'];
  /** Showing which users belong to this userGroup */
  members?: Maybe<Array<User>>;
  /** Owner of the user group (for example a manager) */
  owner: User;
  /** name of the user group - e.g. Marketing */
  name: Scalars['String'];
};

export type UserNotification = {
  __typename?: 'UserNotification';
  id?: Maybe<Scalars['ID']>;
  read_at?: Maybe<Scalars['DateTime']>;
  created_at?: Maybe<Scalars['DateTime']>;
  message: NotificationData;
};

/** A paginated list of UserNotification items. */
export type UserNotificationPaginator = {
  __typename?: 'UserNotificationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of UserNotification items. */
  data: Array<UserNotification>;
};

/** A paginated list of User items. */
export type UserPaginator = {
  __typename?: 'UserPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of User items. */
  data: Array<User>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  dark_mode: Scalars['Boolean'];
  weekly_digest: Scalars['Boolean'];
  use_advanced_password_rules: Scalars['Boolean'];
  email_marketing?: Maybe<Scalars['Boolean']>;
};

export type UserStatus = {
  __typename?: 'UserStatus';
  id: Scalars['ID'];
  user_id: Scalars['ID'];
  emoji: Scalars['String'];
  status_text: Scalars['String'];
  tenure: Scalars['String'];
  start_at: Scalars['DateTime'];
  end_at?: Maybe<Scalars['DateTime']>;
};

export type UserTaxDetail = {
  __typename?: 'UserTaxDetail';
  id: Scalars['ID'];
  tax_type: Scalars['String'];
  tax_id: Scalars['String'];
  stripe_tax_id: Scalars['String'];
};

export type UserWorkspaceRelation = {
  connect: Scalars['ID'];
};

/**
 * Workspaces are where boards and users are grouped. A workspace can be owned by an organization, or by an individual user.
 *
 * A new user can either join existing workspaces or create a new one. And for
 * every new user, we automatically create a personal workspace.
 *
 * The workspace have its own settings for permissions, users, domains etc.
 */
export type Workspace = {
  __typename?: 'Workspace';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  created_at: Scalars['DateTime'];
  /** Shows boards in this workspace */
  boards?: Maybe<Array<Board>>;
  /** Shows boards in this workspace and level */
  boardsinLevel1?: Maybe<Array<Board>>;
  boardsinLevel2?: Maybe<Array<Board>>;
  boardsinLevel3?: Maybe<Array<Board>>;
  /** Shows boards available to user to join */
  available_boards?: Maybe<Array<Board>>;
  /** Shows archived boards available */
  archive_boards?: Maybe<Array<Board>>;
  /** The owner of this workspace */
  owner?: Maybe<Array<User>>;
  my_role: Role;
  my_objectives?: Maybe<Array<Objective>>;
  my_initiatives?: Maybe<Array<Initiative>>;
  my_keyresults?: Maybe<Array<KeyResult>>;
  /** Access to workspace */
  my_access: Scalars['Int'];
  /** Permission holders for this Workspace */
  members?: Maybe<Array<WorkspaceMember>>;
  /** All Members of Workspace Bolocked and unblocked */
  allMembers?: Maybe<Array<WorkspaceMember>>;
  /** Image for the workspace avatar */
  avatar?: Maybe<Scalars['String']>;
  /** pretty-name of the workspace - example: Acme HQ */
  name: Scalars['String'];
  /** Unique name of the workspace, that can be used in wildcard domains (subdomain) - example: acme-hq */
  domain: Scalars['String'];
  /** Pending invitates, showing users that has been invited to the workspace */
  invites?: Maybe<Array<WorkspaceInvite>>;
  encryption_key?: Maybe<Scalars['String']>;
  northStars?: Maybe<Array<NorthStar>>;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  pinnedMessages?: Maybe<Array<Maybe<BoardMessage>>>;
  currentSubscription?: Maybe<WorkspaceSubscription>;
  totalObjectivesCount: Scalars['Int'];
};


/**
 * Workspaces are where boards and users are grouped. A workspace can be owned by an organization, or by an individual user.
 *
 * A new user can either join existing workspaces or create a new one. And for
 * every new user, we automatically create a personal workspace.
 *
 * The workspace have its own settings for permissions, users, domains etc.
 */
export type WorkspaceBoardsinLevel1Args = {
  board_level_identifier?: Maybe<Scalars['Int']>;
};


/**
 * Workspaces are where boards and users are grouped. A workspace can be owned by an organization, or by an individual user.
 *
 * A new user can either join existing workspaces or create a new one. And for
 * every new user, we automatically create a personal workspace.
 *
 * The workspace have its own settings for permissions, users, domains etc.
 */
export type WorkspaceBoardsinLevel2Args = {
  board_level_identifier?: Maybe<Scalars['Int']>;
};


/**
 * Workspaces are where boards and users are grouped. A workspace can be owned by an organization, or by an individual user.
 *
 * A new user can either join existing workspaces or create a new one. And for
 * every new user, we automatically create a personal workspace.
 *
 * The workspace have its own settings for permissions, users, domains etc.
 */
export type WorkspaceBoardsinLevel3Args = {
  board_level_identifier?: Maybe<Scalars['Int']>;
};

export type WorkspaceAvailability = {
  __typename?: 'WorkspaceAvailability';
  available: Scalars['Boolean'];
  domain: Scalars['String'];
};

export type WorkspaceInvite = {
  __typename?: 'WorkspaceInvite';
  id: Scalars['ID'];
  pid: Scalars['ID'];
  boards?: Maybe<Array<Board>>;
  invitee_email: Scalars['String'];
  invitee_registered?: Maybe<Scalars['String']>;
  initiator: User;
  workspace: Workspace;
  role: Role;
  created_at: Scalars['DateTime'];
  deleted_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type WorkspaceInviteBoardsRelation = {
  connect?: Maybe<Array<Scalars['ID']>>;
};

export type WorkspaceInviteWorkspaceRelation = {
  connect: Scalars['ID'];
};

export type WorkspaceMember = {
  __typename?: 'WorkspaceMember';
  id: Scalars['ID'];
  user: User;
  workspace: Workspace;
  role: Role;
  access_workspace?: Maybe<Scalars['String']>;
};

export type WorkspaceNotification = {
  __typename?: 'WorkspaceNotification';
  id: Scalars['ID'];
  user: User;
  board?: Maybe<Board>;
  workspace: Workspace;
  data: WorkspaceNotificationData;
  read?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['DateTime'];
};

export type WorkspaceNotificationData = {
  __typename?: 'WorkspaceNotificationData';
  message: Scalars['String'];
};

/** A paginated list of WorkspaceNotification items. */
export type WorkspaceNotificationPaginator = {
  __typename?: 'WorkspaceNotificationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of WorkspaceNotification items. */
  data: Array<WorkspaceNotification>;
};

export type WorkspaceSubscription = {
  __typename?: 'WorkspaceSubscription';
  id: Scalars['ID'];
  workspace: Workspace;
  plan: Plan;
  subscription_id: Scalars['String'];
  subs_schedule_id?: Maybe<Scalars['String']>;
  subscribed_quantity: Scalars['Int'];
  start_at: Scalars['DateTime'];
  end_at: Scalars['DateTime'];
  type: Scalars['String'];
  hasAccess: Scalars['Boolean'];
};
