








































































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Search extends Vue {

  emitFormat(type: string) {
    this.$emit('format', type)
  }
}
