






























import {Component, Vue} from 'vue-property-decorator';
import MasterLayout from "@/components/layout/MasterLayout.vue";
import {PublicBoardTemplateQuery} from "@/graphql/boardTemplate";
import Objective from "@/components/board/objective/Objective.vue";

@Component({
  components: { MasterLayout, Objective},
  apollo: {
    PublicBoardTemplate: {
      query: PublicBoardTemplateQuery,
      variables() {
        return {
          id: this.$route.params.template_id,
        };
      },
    },
  },
})
export default class Template extends Vue {

}
