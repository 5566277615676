











































































































































import { Component, Vue } from 'vue-property-decorator';
import {format,addHours, addMinutes, endOfToday, endOfWeek, formatDistance} from "date-fns";
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import {
  MeQuery
} from '@/graphql/auth';
import { UserNotification } from '../../typescript/types';
import UserAvatar from "@/components/user/UserAvatar.vue";
import {SetMyStatus,DeleteUserStatus} from "@/graphql/auth";
import {WorkspaceQuery} from "@/graphql/workspace";
import UserStatusText from "@/components/user/UserStatusText.vue";
import CreateWorkspaceInviteModal from '../settings/workspace/CreateWorkspaceInviteModal.vue';

@Component({
  components: {UserStatusText, UserAvatar, WorkspaceAvatar },
})
export default class UserStatus extends Vue {
  notifications: Array<UserNotification> | null = null;
  isEmojiSelectorOpen: boolean = false;
  isEmojiSelected: boolean = false;
  emojis: Array<{ icon: string, name: string }> = [
    {icon: '📅', name: 'calender'},
    {icon: '☕', name: 'coffee'},
    {icon: '💡', name: 'bulb'},
    {icon: '🌴', name: 'vacation'},
    {icon: '🏡', name: 'working-remote'},
    {icon: '🏢', name: 'office-building'},
    {icon: '🤒', name: 'sick'},
    {icon: '🟢', name: 'green-circle'},
    {icon: '🟠', name: 'orange-circle'},
    {icon: '🔴', name: 'red-circle'},
    {icon: '😄', name: 'smile'},
    {icon: '💬', name: 'speech-baloon'},
  ];

  tenure: Array<string> = [
    "1 Hour", "30 Minutes", "4 Hours", "Don't clear", "Today", "This week",
  ];

  statusText: Array<string> = [
    "In a meeting", "On a break", "Looking for inspiration", "Vacationing", "Working from home", "At the office", "Under the weather", 'Available', 'Ping if important', 'Do not disturb'
  ];


  statusData: Array<any> = [{
    icon: this.emojis[this.emojis.length - 1].icon,
    tenure: this.tenure[0],
    statusText: null,
    start_at: new Date(),
    end_at: this.getEndTime(new Date(),this.tenure[0]),
  }];

  get me() {
    return this.$store.state.me;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get singleMemberWorkspace() {
    return this.workspace.members.length === 1;
  }

  openUserInviteModal() {
    //@ts-ignore
    this.$parent.close();
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspace!.id,
        workspacePid: this.workspace!.pid,
      },
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      width: 500,
    });
  }

  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }


  setStatusData(statusIndex: any, tenureIndex: any){
    this.statusData[0].icon = this.emojis[statusIndex].icon;
    this.statusData[0].tenure = this.tenure[tenureIndex];
    this.statusData[0].statusText = this.statusText[statusIndex];
    this.statusData[0].end_at = this.getEndTime(this.statusData[0].start_at,this.statusData[0].tenure);
    this.isEmojiSelected = true;
  }

  getEndTime(start: Date, tenure: string){
    if(tenure === '1 Hour'){
      return addHours(start, 1);
    }else if(tenure === '4 Hours'){
      return addHours(start, 4);
    }else if(tenure === 'Today'){
      return endOfToday();
    }else if(tenure === '30 Minutes'){
      return addMinutes(start, 30);
    }else if(tenure === 'This week'){
      return endOfWeek(start);
    }else if(tenure === "Don't clear"){
      return new Date();
    }
  }

  cancelStatus(){
    this.statusData[0].icon = this.emojis[this.emojis.length - 1].icon;
    this.statusData[0].tenure = this.tenure[0];
    this.statusData[0].statusText = null;
    this.isEmojiSelected = false;
    if(this.me.status){
      this.deleteStatus(this.me.status.id);
    }

  }

  changeTenure(){
    this.statusData[0].start_at = new Date();
    this.statusData[0].end_at = this.getEndTime(this.statusData[0].start_at,this.statusData[0].tenure);
  }

  getTime(end: Date, tenure: string){
    let localData = this.$options.filters?.getLocalDateTime(end);
    if(tenure === '1 Hour' || tenure === '4 Hours' || tenure === '30 Minutes' || tenure === 'Today'){
      return 'Until '+format(new Date(localData), 'h:mm a');
    }else if(tenure === 'This week'){
      return 'Until '+format(new Date(localData), 'yyyy-MM-dd h:mm a');
    }
    return 'Not known';
  }

  deleteStatus(id: string){
    this.$apollo.mutate({
      mutation: DeleteUserStatus,
      variables:{
        id: id
      },
      refetchQueries: [{query: MeQuery},{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
    })
      .then((_data) => {

      })
    ;
  }

  saveStatus(){
    this.$apollo.mutate({
      mutation: SetMyStatus,
      variables:{
        emoji: this.statusData[0].icon,
        status_text: this.statusData[0].statusText,
        tenure: this.statusData[0].tenure,
        start_at: this.statusData[0].start_at,
        end_at: this.statusData[0].end_at,
      },
      refetchQueries: [{query: MeQuery},{query: WorkspaceQuery, variables: {pid: this.$store.state.active_workspace_pid}}],
    })
      .then((_data) => {
        //@ts-ignore
        this.$parent.close();
      })
    ;
  }

  selectEmoji(emoji: any){
    this.statusData[0].icon = emoji.data;
    this.isEmojiSelectorOpen = false;
    this.isEmojiSelected = true;
  }


  mounted() {
    this.$nextTick(function(){
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainInput.focus();
    });
    if(this.me.status){
      this.statusData[0].icon = this.me.status.emoji;
      this.statusData[0].tenure = this.me.status.tenure;
      this.statusData[0].statusText = this.me.status.status_text;
      this.isEmojiSelected = true;
    }
  }

}
