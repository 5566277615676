









































































import {Component, Vue} from 'vue-property-decorator';
import VerifyUser from "@/components/auth/VerifyUser.vue";

@Component({
  components: {},
})
export default class Paywall extends Vue {
  loading: boolean = false;

  get onGuestAccount() {
    return this.$store.state.me && this.$store.state.me.isFake;
  }

  claimAccount() {
    this.$gtag.event('pmb_user_claim_account_manually_initiated_paywall');
    this.$buefy.modal.open({
      component: VerifyUser,
      props: {
        claimWs: (this.$store.state.me.isFake) ?? false,
        clickedClaim: true
      },
      width: '500px',
      canCancel: !this.$store.state.currently_on_verification_code_step,
      parent: this,
    });
  }

  goToCheckout() {
    this.loading = true;
    setTimeout(() => this.routeToCheckout(), 1000);
  }

  routeToCheckout() {
    this.$router.push({name: 'workspace-checkout', 
      params: {productId: "648f3a46-d5cc-48da-8a3c-a0c4e0f50a87", billingPeriod: "monthly"}});

    this.$emit('closemodal');
    //@ts-ignore
    this.$parent.close();
  }

 

  mounted() {
    this.$gtag.event('pmb_app_paywall_modal_mounted');
  }

}
