import gql from 'graphql-tag';
import {BookmarkDetailedFragment} from "@/graphql/bookmark";
import {CustomTagDetailedFragment} from "@/graphql/customTags";
import {ObjectiveReccurenceDetailedFragment} from "@/graphql/objectiveRecurrence";
import {AttachmentDetailedFragment} from "@/graphql/attachment";

export const KeyResultDetailedFragment = gql`
    fragment KeyResultDetailed on KeyResult {
        id
        description
        completed
        priority
        target_value
        start_value
        current_value
        increment_value
        unit
        due_at
        order
        creator {
            id
            name
            deleted_at
        }
        objective {
            id
            name
        }
        assignee {
            id
            name
            first_name
            initials
            avatar
            deleted_at
        }
    }
`;

export const InitiativeDetailedFragment = gql`
    fragment InitiativeDetailed on Initiative {
        id
        description
        priority
        status
        start_date
        due_date
        objective {
            pid
            id
            name
            board {
                id
                members {
                    id
                    role
                    user {
                        id
                        name
                        email
                        initials
                        avatar
                        deleted_at
                    }
                }
            }
        }
        creator {
            id
            name
            deleted_at
        }
        assignee {
            id
            name
            first_name
            last_name
            initials
            avatar
            deleted_at
        }
    }
`;

export const ObjectiveCommentDetailedFragment = gql`
    fragment ObjectiveCommentDetailed on ObjectiveComment {
        id
        author {
            id
            name
            initials
            avatar
            deleted_at
        }
        objective {
            id
        }
        comment
        commentAsHtml
        created_at
    }
`;

export const CreateKeyResultMutation = gql`
    mutation CreateKeyResult(
        $id: ID!
        $description: String!
        $priority: KeyResultPriority
        $assignee_id: ID!
        $start_value: Int
        $current_value: Int
        $increment_value: Int
        $target_value: Int
    ) {
        createKeyResult(
            input: {
                priority: $priority
                description: $description
                objective: { connect: $id }
                assignee: { connect: $assignee_id }
                start_value: $start_value
                current_value: $current_value
                increment_value: $increment_value
                target_value: $target_value
            }
        ) {
            ...KeyResultDetailed
        }
    }
    ${KeyResultDetailedFragment}
`;

export const CreateInitiativeMutation = gql`
    mutation CreateInitiative(
        $id: ID!
        $description: String!
        $start_date: DateTime
        $due_date: DateTime
        $assignee_id: ID!
    ) {
        createInitiative(
            input: {
                description: $description
                start_date: $start_date
                due_date: $due_date
                objective: { connect: $id }
                assignee: { connect: $assignee_id }
            }
        ) {
            ...InitiativeDetailed
        }
    }
    ${InitiativeDetailedFragment}
`;

export const UpdateKeyResultMutation = gql`
    mutation UpdateKeyResult(
        $id: ID!
        $description: String
        $completed: Boolean
        $start_value: Int
        $increment_value: Int
        $priority: KeyResultPriority
        $current_value: Int
        $target_value: Int
        $assignee_id: ID
        $order: Int
        $due_at: DateTime
    ) {
        updateKeyResult(
            id: $id,
            input: {
                assignee: { connect: $assignee_id }
                description: $description
                completed: $completed
                priority: $priority
                start_value: $start_value
                current_value: $current_value
                increment_value: $increment_value
                target_value: $target_value
                order: $order
                due_at: $due_at
            }
        ) {
            ...KeyResultDetailed
        }
    }
    ${KeyResultDetailedFragment}
`;

export const UpdateInitiativeMutation = gql`
    mutation UpdateInitiative(
        $id: ID!
        $description: String
        $status: String
        $start_date: DateTime
        $due_date: DateTime
        $priority: InitiativePriority
        $assignee_id: ID
    ) {
        updateInitiative(
            id: $id,
            input: {
                assignee: { connect: $assignee_id }
                description: $description,
                start_date: $start_date
                due_date: $due_date
                status: $status
                priority: $priority
            }
        ) {
            ...InitiativeDetailed
        }
    }
    ${InitiativeDetailedFragment}
`;

export const IncrementKeyResultMutation = gql`
    mutation IncrementKeyResult($id: ID!, $value: Int) {
        incrementKeyResult(input: { key_result_id: $id, value: $value }) {
            ...KeyResultDetailed
        }
    }
    ${KeyResultDetailedFragment}
`;

export const DeleteKeyResultMutation = gql`
    mutation DeleteKeyResult($id: ID!) {
        deleteKeyResult(id: $id) {
            ...KeyResultDetailed
        }
    }
    ${KeyResultDetailedFragment}
`;

export const DeleteInitiativeMutation = gql`
    mutation DeleteInitiative($id: ID!) {
        deleteInitiative(id: $id) {
            ...InitiativeDetailed
        }
    }
    ${InitiativeDetailedFragment}
`;

export const CompleteKeyResultMutation = gql`
    mutation CompleteKeyResult($id: ID!) {
        completeKeyResult(id: $id) {
            ...KeyResultDetailed
        }
    }
    ${KeyResultDetailedFragment}
`;

export const CreateObjectiveCommentMutation = gql`
    mutation createObjectiveComment($id: ID!, $comment: String!) {
        createObjectiveComment(input: { comment: $comment, objective: { connect: $id } }) {
            ...ObjectiveCommentDetailed
        }
    }
    ${ObjectiveCommentDetailedFragment}
`;

export const ObjectiveDetailedFragment = gql`
    fragment ObjectiveDetailed on Objective {
        id
        pid
        name
        description
        status
        created_at
        priority
        is_recurring
        order
        background_color
        start_date
        due_date        
        deleted_at
        completed_at
        metrics {
            keyResultsCount
            keyResultsCompletedCount
        }
        comments {
            id
        }
        board {
            id
            pid
            name
            workspace {
                id
                pid
            }
        }
        key_results {
            data {
                ...KeyResultDetailed
            }
        }
        initiatives {
            ...InitiativeDetailed
        }
        assignees {
            id
            name
            first_name
            avatar
            initials
            email
            deleted_at
        }
        creator {
            id
            name
            first_name
            last_name
            initials
            avatar
            deleted_at
        }
        owner {
            id
            name
            full_name
            first_name
            last_name
            initials
            avatar
            deleted_at
        }
        objective_type {
            id
            name
            description
        }
        background {
            id
            image_url
        }
        display_height
        northStars {
            id
            name
            description
            color
        }
        recurrences {
            ...ObjectiveRecurrenceDetailed
        }
        bookmarks {
            ...BookmarkDetailed
        }
        tags {
            ...CustomTagDetailed
        }
        attachments {
            ...AttachmentDetailed
        }

        reactionsCount
        reactions {
            id
            reaction
            reacter {
                ...on User {
                    id
                    name
                    initials
                    avatar
                    deleted_at
                }
            }
            created_at
        }
        reactionSummaries {
            reaction
            count
        }
        active_users {
            id
            first_name
            last_name
            name
            avatar
            initials
            deleted_at
        }
    }
    ${KeyResultDetailedFragment}
    ${ObjectiveReccurenceDetailedFragment}
    ${InitiativeDetailedFragment}
    ${BookmarkDetailedFragment}
    ${CustomTagDetailedFragment}
    ${AttachmentDetailedFragment}
`;


export const DeleteObjectiveMutation = gql`
    mutation DeleteObjective($id: ID!) {
        deleteObjective(id: $id) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const ObjectivesQuery = gql`
    query Objectives($board_id: ID, $board_pid: ID, $objective_category: String) {
        getObjectives(board_id: $board_id, board_pid: $board_pid, objective_category: $objective_category) {
            id
            pid
            name
            description
            status
            created_at
            priority
            is_recurring
            order
            background_color
            start_date
            due_date
            deleted_at
            completed_at
#            metrics {
#                keyResultsCount
#                keyResultsCompletedCount
#            }
            northStars {
                id
                name
                description
                color
            }
            recurrences {
                id
            }
            active_users {
                id
                first_name
                last_name
                name
                avatar
                initials
                deleted_at
            }
        }
    }
    
`;

export const ObjectivesListQuery = gql`
    query ObjectivesLists($filter_type: String!, $filter_type_id: ID!, $filter_type_category: String,
        $start_date: DateTime,
        $due_date: DateTime,
        $status: String,
        $userId: String,
        $first: Int,
        $page: Int,
        $completed_at_start: DateTime,
        $completed_at_end: DateTime,
        $master_dashboard_start: DateTime,
        $master_dashboard_end: DateTime
        $sortByDateType: String
    ) {
        getObjectivesLists(filter_type: $filter_type, filter_type_id: $filter_type_id, filter_type_category: $filter_type_category,
            filter_options: {start_date: $start_date, due_date: $due_date, status: $status, userId: $userId, completed_at_start: $completed_at_start, completed_at_end: $completed_at_end, master_dashboard_start: $master_dashboard_start, master_dashboard_end: $master_dashboard_end, sortByDateType: $sortByDateType} first: $first, page: $page) {
            data {
                id
                pid
                name
                description
                status
                created_at
                priority
                is_recurring
                order
                background_color
                start_date
                due_date
                deleted_at    
                completed_at
                northStars {
                    id
                    color
                    boards {
                        id
                    }
                }
                recurrences {
                    id
                }
                owner {
                    id
                    name
                    full_name
                    first_name
                }
                active_users {
                    id
                    first_name
                    last_name
                    avatar
                    initials
                    deleted_at
                }
            },
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }


        }
    }

`;


export const WorkspaceObjectivesQuery = gql`
    query workspaceObjectives($workspace_id: ID, $workspace_pid: ID) {
        workspaceObjectives(id: $workspace_id, pid: $workspace_pid) {
            id
            pid
            name
            description
            status
            created_at
            priority
            is_recurring
            order
            background_color
            start_date
            due_date
            northStars {
                id
                name
                color
            }
            deleted_at
            recurrences {
                ...ObjectiveRecurrenceDetailed
            }
            owner {
                id
                deleted_at
            }
            assignees {
                id
                deleted_at
            }
            active_users {
                id
                first_name
                last_name
                name
                avatar
                initials
                deleted_at
            }
        }
    }
    ${ObjectiveReccurenceDetailedFragment}
`;

export const MissedObjectivesQuery = gql`
    query MissedObjectives($board_id: ID, $board_pid: ID, $date: DateTime) {
        getMissedObjectives(board_id: $board_id, board_pid: $board_pid, date: $date) {
            data {
                ...ObjectiveDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const objective_types = gql`
    query objective_types {
        objective_types {
            id
            name
            description
        }
    }
`;

export const ObjectiveQuery = gql`
    query Objective($id: ID, $pid: ID) {
        objective(id: $id, pid: $pid) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const ObjectiveUpdatedSubscription = gql`
    subscription ObjectiveUpdated($board_id: ID, $board_pid: ID,) {
        objectiveUpdated(board_id: $board_id, board_pid: $board_pid) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const ObjectiveCommentsQuery = gql`
    query ObjectiveComments($objective_id: ID!, $first: Int, $page: Int) {
        objectiveComments(objective_id: $objective_id, first: $first, page: $page) {
            data {
                ...ObjectiveCommentDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${ObjectiveCommentDetailedFragment}
`;

export const DeleteObjectiveCommentMutation = gql`
    mutation DeleteObjectiveComment($id: ID!) {
        deleteObjectiveComment(id: $id) {
            id
        }
    }    
`;


export const BoardObjectivesQuery = gql`
    query BoardObjectives($board_id: ID, $board_pid: ID) {
        boardObjectives(board_id: $board_id, board_pid: $board_pid) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`

export const UpdateObjective = gql`
    mutation updateObjective($id: ID!, $date: DateTime, $start_date: DateTime, $completed_at: DateTime) {
        updateObjective(id: $id, input: { due_date: $date, start_date: $start_date, completed_at: $completed_at }) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const UpdateObjectiveNorthStars = gql`
    mutation updateObjective($id: ID!, $northStars: [ID!]) {
        updateObjective(id: $id,
            input: {
                northStars: {
                    sync: $northStars
                }
            }
        ) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const AssignCustomTagsObjectives = gql `
    mutation assignCustomTags($objective_id: ID!, $custom_tag_ids: [ID!]){
        assignCustomTags(
            input:{
                objective_id: $objective_id
                custom_tag_ids: $custom_tag_ids
            }
        ){
            ...CustomTagDetailed
        }
    }
    ${CustomTagDetailedFragment}
`;

export const CreateThirdPartyObjective = gql`
    mutation CreateThirdPartyObjective(
        $data: [CreateThirdPartyObjectivesInput]!
       
    ) {
        createThirdPartyObjective(input: $data) {
            id
        }
    }
`;

export const CreateObjectiveAttachments = gql `
    mutation createObjectiveAttachments($objective_id: ID!, $files: [Upload!]!){
        createObjectiveAttachments(
            input:{
                objective_id: $objective_id
                files: $files
            }
        ){
            ...AttachmentDetailed
        }
    }
    ${AttachmentDetailedFragment}
`;

export const DeleteObjectiveAttachment = gql `
    mutation deleteObjectiveAttachment($objective_id: ID!, $attachment_id: ID!){
        deleteObjectiveAttachment(objective_id: $objective_id,attachment_id: $attachment_id){
            id
        }
    }    
`;

export const RemoveObjectiveNorthStars = gql`
    mutation removeNorthstarFromObjective($objective_id: ID!) {
        removeNorthstarFromObjective(objective_id: $objective_id) {
            ...ObjectiveDetailed
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const ObjectivesInDateRangeQuery = gql`
    query GetObjectivesInDateRange($board_id: ID, $startDate: DateTime, $endDate: DateTime $first: Int, $page: Int) {
        getObjectivesInDateRange(board_id: $board_id, startDate: $startDate, endDate: $endDate first: $first, page: $page) {
            data {
                ...ObjectiveDetailed
            }
            paginatorInfo {
                total
                currentPage
                hasMorePages
            }
        }
    }
    ${ObjectiveDetailedFragment}
`;

export const CompletedObjectivesByDate = gql `
  query GetCompletedObjectivesByDay($workspace_id: ID!, $board_id: ID, $user_id: ID,  $start_date: DateTime, $end_date: DateTime ) {
      getCompletedObjectivesByDay(workspace_id: $workspace_id, board_id: $board_id, user_id: $user_id, start_date: $start_date, end_date: $end_date) {
          completed_at
          id
      }
  }
`;

export const TotalObjectiveCountInDateRange = gql `
    query GetTotalCompletedObjectiveInDateRange($workspace_id: ID!, $board_id: ID, $user_id: ID, $start_date: DateTime, $end_date: DateTime) {
        getTotalObjectiveCountInDateRange(workspace_id: $workspace_id, board_id: $board_id, user_id: $user_id, start_date: $start_date, end_date: $end_date) {
            totalObjectiveCount
            totalCompletedObjectiveCount
        }
    }
`;

export const UpdateObjectivesOrder = gql`
    mutation updateObjectivesOrder($objectiveIds: [ID!]!) {
        updateObjectivesOrder (
            input: {
                objectiveIds: $objectiveIds
            }
        )
    }

`;
