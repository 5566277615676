class Roles {
  /**
     * @return string
     * @param value
     */
  formateRole(value: string) {
    if (value === 'admin') {
      return 'Administrator'
    } else if (value === 'member') {
      return 'Member'
    } else if (value === 'observer') {
      return 'Observer'
    } else if (value === 'owner') {
      return 'Administrator'
    } else if (value === 'primary_owner') {
      return 'Primary owner'
    } else if (value === 'guest') {
      return 'Guest'
    } else {
      return 'unassigned';
    }
  }
}

export default new Roles();
