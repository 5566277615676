

















import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class LayoutFrame extends Vue {

  get hasHeadSlot() {
    return !!this.$slots.frameHead;
  }

  get hasSubHeadSlot() {
    return !!this.$slots.frameSubHead;
  }

  get hasFootSlot() {
    return !!this.$slots.frameFoot;
  }
}
