import { Component, Vue } from 'vue-property-decorator';
import get from 'lodash/get';
import has from 'lodash/has';
import { ErrorResponse } from 'apollo-link-error';

@Component
class ValidationErrorParserMixin extends Vue {
  validation: [] | null = null;

  parseValidationError({ graphQLErrors }: ErrorResponse) {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions && extensions.category === 'validation') {
          this.validation = { ...extensions.validation, ...extensions.errors };
        }
      });
    }
  }

  fieldHasValidationError(fieldName: string, classes?: string) {
    if (has(this.validation, fieldName)) {
      return classes ?? 'is-danger';
    }
  }

  fieldValidationMessage(fieldName: string) {
    return get(this.validation, fieldName);
  }

  arrayValidationMessages(){
    return this.validation;
  }
}

export { ValidationErrorParserMixin };
