



































































import Paywall from '@/components/subscription/Paywall.vue';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ObjectiveDetailedMoreActions extends Vue {
  makeFullheight: boolean = false;

  @Prop({required: false})
  canDelete!: boolean;

  get workspace() {
    return this.$store.state.workspace;
  }

  get me() {
    return this.$store.state.me;
  }

  get isPremium() {
    if(this.$store.state.workspace.currentSubscription) {
      return true
    } else { return false }
  }

  get newUser() {
    let userCreationDate = new Date(this.me.created_at);
    let comparisonDate = new Date('2024-02-22');
    return userCreationDate > comparisonDate;
  }

  get hasFreemiumAccess() {
    return this.me.has_freemium_access 
  }

  get canAddBookmarks() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.bookmark_access) || this.hasFreemiumAccess
  }

  get canAddFiles() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.file_manager_access) || this.hasFreemiumAccess;
  }

  get canAddReminder() {
    return this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_reminders_access || this.hasFreemiumAccess;
  }

  get canAddSubtasks() {
    return (this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_subtasks_access) || this.hasFreemiumAccess
  }

  get canAddRecurrance() {
    return this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_recurrence_access || this.hasFreemiumAccess;
  }

  get canAddDuplicate() {
    return this.$store.state.workspace.currentSubscription && this.$store.state.workspace.currentSubscription.hasAccess && this.$store.state.workspace.currentSubscription.plan.planAccess.objective_duplicaion_access || this.hasFreemiumAccess;
  }
  

  closeModal() {
    this.$emit('closemodal');
   
  }

  newBookmark() {
    if(this.canAddBookmarks) {
      this.$emit('new-bookmark', true);
      this.close();
    } else {
      this.openPaywall();
    }
    
  }

  newRecurrence() {
    if(this.canAddRecurrance) {
      this.$emit('new-recurrence', true);
      this.close();
    } else {
      this.openPaywall();
    }
  }

  newReminder() {
    if (this.canAddReminder) {
      this.$emit('new-reminder', true);
      this.close();
    } else {
      this.openPaywall();
    }
  }

  newTask() {
    if(this.canAddSubtasks) {
      this.$emit('new-task', true);
      this.close();
    } else {
      this.openPaywall();
    }
  }

  duplicate() {
    if(this.canAddDuplicate) {
      this.$emit('open-duplicate', true);
      this.close();
    } else {
      this.openPaywall();
    }
  
  }

  requestDelete() {
    this.$emit('delete', true);
    this.close();
  }

  close() {
    this.$emit('close', true);
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
      events: {
      // Listen for the 'closemodal' event from the Paywall component
        closemodal: this.closeModal
      }
    });
  }
}
