






import {Component, Prop, Vue, Watch} from "vue-property-decorator";
//@ts-ignore
import confetti from "canvas-confetti";

@Component({
  components: {}
})
export default class ConfettiCanon extends Vue {
  @Prop({required: false, default: () => (['#ffa5ff', '#4eb2db', '#f7c452', '#c24bde', '#699caa'])})
  colors!: Array<string>

  @Prop({required: false, default: 70})
  particleCount!: number

  @Prop({required: false, default: () => (['circle','square'])})
  shapes!: Array<any>

  @Prop({required: false, default: () => ({x: 0.5, y: 0.5})})
  origin!: Object

  @Prop({required: false, default: 135})
  spread!: number

  @Prop({required: false, default: 135})
  angle!: number

  @Prop({required: false, default: 0})
  gravity!: number

  @Prop({required: false, default: 0.9})
  scalar!: number

  @Prop({required: false, default: 0})
  drift!: number

  @Prop({required: false, default: 40})
  startVelocity!: number

  @Prop({required: true, default: 100})
  fireTimeOut!: number

  @Prop({required: false, default: 1})
  fireCount!: number

  @Prop({required: false, default: 110})
  zIndex!: number
  
  @Prop({required: false, default: 80})
  ticks!: number


  timer: any | null = null;


  frame() {
    confetti({
      /*
      particleCount: this.particleCount,
      
      ticks: 80,
      gravity: this.gravity,
      decay: 0.96,
      
      shapes: this.shapes,
      drift: this.drift,

       */
      colors: this.colors,
      angle: this.angle,
      particleCount: this.particleCount,
      spread: this.spread,
      origin: this.origin,
      zIndex: this.zIndex,
      startVelocity: this.startVelocity,
      ticks: this.ticks,
      shapes: this.shapes,
      scalar: this.scalar,
    });

    //clearTimeout(this.timer);
  }

  mounted() {
    //this.frame();
  }

  @Watch('fireTimeOut', {immediate: true})
  fireTimeOutChange(newValue: number)
  {
    let c = 0;
    this.timer = setInterval(() => {
      this.frame();
      c++;
      if(c === this.fireCount) {
        clearInterval(this.timer)
      }
      //@ts-ignore
    }, newValue);




  }

}
