











































import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  props: {
    defaultPriority: {}
  }
})
export default class ObjectivePriority extends Vue {
  selectedPriority: string | null = null;

  setPriority(priority: string) {
    this.selectedPriority = priority;
    this.returnPriority();
  }

  returnPriority() {
    this.$emit('selected-priority', this.selectedPriority);
  }

  get priorities() {
    let priority = [
      'low',
      'normal',
      'high',
    ];
    return priority;
  }

  mounted() {
    if(this.$props.defaultPriority) {
      this.selectedPriority = this.$props.defaultPriority;
    }
  }
}
