











































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ObjectiveDetailedMoreActions extends Vue {
  makeFullheight: boolean = false;

  close() {
    this.$emit('close', true);
  }
}
