



































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import UserAvatar from '@/components/user/UserAvatar.vue';
import UsersSelector from '@/components/formfields/UsersSelector.vue';
import ListedBoardUsers from '@/components/board/ListedBoardUsers.vue';
import { User, BoardMember, WorkspaceMember } from '@/typescript/types';
import gql from 'graphql-tag';
import { BoardQuery } from '@/graphql/board';
import CreateWorkspaceInviteModal from "@/components/settings/workspace/CreateWorkspaceInviteModal.vue";
import InlineDropdown from "@/components/general/InlineDropdown.vue";
import { CreateWorkspaceInviteMutation } from "@/graphql/workspace_invite";
import { WorkspaceQuery } from "@/graphql/workspace";
import Template from "@/components/templates/Template.vue";
import Paywall from "@/components/subscription/Paywall.vue";
import EmailClaimSuccess from "@/components/workspace/EmailClaimSuccess.vue";

@Component({
  components: {
    Template,
    InlineDropdown,
    ListedBoardUsers,
    UserAvatar,
    UsersSelector,
  },
})
export default class BoardMembers extends Vue {
  // Data Properties
  searchInput: string | null = null;
  selectedUsers: Array<User> = [];
  expandMembers = false;
  loadMembersComponents = false;
  openLoading = false;
  addMembers = false;
  clicked = false;
  inviteEmails: Array<string> = [];
  selectedRole: string | null = 'guest';
  inviteSent: boolean = false;
  email: string = "";
  emailInput: string = "";
  isLoading: boolean = false;
  currentInput: string = "";
  emailValid: boolean | null = null;

  @Prop({ default: false, required: false })
  onboarding!: boolean;

  @Prop({ default: false, required: false })
  fromMemberInviteClick!: boolean;

  // Computed Properties
  get workspaceName() {
    return this.$store.state.workspace.name;
  }

  get isDisabled() {
    return this.selectedUsers.length < 1 && this.inviteEmails.length < 1;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }

  get isContinueButtonDisabled() {
    // Disable if no valid emails are present or if loading is in progress
    return (!this.inviteEmails.length || this.emailValid === false) || this.isLoading;
  }

  get isAdminOfWorkspace() {
    return this.$store.getters.isAdminOfWorkspace;
  }

  get board() {
    return this.$store.state.board;
  }

  get isOnGuestAccount() {
    return this.$store.state.me.isFake;
  }

  get myRole() {
    return this.$store.state.board.my_role;
  }

  get workspacePid() {
    return this.$store.state.active_workspace_pid;
  }

  get workspaceMembersCount() {
    return this.$store.state.workspace.members.length;
  }

  get boardMembersCount() {
    return this.$store.state.board.members.length;
  }

  get allMembersInvited() {
    return this.boardMembersCount >= this.workspaceMembersCount;
  }

  get workspace() {
    return this.$store.state.workspace;
  }

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get variables() {
    return {
      workspace: this.workspaceId,
      role: this.selectedRole,
      boards: this.board.id,
    };
  }

  get pendingInvites() {
    if (this.workspace.invites && this.workspace.invites.length > 0) {
      return this.workspace.invites.filter((inv: any) => {
        if (inv.boards.length > 0 && inv.boards[0].id === this.board.id) {
          return inv;
        }
      });
    }
    return [];
  }

  // Lifecycle Hooks
  mounted() {
    this.inviteSent = false;
  
    this.$nextTick(function () {
      //@ts-ignore ToDo: fix type issue
      this.$refs.mainInput.focus();
    });
  }

  beforeDestroy() {
    this.inviteSent = false;
  }

  // Methods
  goToWorkspaceMembers() {
    this.$router.push({ name: 'members-manager' });
    //@ts-ignore
    this.$parent.close();
  }

  handleSearch(value: string) {
    this.currentInput = value;
    this.validateEmail(value);
  }

  validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email === '') {
      this.emailValid = null;
    } else {
      this.emailValid = emailRegex.test(email);
    }
  }

  beforeTagAdd(newTag: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(newTag)) {
      this.emailValid = false;
      this.$buefy.toast.open({
        message: 'Invalid email address.',
        type: 'is-danger',
      });
      return false; // Prevent adding the invalid tag
    }
    this.emailValid = true;
    return true;
  }



  isEmailValid(email: string = ''): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  get isClaimEmailValid() {
    const email = this.emailInput ?? '';
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email.trim());
  }

  openUserInviteModal() {
    this.$buefy.modal.open({
      component: CreateWorkspaceInviteModal,
      props: {
        workspaceId: this.workspaceId,
        workspacePid: this.workspacePid,
      },
      // @ts-ignore
      customClass: 'fullpage-modal',
      parent: this,
      width: 500,
    });
  }

  get boardUsers() {
    let bUsers: Array<User> = [];
    this.board.members.forEach((element: BoardMember) => {
      if(element.user.deleted_at === null)
        bUsers.push(element.user);
    });
    return bUsers;
  }

  get workspaceUsers() {
    let wUsers: Array<User> = [];
    this.board.workspace.members.forEach((element: WorkspaceMember) => {
      if(element.user.deleted_at===null)
        wUsers.push(element.user);
    });
    return wUsers;
  }

  addUsers() {
    this.clicked = true;
    if(this.selectedUsers.length >= 1){
      this.addWorkspaceMember();
    }

    if(this.inviteEmails.length >= 1){
      
      this.inviteExternalUser();
      
    }
  }

  closeModal() {
    // @ts-ignore
    this.$parent.close() 
  }

  openEmailClaimSuccess() {
    this.$buefy.modal.open({
      component: EmailClaimSuccess,
      width: 900,
      parent: this,
      customClass: 'claim-with-email-success',
      canCancel: true,
      props: {
        userEmail: this.emailInput,
        userClaimed: true,
        fromMemberInviteClick: this.fromMemberInviteClick
      },
    });
    this.closeModal();
  }

  upgradePlan() {
    //@ts-ignore
    this.$parent.close();
    this.$buefy.modal.open({
      component: Paywall,
      width: '480px',
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  addWorkspaceMember(){
    for (let i = 0; i < this.selectedUsers.length; i++) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation addUserToBoard($user_id: ID!, $board_id: ID!) {
              addUserToBoard(user_id: $user_id, board_id: $board_id) {
                user {
                  id
                  name
                }
                board {
                  id
                  pid
                  name
                }
                role
              }
            }
          `,
          variables: {
            user_id: this.selectedUsers[i].id,
            board_id: this.board.id,
          },
          refetchQueries: [{ query: BoardQuery, variables: { id: this.board.id } }],
        })
        .then(_ => {
          this.$gtag.event('pmb_room_internal_member_invite_created');
        })
        .finally(() => {
          this.clicked = false;
          this.addMembers = false;
          this.selectedUsers = [];
        });
    }
    this.$buefy.toast.open({
      message: 'Added users!',
      position: 'is-bottom-right',
      type: 'is-black',
    });
  }

  inviteExternalUser(){
    for (let i = 0; i < this.inviteEmails.length; i++) {
      this.$apollo
        .mutate({
          mutation: CreateWorkspaceInviteMutation,
          variables: {
            invitee_email: this.inviteEmails[i],
            ...this.variables,
          },
          refetchQueries: [
            {query: WorkspaceQuery, variables: {pid: this.workspacePid}},
          ],
        })
        .catch(error => {
          console.error(error);
          this.$gtag.event('pmb_room_external_invite_failed');
        })
        .then(_ => {
          this.inviteSent = true;
          //@ts-ignore
          this.$gtag.event('pmb_room_external_invite_created');
          this.$store.commit('set_invite_sent', true);
        })
        .finally(() => {
          this.$buefy.toast.open({
            message: 'Created invite!',
            position: 'is-bottom-right',
            type: 'is-black',
          });

          this.$gtag.event('pembio_invite_workspace');
        });
    }
  }



}
