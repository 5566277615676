import {DollarApollo} from "vue-apollo/types/vue-apollo";
import {
  CheckEmail,
  GetAccessTokenWithEmailMutation,
  RegisterFakeMutation,
  LoginMutation, MeQuery,
  RegisterMutation,
  ResendEmailVerification,
  SetInitialPassword, UpdateUserMutation,
  UpdateUserSettingsMutation,
  VerifyEmailWithPin, ClaimOwnAccount
} from "@/graphql/auth";
import store from '../store';
import {Maybe} from "@/typescript/types";
import PusherService from "@/PusherService";

class UserService {
  private apollo: DollarApollo<any>;

  constructor(apollo: DollarApollo<any>) {
    this.apollo = apollo;
  }

  resendEmailVerification(): Promise<any> {
   
    return this.apollo.mutate({
      mutation: ResendEmailVerification,
    });
  }

  confirmAccount(pin: string): Promise<any> {
    return this.apollo.mutate({
      mutation: VerifyEmailWithPin,
      variables: {
        pin: pin,
      },
      refetchQueries: [{query: MeQuery}]
    })
  }

  setInitialPassword(password: string): Promise<any> {
    return this.apollo.mutate({
      mutation: SetInitialPassword,
      variables: {
        password: password,
      },
    })
  }

  acceptEmailMarketing(acceptTerms: boolean): Promise<any> {
    return this.apollo.mutate({
      mutation: UpdateUserSettingsMutation,
      variables: {
        email_marketing: acceptTerms,
      },
    })
  }

  updateUser(id: string, firstName: Maybe<string>, lastName: Maybe<string>): Promise<any> {
    return this.apollo.mutate({
      mutation: UpdateUserMutation,
      variables: {
        id: id,
        first_name: firstName,
        last_name: lastName,
      },
    }).then(({data: user}) => {
      store.commit('set_me', user.updateUser);
    })
  }

  register(email: string, firstName: Maybe<string>, lastName: Maybe<string>, ipAddress: Maybe<string>): Promise<any> {
    store.commit('set_active_board_pid', null); // If left from another user on machine.
    store.commit('set_active_workspace_pid', null);

    return this.apollo.mutate({
      mutation: RegisterMutation,
      variables: {
        email: email,
        first_name: firstName,
        last_name: lastName,
        ipAddress: ipAddress
      },
    })
  }

  login(username: Maybe<string>, password: Maybe<string>): Promise<any> {
    return this.apollo.mutate({
      mutation: LoginMutation,
      variables: {
        username: username,
        password: password,
      },
    }).then(({data: {login}}) => {
      return this.apollo.getClient().resetStore().then((result) => {

        store.commit('logged_in', login.access_token);
        PusherService.pusherAuth();

        return result;
      });
    })
  }

  accessTokenWithEmail(email: string): Promise<any> {
    return this.apollo.mutate({
      mutation: GetAccessTokenWithEmailMutation,
      variables: {
        email: email,
      },
    })
  }

  checkEmail(email: Maybe<string>): Promise<any> {
    return this.apollo.query({
      query: CheckEmail,
      variables: {
        email: email
      }
    })
  }

  createFakeUser(email: Maybe<string>, first_name: Maybe<string>, last_name: Maybe<string>, additional_registration_info: Maybe<string>): Promise<any> {
    return this.apollo.mutate({
      mutation: RegisterFakeMutation,
      variables: {
        email: email,
        first_name: first_name,
        last_name: last_name,
        additional_registration_info: additional_registration_info,
      }
    })
  }

  claimAccount(email: Maybe<string>, first_name: Maybe<string>, last_name: Maybe<string>, password: Maybe<string>): Promise<any> {
    return this.apollo.mutate({
      mutation: ClaimOwnAccount,
      variables: {
        email: email,
        firstName: first_name,
        lastName: last_name,
        password: password
      },
      refetchQueries: [{query: MeQuery}]
    })
  }
}

export default UserService;
