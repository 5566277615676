import gql from 'graphql-tag';

export const WorkspaceInviteFragment = gql`
    fragment WorkspaceInviteDetailed on WorkspaceInvite {
        id
        invitee_email
        invitee_registered
        initiator {
            id
            name
        }
        workspace {
            id
            pid
            domain
            name
            avatar
        }
        boards {
            id
            pid
            name
        }
    }
`;

export const CreateWorkspaceInviteMutation = gql`
    mutation CreateWorkspaceInvite($invitee_email: String!, $workspace: ID!, $role: Role!, $boards: [ID!]) {
        createWorkspaceInvite(
            input: {
                invitee_email: $invitee_email
                workspace: { connect: $workspace }
                role: $role
                boards: { connect: $boards }
            }
        ) {
            ...WorkspaceInviteDetailed
        }
    }
    ${WorkspaceInviteFragment}
`;
