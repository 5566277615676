import gql from 'graphql-tag';

export const CreateReactionMutation = gql`
    mutation React(
        $reactable_type: String!
        $reactable_id: ID!
        $reaction: ReactionType!
    ) {
        react(
            input: {
                reactable: { connect: { type: $reactable_type, id: $reactable_id } }
                reaction: $reaction
            }
        ) {
            ... on Activity {
                id
            }
            reactionSummaries {
                reaction
                count
            }
        }
    }
`;

export const RemoveReactionMutation = gql`
    mutation  removeReact($id: ID!) {
        removeReact(id: $id){
            id
        }
    }
`;
