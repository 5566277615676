

































import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
  props: {
    defaultColor: {},
    name: {}
  }
})
export default class ColorPicker extends Vue {
  @Prop({required: true, default: null})
  color!: string;

  selectedColor: string | null = null;
  hexArray: Array<{ color: string }> = [
    {color: '#ffe1dc',},
    {color: '#faebbb',},
    {color: '#bbf6d4',},
    {color: '#d9deff',},
    {color: '#e8ebff',},
    {color: '#faeeff',},
  ];

  returnColor(color: string) {
    this.selectedColor = color;
    this.$emit('selected-color', this.selectedColor);
  }

  deleteColor(){
    this.selectedColor = '#ffffff';
    this.$emit('selected-color', this.selectedColor);
  }

  mounted() {
    if(this.$props.defaultColor) {
      this.selectedColor = this.$props.defaultColor;
    }
  }
}
