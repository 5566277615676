



































import {Component, Vue} from 'vue-property-decorator';
import {feedback_types} from "@/graphql/feedback";
import {CreateFeedbackMutation} from '@/graphql/feedback';

@Component({
  apollo: {
    feedback_types: {
      query: feedback_types,
      result(_result) {
        //
      }
    },
  },
  components: {},
})
export default class FeedbackInput extends Vue {
  feedbackTitle: string | null = null;
  feedbackDescription: string | null = null;
  feedbackTypeId: string | null = null;

  get workspaceId() {
    return this.$store.state.workspace.id;
  }

  get meId() {
    return this.$store.state.me.id;
  }

  get meScore() {
    return this.$store.state.me.engagement_score;
  }

  submitFeedback() {
    this.$gtag.event('pmb_user_feedback_submitted');
    this.$apollo
      .mutate({
        mutation: CreateFeedbackMutation,
        variables: {
          creator_id: this.meId,
          workspace_id: this.workspaceId,
          feedback_type_id: this.feedbackTypeId,
          title: this.feedbackTitle,
          description: this.feedbackDescription
        },
      })
      .then(() => {
        this.feedbackTitle = null;
        this.feedbackDescription = null;
        this.$buefy.toast.open({
          message: 'Submitted!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      })
    ;
  }
}
