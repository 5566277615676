























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {Template},
  props: {
    date: {
      default: null,
    },
    range: {
      default: false
    }
  }
})
export default class SmartDateTime extends Vue {
  dateTime: Date  = new Date();
  dates: Array<Date> = [];
  setTimeActive = false;
  showTimePicker: boolean = false;

  @Prop({required: false, default: null})
  minDate!: Date | null;

  @Prop({required: false, default: null})
  maxDate!: Date | null;

  @Prop({required: false, default: false})
  removeDueDate!: boolean;

  @Prop({required: false, default: null})
  setDate!: string;

  @Prop({required: false, default: null})
  removeOtherButton!: boolean

  update(dateTime: any) {
    if (dateTime) {
      this.$emit('date', dateTime);

    } else {
      this.$emit('date', null);
    }
    //@ts-ignore ToDo: fix type issue
    this.$parent.close();

  }

  setToday() {
    this.dateTime = new Date();
    this.update(this.dateTime);
  }
  setTomorrow() {
    let tomorrow = new Date(Date.now() + 24 * 60 * 60 * 1000);
    this.dateTime = tomorrow;
    this.update(tomorrow);
  }

  updateDateRange(dates: any) {
    if(dates.length > 0) {
      this.$emit('dates', {'date1': dates[0], 'date2': dates[1]})
    }

    //@ts-ignore ToDo: fix type issue
    this.$parent.close();
  }

  handleShowTimePicker() {
    this.showTimePicker = !this.showTimePicker;
  }

  mounted() {
    if(this.$props.setDate) {
      this.dateTime = new Date(this.$props.setDate);
    }
  }

}
