
























































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import formatDistance from "date-fns/formatDistance";
import WorkspaceAvatar from '@/components/workspace/WorkspaceAvatar.vue';
import {
  MeQuery,
  MarkAllNotificationsAsReadMutation,
  DeleteNotificationMutation,
  DeleteAllNotificationsMutation, GetUserNotifications,
} from '@/graphql/auth';
import UserAvatar from "@/components/user/UserAvatar.vue";
import Template from "@/components/templates/Template.vue";
import {UserNotification, UserNotificationPaginator} from "@/typescript/types";

@Component({
  components: {Template, UserAvatar, WorkspaceAvatar },
  apollo: {
    userNotifications: {
      query: GetUserNotifications,
      fetchPolicy: 'network-only',
      variables() {
        return {
          isWorkspace: this.workspaceLevel,
          workspacePid: this.$store.state.workspace.pid
        }

      },
      result(result: any) {
        return result.data.userNotifications;
      }
    }
  }
})
export default class UserNotifications extends Vue {
  userNotifications: UserNotificationPaginator | null = null;
  sidebarActive = false;
  page = 1;

  @Prop({required: false, default: false})
  workspaceLevel!: boolean

  get me() {
    return this.$store.state.me;
  }

  get boardPid() {
    return this.$store.state.active_board_pid;
  }

  get allNotifications() {
    if(this.userNotifications) {
      if(this.$props.workspaceLevel) {
        return this.userNotifications.data.filter((noti: any) => noti.message.message_detail_workspace_pid === this.$store.state.workspace.pid );
      }
      return this.userNotifications.data;
    }
    return [];

  }

  get hasMorePages() {
    if(this.userNotifications) {
      return this.userNotifications!.paginatorInfo.hasMorePages;
    }
    return false;

  }

  get unreadNotificationCount(){
    if(this.userNotifications) {
      if(this.$props.workspaceLevel) {
        return this.userNotifications.data.filter((noti: any) => noti.message.message_detail_workspace_pid === this.$store.state.workspace.pid).length;
      }
      return this.userNotifications.data.filter((noti: any) => noti.read_at === null).length;
    }
    return 0;

  }


  forHumans(d: Date) {
    return formatDistance(new Date(d), new Date());
  }

  goToWorkspace(pid: string) {

    this.$router.push({ name: 'board-select', params: { workspace_pid: pid } });
    this.sidebarActive = false;
  }

  goToWorkspaceBoard(notification: UserNotification) {
    this.$events.fire('close-notification-modal', true);

    if(this.boardPid === notification.message.message_detail_board_pid) {
      if(notification.message.source === 'ObjectiveComment' || notification.message.source === 'AssignObjectiveUser') {
        this.$events.fire('open-objective-from-notification', notification.message.message_detail_id)
      }
    }else{
      if(notification.message.source === 'ObjectiveComment' || notification.message.source === 'AssignObjectiveUser'){
        this.$store.commit('set_notification_objective_id', notification.message.message_detail_id);
      }
      this.$router.push({ path: `/${notification.message.message_detail_workspace_pid}/${notification.message.message_detail_id}/${notification.message.message_detail_board_pid}` });
    }

  }

  markAllAsRead() {
    this.$apollo.mutate({
      mutation: MarkAllNotificationsAsReadMutation,
      refetchQueries: [{ query: MeQuery }],
    });
  }

  triggerNotifications() {
    //setTimeout(() => { this.markAllAsRead(); }, 3000);
  }

  deleteAllNotifications() {
    this.$apollo.mutate({
      mutation: DeleteAllNotificationsMutation,
      refetchQueries: [{ query: MeQuery }],
    });
  }

  deleteNotification(id: string) {
    this.$apollo.mutate({
      mutation: DeleteNotificationMutation,
      variables: {
        id: id,
      },
      refetchQueries: [{ query: GetUserNotifications, variables: {isWorkspace: this.workspaceLevel, workspacePid: this.$store.state.workspace.pid} }],
    });
  }

  viewInvite(pid: string) {
    this.$router.push({ name: 'invitation-view', params: { pid: pid } });
    this.$emit('close', true);
  }

  mounted() {
    this.triggerNotifications();
    this.$events.listen('new-notification', (data: any) => {
     
      this.userNotifications!.data.unshift(...data);
    })
  }

  beforeDestroy() {
    this.markAllAsRead();
  }

  loadMoreNotification() {
    this.page++;
    this.$apollo.queries.userNotifications.fetchMore({
      variables: {
        isWorspaceLevel: this.$props.workspaceLevel,
        workspacePid: this.$store.state.workspace.pid,
        page: this.page,
      },
      updateQuery: (previousResult, {fetchMoreResult}) => {
       
        //this.infiniteStateUp.loaded();
        //this.isLoading = false;
        return {
          userNotifications: {
            __typename: previousResult.userNotifications.__typename,
            /**
             * Merging the tag list
             */
            data: [...previousResult.userNotifications.data, ...fetchMoreResult.userNotifications.data],
            paginatorInfo: fetchMoreResult.userNotifications.paginatorInfo,
          },
        };
      },
    })
  }
}
