import {Board, NorthStar, Objective, Workspace} from "@/typescript/types";
import {DollarApollo} from "vue-apollo/types/vue-apollo";
import {
  AddBoardToNorthStar,
  CreateNorthStar,
  CreateNorthStarWithoutBoard,
  DeleteNorthStarMutation,
  RemoveBoardFromMilestone,
  UpdateNorthStarDescription,
  SingleNorthStar,
  CopyNorthStar,
  CopyObjectiveForNorthStar, CopyMilestoneForNorthStar
} from "@/graphql/NorthStar";
import { WorkspaceQuery } from '@/graphql/workspace';
import {
  ObjectivesQuery,
  RemoveObjectiveNorthStars,
  UpdateObjectiveNorthStars
} from "@/graphql/objective";
import map from "lodash/map";
import store from '@/store';
import { BoardQuery } from '@/graphql/board';



class NorthStarService {
  private apollo: DollarApollo<any>;

  constructor(apollo: DollarApollo<any>) {
    this.apollo = apollo;
  }

  addBoardToNorthStar(boards: Board[], northStar: NorthStar) {
    return this.apollo.mutate({
      mutation: AddBoardToNorthStar,
      variables: {
        id: northStar.id,
        board: map(boards, (board: Board) => {
          return board.id
        }),
      },
      refetchQueries: [{ query: BoardQuery, variables: { pid: store.state.active_board_pid } }],
    }).then((response) => {
      return response;
    })
  }

  addNorthStarToObjective(objective: Objective, northStars: NorthStar[]) {
    return this.apollo.mutate({
      mutation: UpdateObjectiveNorthStars,
      variables: {
        id: objective.id,
        northStars: map(northStars, (star: NorthStar) => {
          return star.id
        }),
      },
      //@ts-ignore
      refetchQueries: [{ query: BoardQuery, variables: { pid: store.state.active_board_pid } }],
    }).then((response) => {
      return response;
    }).catch((error) => {
      throw error;
    })
  }

  removeNorthStarFromObjective(objective: Objective) {
    return this.apollo.mutate({
      mutation: RemoveObjectiveNorthStars,
      variables: {
        objective_id: objective.id,

      },
      //@ts-ignore
      refetchQueries: [{ query: BoardQuery, variables: { pid: store.state.active_board_pid } },{query: ObjectivesQuery, variables:{board_id: store.state.board.id}}],
    }).then((response) => {
      return response;
    }).catch((error) => {
      throw error;
    })
  }

  createNorthStar(workspace: Workspace, board: Board, name: string, dueDate: Date | null = null, startDate: Date | null = null, color: string | null = null) {
    return this.apollo.mutate({
      mutation: CreateNorthStar,
      variables: {
        name: name,
        workspace: workspace.id,
        board: board.id,
        color: color,
        start_date: startDate,
        due_date: dueDate,
        isPublic: true
      },
      refetchQueries: [{ query: BoardQuery, variables: { pid: store.state.active_board_pid } },{ query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid } }],

    }).then((response) => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  createNorthStarWithoutBoard(workspace: Workspace, name: string, dueDate: Date | null = null, startDate: Date | null = null, color: string | null = null) {
    return this.apollo.mutate({
      mutation: CreateNorthStarWithoutBoard,
      variables: {
        name: name,
        workspace: workspace.id,
        color: color,
        due_date: dueDate,
        start_date: startDate,
        isPublic: true
      },
      refetchQueries: [{ query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid } }],
    }).then((response) => {
      return response;
    }).catch(error => {
      throw error;
    })
  }

  deleteNorthStar(id: string) {
    return this.apollo.mutate({
      mutation: DeleteNorthStarMutation,
      variables: {
        id: id
      },
      refetchQueries: [{query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid }}]
    }).then((response) => {
      return response.data.deleteNorthStar;
    }).catch(error => {
      throw error;
    })
  }

  updateNorthStarDescription(id: string, name: string | null = null, description: string | null = null, status: string | null = null, due_date: Date | null = null, start_date: Date | null = null, color: string | null = null, is_public: boolean | null = null) {
    return this.apollo.mutate({
      mutation: UpdateNorthStarDescription,
      variables: {
        id: id,
        name: name,
        description: description,
        status: status,
        due_date: due_date,
        start_date: start_date,
        color: color,
        completed_at: (status === 'completed') ? new Date() : null,
        is_public: is_public
      },
      refetchQueries: [{query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid }}, {query: SingleNorthStar, variables:{goal_id: id}}]
    }).then((response) => {

      return response.data.updateNorthStar;
    }).catch(error => {

      throw error;
    })
  }

  updateNorthStarDates(id: string, start_date: Date | null = null, due_date: Date | null = null) {
    return this.apollo.mutate({
      mutation: UpdateNorthStarDescription,
      variables: {
        id: id,
        start_date: start_date,
        due_date: due_date,
      },
      refetchQueries: [{query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid }}, {query: SingleNorthStar, variables:{goal_id: id}}]
    }).then((response) => {

      return response.data.updateNorthStar;
    }).catch(error => {

      throw error;
    })
  }

  updateNorthStarName(id: string, name: string | null = null) {
    return this.apollo.mutate({
      mutation: UpdateNorthStarDescription,
      variables: {
        id: id,
        name: name,
      },
      refetchQueries: [{query: WorkspaceQuery, variables: { pid: store.state.active_workspace_pid }}, {query: SingleNorthStar, variables:{goal_id: id}}]
    }).then((response) => {

      return response.data.updateNorthStar;
    }).catch(error => {

      throw error;
    })
  }

  removeBoardFromMilesone(board_id: string, milestone_id: string){
    return this.apollo.mutate({
      mutation: RemoveBoardFromMilestone,
      variables: {
        board_id: board_id,
        milestone_id: milestone_id
      }
    }).then((response)=>{
      return response;
    }).catch(error =>{
      throw error;
    })
  }

  getNorthStar(goal_id: string) {
    return this.apollo.query({
      query: SingleNorthStar,
      variables: {
        goal_id: goal_id
      }
    }).then((response)=>{
      return response;
    }).catch(error =>{
      throw error;
    })
  }

  copyNorthStar(goal_id: string, board_id: string | null = null, workspace_id: string, forCopyObj: string = 'all') {
    return this.apollo.mutate({
      mutation: CopyNorthStar,
      variables: {
        id: goal_id,
        board_id: board_id,
        workspace_id: workspace_id,
        forCopyObj: forCopyObj
      },
      refetchQueries: [{ query: WorkspaceQuery, variables: { id: workspace_id } }]
    }).then((response)=>{
      return response;
    }).catch(error =>{
      throw error;
    })
  }

  copyObjectiveForNorthStar(northstar_id: string, objective_id: string, board_id: string) {
    return this.apollo.mutate({
      mutation: CopyObjectiveForNorthStar,
      variables: {
        northstar_id: northstar_id,
        objective_id: objective_id,
        board_id: board_id
      },
      refetchQueries:[{query: SingleNorthStar, variables:{goal_id: northstar_id}}]
    }).then((response)=>{
      return response;
    }).catch(error =>{
      throw error;
    })
  }

  copyMilestoneForNorthStar(northstar_id: string, objective_id: string, board_id: string) {
    return this.apollo.mutate({
      mutation: CopyMilestoneForNorthStar,
      variables: {
        northstar_id: northstar_id,
        milestone_id: objective_id,
        board_id: board_id
      }
    }).then((response)=>{
      return response;
    }).catch(error =>{
      throw error;
    })
  }
}

export default NorthStarService;
