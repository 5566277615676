























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ObjectiveQuery, CreateInitiativeMutation } from '@/graphql/objective';
import SmartDateTime from "@/components/general/SmartDateTime.vue";
import chrono from 'chrono-node';



@Component({
  components: {SmartDateTime},
})
export default class CreateInitiative extends Vue {
  createInitiativeActive = false;
  initiativeDescription: string | null = null;
  dueDate: Date | null = null;


  @Prop({ default: undefined, required: true })
  objectiveId!: string;

  @Prop({ default: null, required: false })
  objectiveDueDate!: Date;

  get me() {
    return this.$store.state.me;
  }

  get datesFromInput() {
    if (this.initiativeDescription) {
      return chrono.parseDate(this.initiativeDescription);
    }
    return {};
  }

  @Watch('datesFromInput')
  onPropertyChanged() {
    /*
        // ToDo: fix format of datesFromInput to match what the mutation is expecting
        if(this.datesFromInput === undefined || this.datesFromInput === null) {
            Vue.prototype.$consoleLog('did not find any date');
        } else {
            this.dueDate = this.datesFromInput;
        }
        */
  }

  toggleCreate() {
    this.createInitiativeActive = !this.createInitiativeActive;
    if(this.createInitiativeActive === true) {
      this.$nextTick(function(){
        //@ts-ignore ToDo: fix type issue
        this.$refs.initiativeInput.focus();
      });
    }
  }

  openDatePicker() {
    this.$buefy.modal.open({
      component: SmartDateTime,
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {
          if(value) {
            this.dueDate = value;
          }
        }
      },
    });
  }


  cancelCreate() {
    this.createInitiativeActive = false;
    this.initiativeDescription = null;
    this.dueDate = null;
  }

  createInitiative() {
    if(!this.dueDate && this.objectiveDueDate) {
      this.snapDateToObjective();
    }
    this.$apollo
      .mutate({
        mutation: CreateInitiativeMutation,
        variables: {
          id: this.objectiveId,
          description: this.initiativeDescription,
          assignee_id: this.me.id,
          due_date: this.dueDate,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.objectiveId } }],
      })
      .then(_ => {

      })
      .finally(() => {
        this.initiativeDescription = null;
        this.dueDate = null;
      });
  }

  snapDateToObjective() {
    this.dueDate = this.objectiveDueDate;
  }

  mounted() {
    /**
     * Snap initiative due date to parent objective due date
     */
    this.$nextTick(() => {
      if(this.objectiveDueDate) {
        this.snapDateToObjective();
      }
    });
  }
}
