








































import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import UserAvatar from "@/components/user/UserAvatar.vue";
import {ObjectiveDetailedFragment, WorkspaceObjectivesQuery} from "@/graphql/objective";
import Template from "@/components/workspace/Template.vue";

@Component({
  components: {Template, UserAvatar},
  props: {
    objectiveId: {
      required: true
    },
    owner: {
      required: true,
    },
    assignees: {
      default: null,
      type: Array
    }
  },
})
export default class ObjectiveOwnerSelector extends Vue {
  isActive = false;
  currentOwner: string | null = null;

  get board() {
    return this.$store.state.board;
  }


  setNewOwner(userId: string) {
    this.currentOwner = userId;
    this.updateObjective(userId);
  }

  checkIfUserBlockedFromWorkspace(userId: string) {
    let member = this.$store.state.workspace.allMembers.filter((member: any) => {
      return member.user.id === userId;
    });


    if (member[0].access_workspace == 0) {
      return false;
    } else {
      return true;
    }
  }

  updateObjective(userId: string) {
    this.isActive = false;
    this.$apollo.mutate({
      mutation: gql`
                mutation updateObjective($id: ID!, $owner: ID!) {
                    updateObjective(id: $id, input: { owner: { connect: $owner } }) {
                        ...ObjectiveDetailed
                    }
                }
                ${ObjectiveDetailedFragment}
            `,
      variables: {
        id: this.$props.objectiveId,
        owner: userId,
      },
      refetchQueries: [{query: WorkspaceObjectivesQuery, variables: { workspace_id: this.$store.state.workspace.id }}]
    })
      .finally(() => {
        this.$buefy.toast.open({
          message: 'Changes saved!',
          position: 'is-bottom-right',
          type: 'is-black',
        });
      });
  }


  mounted() {
    this.currentOwner = this.$props.objectiveOwnerId;
  }
}
