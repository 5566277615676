




















































































import { Component, Vue } from 'vue-property-decorator';
import {
  DeleteKeyResultMutation,
  IncrementKeyResultMutation,
  ObjectiveQuery,
  UpdateKeyResultMutation
} from '@/graphql/objective';
import PieCheckbox from '@/components/general/PieCheckbox.vue';
import CreateOrUpdateKeyResult from "@/components/board/CreateOrUpdateKeyResult.vue";
import ConfirmDelete from "@/components/general/ConfirmDelete.vue";
import KeyResultDetailed from "@/components/board/KeyResultDetailed.vue";
import UserAvatar from "@/components/user/UserAvatar.vue";
import SmartDateTime from "@/components/general/SmartDateTime.vue";

@Component({
  components: {UserAvatar, ConfirmDelete, CreateOrUpdateKeyResult, PieCheckbox },
  props: {
    key_result: {},
    index: {},
    objectiveMembers: {
      type: Array
    }
  },
})
export default class KeyResultItem extends Vue {
  isSelected = false;
  itemHovered = false;
  requestDeleteActive = false;
  isCompleted = false;
  dueDate: Date | null = null;

  get showAssignee() {
    let members = this.$store.state.board.members.filter((member: any)=>member.user.deleted_at===null);
    if(members.length === 1) {
      return false;
    } else { return true }
  }

  deleteKeyResult(id: string) {
    this.$apollo
      .mutate({
        mutation: DeleteKeyResultMutation,
        variables: {
          id: id,
        },
        refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.key_result.objective.id } }],
      })
  }

  updateKeyResult(completed: boolean) {
    this.isCompleted = !this.isCompleted;
    if(this.isCompleted) {
      this.playSound();
    }
    this.$apollo.mutate({
      mutation: UpdateKeyResultMutation,
      variables: {
        id: this.$props.key_result.id,
        completed: completed,
      },
      refetchQueries: [{ query: ObjectiveQuery, variables: { id: this.$props.key_result.objective.id } }]
    });
  }

  updateKeyResultDueDate(date: Date) {
    this.$apollo.mutate({
      mutation: UpdateKeyResultMutation,
      variables: {
        id: this.$props.key_result.id,
        due_at: date
      },
    });
  }

  openKeyResult() {
    this.$buefy.modal.open({
      component: KeyResultDetailed,
      // @ts-ignore
      customClass: 'initiative-detailed-modal',
      props: {
        keyResult: this.$props.key_result,
        assignedMembers: this.$props.objectiveMembers
      },
      width: '600px',
      parent: this,
    });
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      // this plays when an initiative is marked as completed
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.1;
      audio.play();
    }
  }

  handleKeyResultValueChange(value: number) {
    let current = this.$props.key_result.current_value;
    let target = this.$props.key_result.target_value;

    if(value) {
      this.$apollo.mutate({
        mutation: IncrementKeyResultMutation,
        variables: {
          id: this.$props.key_result.id,
          value: value,
        },
      });
    }
    /**
     * mark key result completed if it exceeds target value
     * */
    if(current + value >= target) {
      this.updateKeyResult(true);
      this.isCompleted = true;
    }
    /**
     * mark key result incomplete if it's updated to be less than target value
     * */
    else if(current + value < target)  {
      this.updateKeyResult(false);
      this.isCompleted = false;
    }
  }

  openDatePicker(date: string) {
    let selectedDate = (date) ? new Date(date) : null;
    this.$buefy.modal.open({
      component: SmartDateTime,
      props: {date: selectedDate, removeDueDate: true},
      width: '280px',
      // @ts-ignore
      customClass: 'is-paddingless',
      parent: this,
      events: {
        //@ts-ignore ToDo: fix type issue
        'date': value => {

          this.$props.key_result.due_at = value;
          this.updateKeyResultDueDate(value);

        }
      },
    });
  }

  mounted() {
    this.isCompleted = this.$props.key_result.completed;
  }
}
